import { request } from 'utilities/requestUtil'

export const onLoad = async ({ setAds, setProductAds, app, session }) => {
  const data = await getData({ session, app })

  setAds(data.ads)
  setProductAds(data.productAds)
}

async function getData({ session, app }) {
  const variables = {
    type: 'PRODUCT',
    merchantId: app.state.merchant.id,
  }
  const query = `
    query($merchantId: ID!, $type: String) {
      ads(merchantId: $merchantId, type: $type) {
        id
        source
        type
        title
        linkUrl
        imageUrl
        seq
        extra
        status
      }
      productAds(merchantId: $merchantId) {
        id
        seq
        name
        items {
          productId
          seq
          spu
          priceRange
          primaryImage {
            src
            alt
          }
          startTime
          endTime
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data
}
