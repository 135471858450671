import React from 'react'
import { Box } from 'rebass'
import Page from 'components/Page'
import Container from 'components/Container'

export default () => (
  <Page title="privacy.title">
    <Container minHeight="100vh" bg="grey.1">
      <Box
        bg="grey.0"
        py={3}
        px={4}
        sx={{
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: 'grey.3',
          borderRadius: '4px',
        }}
      >
        <h2>慎康愛生活 隱私權條款</h2>
        <p>
          慎康愛生活是由「慎康企業股份有限公司」所經營。為了支持個人資料保護，維護個人隱私權，慎康企業股份有限公司謹以下列聲明，向您說明慎康企業股份有限公司蒐集個人資料之目的、類別、利用範圍及方式、以及您所得行使之權利等事項：
        </p>
        <h3>一、 客戶/會員資料之來源</h3>
        <p>（一） 會員在本網站的註冊會員或消費之客戶所提供之資訊。</p>

        <p>（二） 本公司使用Cookie和其他技術取得之資訊。</p>
        <p>
          （三）
          由本公司在其他行銷或異業結盟活動中另行合法取得(包括但不限於自業務合作廠商處取得)。
        </p>
        <h3>二、 客戶/會員資料蒐集之目的及種類</h3>
        <p>
          （一）
          蒐集之目的在於提供商品銷售、金融交易授權、物流配送、廣告行銷、市場分析、贈獎活動、會員權益通知其他相關服務。
        </p>
        <p>
          （二）
          本公司依據本網站所提供之相關服務，將蒐集、使用之會員資料例示及說明如下：
        </p>
        <ol>
          <li>
            成為本購物網站之會員，須請您提供個人基本資料：包含姓名、性別、身分證統一編號、出生年月日、聯絡電話、聯絡地址、電子郵件信箱及其他您主動提供之資料。
          </li>
          <li>
            當您指定商品配送服務時，本公司會請您提供配送資料：包含收件人之姓名、聯絡電話、聯絡地址及其他必要配送聯絡資料。
          </li>
          <li>當您有實際消費行為時，本公司會留存您的帳務資料。</li>
          <li>
            本公司基於提供市場分析、贈獎活動、會員及客戶/會員權益通知、開立電子發票等相關服務，將請您提供其他必要資料或本公司使用Cookie技術取得之資料。
          </li>
        </ol>
        <p>
          （三）
          依據法務部頒佈之「個人資料保護法之特定目的及個人資料之類別」，本公司蒐集、處理、利用及保有您個人資料之種類列示如下：
        </p>
        <ul>
          <li>
            001
            辨識個人者。(姓名、職稱、住址、工作地址、以前地址、住家電話號碼、行動電話、網路平臺申請之帳號、通訊及戶籍地址、電子郵遞地址及其他任何可辨識資料本人者等。)
          </li>
          <li>
            002
            辨識財務者。(例如：金融機構帳戶之號碼與姓名、信用卡或簽帳卡之號碼、保險單號碼、個人之其他號碼或帳戶等。)
          </li>
          <li>003 政府資料中之辨識者。(例如：身分證統一編號、護照號碼等。)</li>
          <li>
            011 個人描述。(例如：年齡、性別、出生年月日、出生地、國籍、聲音等。)
          </li>
          <li>012 身體描述。(例如：身高、體重、血型等。)</li>
          <li>
            021
            家庭情形。(例如：結婚有無、配偶或同居人之姓名、前配偶或同居人之姓名、結婚之日期、子女之人數等。)
          </li>
          <li>
            023
            家庭其他成員之細節。(例如：子女、受扶養人、家庭其他成員或親屬、父母等。)
          </li>
          <li>031 住家及設施。(如：住所地址)</li>
          <li>033 移民情形。(例如：護照)</li>
          <li>034 旅行及其他遷徙細節。(例如：旅行細節)</li>
          <li>
            036
            生活格調。(例如：使用消費品之種類及服務之細節、個人或家庭之消費模式等。)
          </li>
          <li>038 職業。(例如：學校校長、民意代表或其他各種職業等。)</li>
          <li>081 收入、所得、資產與投資。</li>
          <li>
            093
            財務交易。(例如：收付金額、信用額度、保證人、支付方式、往來紀錄、保證金或其他擔保等。)
          </li>
          <li>
            102 約定或契約。(例如：關於交易、商業、法律或其他契約、代理等。)
          </li>
          <li>
            132 未分類之資料。 (例如：無法歸類之信件、檔案、報告或電子郵件等。)
          </li>
        </ul>

        <h3>三、 會員資料之利用方式與範圍</h3>
        <p>
          （一）
          利用期間：會員當事人要求停止使用或至本公司停止提供服務之日為止。
        </p>
        <p>（二） 利用地區：服務所能到達地區內。</p>
        <p>
          （三）
          除非另外事先說明並取得您的同意、或依法規、法院或政府機關之命令而負有揭露義務者外，本公司不會向第三人公開揭露您的個人資訊或作目的外之使用。本公司僅於下列目的範圍內，存取並使用您的資料，使用方式與範圍說明及例示如下：
        </p>
        <ol>
          <li>
            物流寄送：因應本公司提供會員之客戶/會員商品銷售、物流配送等履行訂單之服務，將提供配送資料予供貨合作廠商及物流商。
          </li>
          <li>
            金融交易授權：因應本公司提供之多重付款功能，將使用您所提供之帳務資料於金融交易過程，提供予金融機構以完成金融交易及相關業務。
          </li>
          <li>
            廣告行銷：為提供您更多優質商品及活動資訊，本公司將使用您的部分資訊或分享予經本公司授權之廠商用於行銷業務上，如您不願接收此項服務，均可隨時通知本公司停止使用您的資料。
          </li>
          <li>
            優化服務：為了提供更適合您需要的服務，本公司亦將依據您所瀏覽廣告之內容、消費之紀錄及所提供之資料等進行市場分析，並使用（包含透過第三方）Cookie的技術，接收並且記錄您瀏覽器上的伺服器數值，包括IP
            Address、Cookies，以進行提供與產品更新及網路服務優化有關的工作時使用。（※Cookie是網站伺服器用來和使用者瀏覽器進行溝通的一種技術，它可能在使用者的電腦中儲存某些資訊。您可以經由瀏覽器的設定，隨時取消或限制此項功能，惟若關閉可能將影響部分本公司提供之服務功能。）
          </li>
          <li>
            其他業務附隨事項：依據以上所述服務目的，及其他您在使用本網站其他服務，於必要範圍內進行相關運用，例如：於配合財政部電子發票整合服務之特定目的內，將電子發票相關資料上傳至財政部電子發票整合服務平台，及提供電子發票加值服務目的內處理利用。
          </li>
        </ol>
        <h3>四、 會員資料儲存及保管</h3>
        <p>
          關於您提供的個人資料，本公司將就資訊系統及公司作業規則制定嚴格規範，任何人均須在本公司明定的授權規範下才能處理及利用必要之資料。本公司針對資料安全設備投注之保護措施如下：
        </p>
        <p>
          （一）
          本公司將執行內部個資風險保護評估，任用專業人員並採用當時最佳的科技來保障您的個人資料安全。目前以Secure
          Sockets Layer(SSL)
          機制(128bit)進行資料傳輸加密，並以加裝防火牆防止不法入侵，避免您的個人資料遭到非法存取。
        </p>
        <p>
          （二）
          如本公司發現有個人資料外洩或遭他人非法使用，本公司將立即或於發現後72小時內通報給資料保護主管機關及/或警察機關。
        </p>
        <h3>五、會員資料權利行使</h3>
        <p>
          依個人資料保護法之規定，您就所提供之個人資料享有向本公司請求查詢或閱覽、製給複製本、補充或更正、請求停止蒐集處理或利用、刪除之權利（包括刪除任何連結、副本或複製品之權利）。
        </p>
        <p>
          如果您欲行使上述權利或是有其他諮詢事項，可聯絡客服提出意見，本公司將盡快回覆您的問題。
        </p>
        <h3>六、權益影響</h3>
        <p>
          在此提醒您，會員如您不願意提供部分必要之資料，將可能導致無法享受完整服務或完全無法使用該項服務。
        </p>
        <h3>
          七、關於此份隱私權聲明，在會員第一次登入、登錄會員或開始使用本公司服務時，將以彈跳窗方式要求客戶/會員閱讀，並於客戶/會員於下方聲明係自願、具體及完全同意本份隱私權聲明內容（包括蒐集、處理及利用客戶/會員之個人資料）後，本公司方同意提供平台服務給會員。本公司將隨時因應社會環境及法令規定的變遷與科技的進步進行修改，修改後的聲明內容將公佈網站上，同時在會員下一次使用時，以彈跳窗方式要求會員閱讀，並於會員明確勾選聲明自願、具體及完全同意修改內容後，才能繼續使用本公司之服務。本公司雖不會個別通知會員，但會員亦應隨時注意該等修改或變更。會員於任何修改或變更後繼續使用本網站之服務時，亦可視為會員已瞭解並同意接受該等修改或變更。若不同意上述的聲明修訂或變更，會員應立即停止使用本公司提供之服務，並得請求本公司刪除會員資料。
        </h3>
      </Box>
    </Container>
  </Page>
)
