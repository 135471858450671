import React from 'react'
import { Box, Flex, Text } from 'rebass'
import { keyframes } from '@emotion/core'

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const loading = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`

const Dot = ({ top, left, delay }) => (
  <Box
    width="13px"
    height="13px"
    bg="accent.2"
    sx={{
      position: 'absolute',
      top,
      left,
      borderRadius: '50%',
      animation: `${loading} 1.2s linear infinite`,
      animationDelay: delay
    }}
  />
)

export default ({ children, ...props }) => (
  <Flex
    width={1}
    height={200}
    justifyContent="center"
    alignItems="center"
    sx={{
      opacity: 0,
      animation: `${fadeIn} 0.25s forwards`,
      animationDelay: '0.25s'
    }}
    {...props}
  >
    <Box width={64} height={64} sx={{ position: 'relative' }}>
      <Dot top="6px" left="6px" delay="0s" />
      <Dot top="6px" left="26px" delay="-0.4s" />
      <Dot top="6px" left="45px" delay="-0.8s" />
      <Dot top="26px" left="6px" delay="-0.4s" />
      <Dot top="26px" left="26px" delay="-0.8s" />
      <Dot top="26px" left="45px" delay="-1.2s" />
      <Dot top="45px" left="6px" delay="-0.8s" />
      <Dot top="45px" left="26px" delay="-1.2s" />
      <Dot top="45px" left="45px" delay="-1.6s" />
    </Box>
    {children && (
      <Text color="accent.2" fontSize={4} ml={3}>
        {children}
      </Text>
    )}
  </Flex>
)
