import React, { useState, useEffect, useContext } from 'react'
import { Text } from 'rebass'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Message from 'components/Message'
import Link from 'components/Link'
import Table from 'components/Table'
import Page from 'components/Page'
import { onLoad, setBreadcrumb } from 'models/orderList'
import { formatDate } from 'utilities/dateUtil'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const url = new URL(window.location.href)
  const tradeId = url.searchParams.get('ticketId')

  useEffect(() => {
    onLoad({ setState, app, session, tradeId })
    setBreadcrumb({ app })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="orderList.title">
      <Table
        width={1}
        bg="grey.0"
        columns={[
          {
            id: 'id',
            label: 'orderList.table.orderNo',
            render: ({ row }) => (
              <Link color="accent.1" href={`/member/order/${row.id}`}>
                {row.id}
              </Link>
            ),
          },
          {
            id: 'transDate',
            label: 'orderList.table.transDate',
            render: ({ row }) => (
              <Text>{formatDate(new Date(row.transDate))}</Text>
            ),
          },
          {
            id: 'price',
            label: 'orderList.table.totalAmount',
            render: ({ row }) => <Text>NT.{row.amountBilled}</Text>,
          },
          {
            id: 'status',
            label: 'orderList.table.status',
            render: ({ row }) => (
              <Message id={`orderList.status.${row.status}`} />
            ),
          },
        ]}
        rows={state}
      />
    </Page>
  )
}
