import React, { useState, useContext, useEffect } from 'react'
import { Button, Flex, Box, Text } from 'rebass'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import Carousel from 'nuka-carousel'
import Message from 'components/Message'
import Page from 'components/Page'
import Container from 'components/Container'
import Link from 'components/Link'
import Image from 'components/Image'
import ImageCard from 'components/ImageCard'
import { onLoad } from 'models/home'
import { MdNavigateBefore, MdNavigateNext, MdMenu } from 'react-icons/md'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [ads, setAds] = useState([])
  const [productAds, setProductAds] = useState([])
  const { categories, merchant } = app.state
  const { estore } = merchant
  const storeName = estore.name || estore.companyName

  const keywords = ads.filter(({ source }) => source === 'HOME_KEYWORD')
  const slides = ads.filter(({ source }) => source === 'HOME_SLIDER')
  const blocks = ads.filter(({ source }) => source === 'HOME_BLOCK')

  useEffect(() => {
    onLoad({ setAds, setProductAds, session, app })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderKeywords = () => {
    if (keywords.length === 0) return null

    return (
      <Flex
        alignItems="center"
        py={2}
        px={3}
        sx={{ lineHeight: '24px' }}
        color="accent.1"
      >
        <Message id="home.popular" />
        {keywords.map((item, index) => (
          <Link
            key={item.title}
            px={3}
            color="accent.1"
            sx={{
              borderRightWidth: index === keywords.length - 1 ? 0 : '1px',
              borderRightColor: 'grey.3',
              borderRightStyle: 'solid'
            }}
            href={`/category?key=${item.title}`}
          >
            {item.title}
          </Link>
        ))}
      </Flex>
    )
  }

  const renderAdBlocks = () => {
    if (blocks.length === 0) return

    return (
      <Box
        mx={[2, 0]}
        mt={3}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr 1fr'],
          justifyItems: 'center',
          gridGap: 3
        }}
      >
        {blocks.map((item, index) => (
          <Image
            key={item.id}
            src={item.imageUrl}
            url={item.linkUrl}
            variant="default"
            height="128px"
            mr={index === blocks.length - 1 ? 0 : 3}
            sx={{ borderRadius: '5px' }}
          />
        ))}
      </Box>
    )
  }

  const renderAds = items =>
    items.map(({ productId, primaryImage, spu, priceRange }) => (
      <Link
        key={productId}
        p={[2, 0]}
        variant="default"
        width={1}
        href={`/product/${productId}`}
      >
        <ImageCard
          src={primaryImage ? primaryImage.src : null}
          ratio={1}
          height="100%"
          sx={{
            transition:
              'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), transform 0.2s cubic-bezier(0.4, 0, 1, 1)',
            ':hover': {
              transform: 'translateY(-4px)',
              boxShadow: '0 8px 14px rgba(0, 0, 0, .125)'
            }
          }}
        >
          <Text width={1} px={3} py={3}>
            {spu}
          </Text>
          <Text px={3} pb={3} alignSelf="flex-start" color="accent.2">
            {priceRange}
          </Text>
          {/* <Text pb={3} px={3} alignSelf="flex-end" fontSize={0} color="grey.4">
            已出售 0
          </Text> */}
        </ImageCard>
      </Link>
    ))

  const renderAdGroups = () =>
    productAds.map(group => (
      <Box key={group.id} mb={5}>
        <Flex variant="header" mb={4}>
          <Text>{group.name}</Text>
        </Flex>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: [
              '1fr 1fr',
              '1fr 1fr 1fr 1fr',
              '1fr 1fr 1fr 1fr 1fr'
            ],
            justifyItems: 'center',
            gridRowGap: 3,
            gridColumnGap: [0, 2]
          }}
        >
          {renderAds(group.items || [])}
        </Box>
      </Box>
    ))

  const renderCategories = () => (
    <Box
      display={['none', 'block']}
      width={1}
      bg="grey.0"
      color="grey.5"
      sx={{
        // zIndex: 100,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'grey.3',
        borderRadius: '4px'
        // boxShadow: '2px 0 6px rgba(0, 0, 0, .25)'
      }}
    >
      <Flex
        alignItems="center"
        py={2}
        px={3}
        sx={{ boxShadow: '0 2px 4px rgba(0, 0, 0, .25)' }}
      >
        <MdMenu size="24px" />
        <Message ml={3} id="home.category" />
      </Flex>
      <Box py={2}>
        {categories
          .filter(item => item.parentCategoryId === '0')
          .filter(item => item.isPublic)
          .map(({ id, name }) => (
            <Link
              key={id}
              variant="darkLink"
              px={3}
              py={2}
              href={`/category?id=${id}`}
            >
              {name}
            </Link>
          ))}
      </Box>
    </Box>
  )

  return (
    <Page title={storeName || 'home.title'} formatTitle={!storeName}>
      <Container
      // sx={{
      //   backgroundImage: `linear-gradient(60deg, #eae7dc, #fff, #eae7dc)`,
      //   backgroundPosition: 'center top',
      //   backgroundSize: 'cover',
      // }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 4fr'],
            justifyItems: 'center',
            gridColumnGap: 3
          }}
        >
          {renderCategories()}
          <Box width={1}>
            {renderKeywords()}
            <Carousel
              autoplay={slides.length > 1}
              swiping={slides.length > 1}
              wrapAround
              pauseOnHover
              withoutControls={slides.length <= 1}
              renderCenterLeftControls={({ previousSlide }) => (
                <Button
                  ml={2}
                  height="48px"
                  variant="glass"
                  onClick={previousSlide}
                >
                  <MdNavigateBefore size="3em" />
                </Button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <Button
                  mr={2}
                  height="48px"
                  variant="glass"
                  onClick={nextSlide}
                >
                  <MdNavigateNext size="3em" />
                </Button>
              )}
            >
              {slides.map(item => (
                <Image
                  key={item.id}
                  height={[140, 300]}
                  ratio={300 / 794}
                  src={item.imageUrl}
                  url={item.linkUrl}
                />
              ))}
            </Carousel>
            {renderAdBlocks()}
          </Box>
        </Box>
      </Container>
      <Container
        minHeight="calc(100vh - 636px)"
        bg="grey.1"
        sx={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'grey.3'
        }}
      >
        {renderAdGroups()}
      </Container>
    </Page>
  )
}
