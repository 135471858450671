import React from 'react'
import { FormattedMessage } from 'react-intl'
import { request } from 'utilities/requestUtil'
import { BREADCRUMB_SET } from 'constants/actionType'

export const onLoad = async ({ setState, app, session }) => {
  const userId = app.state.user.id
  const variables = { input: { userId, parentId: 0 } }
  const query = `
    query($input: SupportMessageInput) {
      supportMessages(input: $input) {
        id
        type
        subject
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  setState(data.supportMessages)
}

export const setBreadcrumb = ({ app }) => {
  const items = [
    { label: 'Home', url: '/' },
    { label: <FormattedMessage id="support.title" /> }
  ]

  app.dispatch({ type: BREADCRUMB_SET, items })
}
