import React from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box, Flex } from 'rebass'
import ReactTooltip from 'react-tooltip'
import Icon from 'components/Icon'
import Message from './Message'

export default ({
  show = true,
  disabled,
  icon,
  iconProps,
  rightIcon,
  rightIconProps,
  href,
  text,
  textProps,
  tooltip,
  tooltipProps,
  children,
  onClick,
  variant = 'link',
  ...props
}) => {
  if (!show) return null

  const history = useHistory()
  const { formatMessage: message } = useIntl()

  const getClickProp = () => {
    if (disabled) return { onClick: () => {} }
    if (href) {
      if (href.startsWith('http')) return { href }
      return { onClick: () => history.push(href) }
    }
    return { onClick: () => onClick({ history }) }
  }

  return (
    <>
      <Box
        as="a"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        data-tip={tooltip && message({ id: tooltip })}
        tx="variants"
        variant={disabled ? 'disabledLink' : variant}
        {...getClickProp()}
        sx={{ cursor: 'pointer' }}
        {...props}
      >
        <Icon mr={text || children ? 2 : 0} {...iconProps} value={icon} />
        {text ? <Message id={text} {...textProps} /> : children}
        {rightIcon && (
          <Flex
            alignItems="center"
            fontSize={3}
            ml={children ? 2 : 0}
            {...rightIconProps}
          >
            {rightIcon}
          </Flex>
        )}
      </Box>
      {tooltip && <ReactTooltip effect="solid" {...tooltipProps} />}
    </>
  )
}
