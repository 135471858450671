import React from 'react'
import { Flex, Link } from 'rebass'
import { ALERT_ADD } from 'constants/actionType'
import TextInput from 'components/TextInput'
import Checkbox from 'components/Checkbox'
import {
  initializeState,
  handleTextChange,
  validateForm,
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { FiExternalLink } from 'react-icons/fi'

export const initialState = (value = {}) =>
  initializeState({
    username: value.username || '',
    password: value.password || '',
    passwordConfirm: '',
    captchaKey: value.captchaKey || '',
    captchaValue: '',
    captchaImage: value.captchaImage || '',
    subscription: true,
    privacy: true,
  })

const validation = {
  username: [{ type: 'required', message: 'error.required' }],
  password: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] },
  ],
  passwordConfirm: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] },
    {
      type: 'fieldEqual',
      name: 'password',
      message: 'signup.error.passNotMatch',
    },
  ],
  captchaValue: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const { name } = app.state.merchant.estore
  return {
    username: (
      <TextInput
        placeholder="signup.field.username"
        value={state.username}
        autoComplete="on"
        onChange={onTextChange('username')}
        errMsg={state.__error__.username}
      />
    ),
    password: (
      <TextInput
        type="password"
        placeholder="signup.field.password"
        value={state.password}
        autoComplete="current-password"
        onChange={onTextChange('password')}
        errMsg={state.__error__.password}
      />
    ),
    passwordConfirm: (
      <TextInput
        type="password"
        placeholder="signup.field.passwordConfirm"
        value={state.passwordConfirm}
        autoComplete="new-password"
        onChange={onTextChange('passwordConfirm')}
        errMsg={state.__error__.passwordConfirm}
      />
    ),
    captchaValue: (
      <TextInput
        placeholder="signup.field.captchaValue"
        value={state.captchaValue}
        onChange={onTextChange('captchaValue')}
        errMsg={state.__error__.captchaValue}
      />
    ),
    subscription: (
      <Checkbox
        label="signup.field.subscription"
        labelProps={{ values: { name }, fontSize: 0 }}
        checked={state.subscription}
        onChange={() =>
          setState({ ...state, subscription: !state.subscription })
        }
      />
    ),
    privacy: (
      <Flex alignItems="center">
        <Checkbox
          label="signup.field.privacy"
          labelProps={{ values: { name }, fontSize: 0 }}
          checked={state.privacy}
          onChange={() => setState({ ...state, privacy: !state.privacy })}
        />
        <Link ml={1} target="_blank" href="/privacy">
          <FiExternalLink />
        </Link>
      </Flex>
    ),
  }
}

export const handlers = ({ state, setState, app, session, actions }) => ({
  handleLoad: async () => {
    const { captchaKey, captchaImage } = await createCaptcha({ session })
    setState(initialState({ captchaKey, captchaImage }))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) {
      return
    }

    const merchantId = app.state.merchant.id
    const { username, password, captchaKey, captchaValue, subscription } = state
    const extra = { subscription }
    const variables = {
      merchantId,
      input: { username, password, captchaKey, captchaValue, extra },
    }
    const query = `
      mutation ($merchantId: ID!, $input: UserInput!) {
        registerUser(merchantId: $merchantId, input: $input)
      }
    `
    const [ok] = await request({ query, variables }, { app, session })
    const captchaData = await createCaptcha({ session })
    setState(
      initialState({
        username,
        password,
        captchaKey: captchaData.captchaKey,
        captchaImage: captchaData.captchaImage,
      })
    )
    if (!ok) return

    if (actions && actions.handleClose) {
      actions.handleClose()
    }

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'signup.success' },
    })
  },
})

async function createCaptcha({ session }) {
  const query = `
      mutation {
        createCaptcha {
          key
          data
        }
      }
    `
  const [ok, data] = await request({ query }, { session })
  if (!ok) return {}

  const { createCaptcha } = data
  return { captchaKey: createCaptcha.key, captchaImage: createCaptcha.data }
}
