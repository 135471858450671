import React from 'react'
import { Flex, Box } from 'rebass'
import Message from 'components/Message'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'

export default ({
  label,
  labelProps,
  checked,
  onChange,
  disabled = false,
  ...props
}) => {
  return (
    <Flex
      as="label"
      alignItems="center"
      onClick={disabled ? () => {} : onChange}
      sx={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        userSelect: 'none',
      }}
      {...props}
    >
      <Box
        mr={1}
        color={checked ? 'accent.1' : 'grey.4'}
        fontSize={3}
        lineHeight={0}
        sx={{
          '&:hover': {
            color: disabled ? 'grey.4' : 'accent.1',
          },
        }}
      >
        {checked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
      </Box>
      {label && <Message id={label} {...labelProps} />}
    </Flex>
  )
}
