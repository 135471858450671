import React from 'react'
import { ALERT_ADD } from 'constants/actionType'
import TextInput from 'components/TextInput'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id,
    resetToken: value.resetToken,
    password: '',
    passwordConfirm: ''
  })

const validation = {
  password: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] }
  ],
  passwordConfirm: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] },
    {
      type: 'fieldEqual',
      name: 'password',
      message: 'reset.error.passNotMatch'
    }
  ]
}

export const fields = ({ state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    password: (
      <TextInput
        type="password"
        placeholder="reset.field.password"
        value={state.password}
        autoComplete="current-password"
        onChange={onTextChange('password')}
        errMsg={state.__error__.password}
      />
    ),
    passwordConfirm: (
      <TextInput
        type="password"
        placeholder="reset.field.passwordConfirm"
        value={state.passwordConfirm}
        autoComplete="new-password"
        onChange={onTextChange('passwordConfirm')}
        errMsg={state.__error__.passwordConfirm}
      />
    )
  }
}

export const handlers = ({ state, setState, app, session }) => ({
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) {
      return
    }

    const { id, resetToken, password } = state
    const variables = {
      resetToken,
      userId: id,
      password
    }
    const query = `
      mutation($resetToken: String!, $userId: ID!, $password: String!) {
        resetUserPass(resetToken: $resetToken, userId: $userId, password: $password)
      }
    `
    const [ok] = await request({ query, variables }, { app, session })
    if (!ok) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'reset.success' }
    })
    setState(initialState())
  }
})
