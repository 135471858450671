import React from 'react'
import { Flex, Button } from 'rebass'
import * as model from 'models/addressModal'
import Form from 'components/Form'
import Message from 'components/Message'
import Modal from 'components/Modal'

export default ({ open, onClose, onConfirm, zIndex }) => (
  <Modal
    title="address.title.add"
    open={open}
    onClose={onClose}
    width={300}
    contentProps={{ bg: 'grey.0' }}
    zIndex={zIndex}
  >
    <Form {...model} actions={{ onConfirm, onClose }}>
      {({ form, action, state }) => (
        <>
          {form.name}
          {form.phone}
          {form.zipcode}
          {form.city}
          {form.district}
          {form.address1}
          <Flex justifyContent="center" alignItems="center" mt={3}>
            <Button
              type="submit"
              variant="accent"
              flex={1}
              justifyContent="center"
            >
              <Message id="btn.confirm" />
            </Button>
          </Flex>
        </>
      )}
    </Form>
  </Modal>
)
