export default {
  'error.required': '此欄位為必填欄',
  'error.min': '最小值需為{val}',
  'error.minLength': '最少字符需為{val}',
  'error.maxLength': '最多字符為{val}',
  'error.token.missing': '請先登錄會員',
  'error.token.expired': '請重新登錄！',
  'error.token.invalid': '帳號與密碼不匹配！',
  'error.token.userNotFound': '帳號與密碼不匹配！',
  'error.token.invalidCaptcha': '驗證碼錯誤！',
  'error.user.invalidCredential': '帳號與密碼不匹配！',
  'error.user.invalidCode': '認證碼錯誤！',
  'error.user.duplicate': '用戶EMAIL已存在',
  'error.user.notFound': '帳號與密碼不匹配！',
  'error.user.notActivated': 'Email尚未認證完成',
  'error.auth.memberNotFound': '帳號與密碼不匹配！',
  'error.location.notFound': '商品已售罄',
  'error.product.notFound': '商品不存在',
  'error.inventoryBalance.notFound': '商品已售罄',
  'error.ecpay.webpayFailed': '創建訂單失敗！',
  'error.discountConfig.notFound': '查無優惠碼',
  'error.discountCode.notFound': '查無優惠碼',
  'error.discount.invalidStartDate': '優惠碼尚未開放',
  'error.discount.invalidEndDate': '優惠碼已過期',
  'error.discount.invalidProduct': '購買商品不適用於此優惠',
  'error.discount.insuffientQuantity': '優惠碼已達使用上限',
  'error.discount.invalidMinAmount': '購買金額未達折扣條件',
  'error.discount.invalidMinQuantity': '購買數量未達折扣條件',
  'error.discount.invalidMaxUserQuantity': '優惠碼已超出每人限用次數',
  'error.discount.invalidMaxQuantityPerUser': '優惠碼已超出每人限用次數',
  'error.discount.combinationNotAllowed': '優惠碼不可疊加使用',
  'error.checkout.missingPaymentMethod': '請選擇支付方式',
  'error.checkout.missingCvsId': '請選擇超商地址',
  'error.checkout.invalidPhone': '聯絡手機格式有誤！',
  'error.checkout.missingCity': '縣市為必填欄位',
  'error.checkout.missingDistrict': '鄉鎮市區為必填欄位',
  'error.checkout.missingStreet': '巷弄 / 街道 / 門牌號為必填欄位',
  'error.ecpay.logisticsFailed': '新增物流訂單失敗',
  'error.ecpay.returnLogisticsFailed': '新增物流退單失敗',
  'error.invalidAddress': '街道、電梯為必填欄位',
  'btn.add': '新增',
  'btn.cancel': '取消',
  'btn.delete': '刪除',
  'btn.submit': '提交',
  'btn.confirm': '確認',
  'btn.close': '關閉',
  'placeholder.textfield': '請輸入{label}',
  'placeholder.select': '請選擇{label}',
  'app.name': '慎康愛生活',
  'app.login': '登錄',
  'app.logout': '登出',
  'app.signup': '註冊',
  'app.search': '請輸入關鍵字全站搜尋',
  'app.section.login': '登錄會員',
  'app.section.signup': '註冊會員',
  'app.login.required': '請先登錄會員！',
  'app.message.paying': '支付中，請稍後...',
  'demo.message': '測試系統將於{time}後重置系統資料',
  'footer.company': '慎康企業股份有限公司',
  'footer.address': '新北市新店區北新路3段207-2號8樓',
  'home.title': 'Welcome - mr.shop',
  'home.category': '全站商品分類',
  'home.promotion': '超級強檔',
  'home.popular': '熱門：',
  'member.error.duplicate': '系統已存在此用戶',
  'category.title': '商品目錄',
  'product.title': '商品詳情',
  'product.title.relatedList': '相關商品',
  'product.section.desc': '商品特色',
  'product.section.refund': '退/換貨需知',
  'product.field.variant': '選項',
  'product.field.quantity': '數量',
  'product.btn.addToCart': '放入購物車',
  'product.btn.backorder': '預購商品',
  'product.success.addToCart': '商品放入購物車',
  'product.message.balance': '還剩 {balance} 件',
  'sidebar.title': '分類',
  'cart.title': '購物車',
  'cart.table.product': '商品',
  'cart.table.unitPrice': '單價',
  'cart.table.quantity': '數量',
  'cart.table.price': '總計',
  'cart.table.outOfStock': '已售完',
  'cart.invoice.price': '訂單金額',
  'cart.discount.price': '折扣金額',
  'cart.total.price': '結帳總金額',
  'cart.shipping': '收貨信息',
  'cart.discount': '折價訊息',
  'cart.memo': '訂單備註',
  'cart.payment': '支付方式',
  'cart.payment.cash': '貨到付款',
  'cart.payment.creditCard': '信用卡',
  'cart.payment.linePay': 'LINE Pay',
  'cart.receipt': '發票',
  'cart.shipping.fee': '運費',
  'cart.shipping.label': '(滿 NT.{fee} 減免運費)',
  'shipping.field.address': '選取收貨超商地址',
  'shipping.type': '物流',
  'shipping.type.SHIP_TO_HOME': '宅配',
  'shipping.type.SHIP_TO_CVS': '超商',
  'shipping.provider': '超商',
  'shipping.provider.FAMI': '全家',
  'shipping.provider.UNIMART': '7-ELEVEN 超商',
  'shipping.provider.HILIFE': '萊爾富',
  'shipping.collect': '是否代收貨款',
  'shipping.collect.N': '不代收貨款',
  'shipping.collect.Y': '代收貨款',
  'receipt.type': '發票類型',
  'receipt.type.RECEIPT_PERSONAL': '二聯式發票',
  'receipt.type.RECEIPT_BUSINESS': '三聯式發票',
  'receipt.type.RECEIPT_DONATE': '捐贈發票',
  'receipt.field.receiptType': '發票類型',
  'receipt.field.receiptNo': '統一編號',
  'receipt.field.receiptTitle': '統編抬頭',
  'receipt.field.donationNo': '社福團體愛心碼',
  'checkout.tab.cvs': '超商取貨',
  'checkout.tab.d2d': '宅配',
  'checkout.title.discount': '全站折價碼',
  'checkout.title.userInfo': '訂購人資料（發票收件地址）',
  'checkout.title.chooseCvs': '選擇超商門市',
  'checkout.title.recipient': '收件人資料',
  'checkout.title.receipt': '發票資料',
  'checkout.title.payment': '付款方式',
  'checkout.label.productAmount': '商品總金額',
  'checkout.label.discountAmount': '活動共折抵',
  'checkout.label.orderAmount': '本次訂單金額',
  'checkout.label.editUser': '修改訂購人資料',
  'checkout.field.name': '訂購人',
  'checkout.field.phone': '聯絡手機',
  'field.zipcode': '郵遞區號',
  'field.city': '縣市',
  'field.district': '鄉鎮市區',
  'field.street': '巷弄 / 街道 / 門牌號',
  'checkout.field.recipientName': '收件人',
  'checkout.field.memo': '訂單備註',
  'checkout.field.discountCode': '優惠碼',
  'checkout.field.cvsName': '超商名稱',
  'checkout.field.cvsAddress': '超商地址',
  'checkout.field.cvsTelephone': '超商電話',
  'checkout.field.sameAsOrderer': '同訂購人',
  'checkout.btn.remove': '移除',
  'checkout.btn.editShipping': '更改配送方式',
  'checkout.btn.selectAll': '全選',
  'checkout.btn.discount': '輸入全站折扣碼',
  'checkout.btn.submit': '確認結帳',
  'checkout.message.noData': '無折價碼',
  'checkout.message.storeAddress': '設為常用收貨地址',
  'checkout.message.paymentNote': '未完成支付之訂單將於下單後24小時後自動取消',
  'checkout.message.shippingNote': '超取重量限制5公斤，超出限制取消訂單',
  'checkout.message.cvsShippingFee': '超取金額未滿NT.{shippingWaiverFee}元，將另收運費',
  'checkout.message.d2dShippingFee': '宅配金額未滿NT.{shippingWaiverFee}元，運費NT.{shippingFee}',
  'cart.btn.viewDetail': '查看我的購物車',
  'cart.btn.apply': '使用',
  'cart.btn.submit': '結帳',
  'cart.btn.search': '選取超商地址',
  'login.title': '登錄用戶',
  'login.field.username': 'EMAIL',
  'login.field.password': '密碼',
  'login.btn.reset': '忘記密碼',
  'login.btn.login': '登錄',
  'login.messsage.forget': '請按郵件中的步驟重設您的登錄密碼',
  'login.success': '成功登錄',
  'logout.success': '成功登出',
  'signup.title': '註冊用戶',
  'signup.field.username': 'EMAIL',
  'signup.field.password': '密碼',
  'signup.field.passwordConfirm': '確認密碼',
  'signup.field.captchaValue': '請輸入下方驗證碼',
  'signup.field.subscription': '我願意收到{name}最新電子報',
  'signup.field.privacy': '我已閱讀並同意{name}隱私權條款',
  'signup.btn.signup': '註冊',
  'signup.error.passNotMatch': '密碼與確認密碼不相同',
  'signup.success': '註冊成功，請查看信箱完成認證',
  'privacy.title': '隱私權條款',
  'reset.title': '重設密碼',
  'reset.field.username': 'EMAIL',
  'reset.field.password': '密碼',
  'reset.field.passwordConfirm': '確認密碼',
  'reset.success': '成功重設密碼',
  'reset.error.passNotMatch': '密碼與確認密碼不相同',
  'activate.title': '會員帳戶認證',
  'activate.pending': '帳戶認證中...',
  'activate.failure': '帳戶認證失敗',
  'activate.success': '帳戶認證成功！',
  'activate.welcome': '歡迎加入慎康商城',
  'activate.login': '您可以登錄帳戶修改個人信息及查看消費訂單！',
  'payment.title': '支付訂單',
  'payment.field.transDate': '日期',
  'payment.field.transType': '交易類型',
  'payment.field.channelType': '支付類型',
  'payment.field.channelProvider': '付款商',
  'payment.field.transAmount': '金額 (NT)',
  'payment.field.status': '狀態',
  'payment.status.INACTIVE': '失敗',
  'payment.status.ACTIVE': '成功',
  'payment.status.PENDING': '待支付',
  'payment.btn.pay': '去支付',
  'payment.transType.payment': '支付',
  'payment.transType.refund': '退款',
  'payment.channelProvider.SELF': '現金',
  'payment.channelProvider.ECPAY': '信用卡',
  'payment.channelProvider.LINEPAY': 'LINE Pay',
  'payment.method.cash': '到貨付款',
  'shipping.field.timestamp': '時間',
  'shipping.field.status': '狀態',
  'shipping.field.memo': '備註',
  'refund.title.add': '新增退貨紀錄',
  'refund.field.id': '退貨單號',
  'refund.field.product': '商品名稱',
  'refund.field.quantity': '退貨數量',
  'refund.field.transDate': '退貨日期',
  'refund.field.cvsReturnNo': '超商退貨編號',
  'refund.field.status': '退貨狀態',
  'refund.status.ACTIVE': '已退款',
  'refund.status.PENDING': '處理中',
  'refund.status.INACTIVE': '已駁回',
  'account.title': '會員中心',
  'account.password.title': '登錄帳密',
  'account.field.password': '密碼',
  'account.field.newPassword': '新密碼',
  'account.field.newPasswordConfirm': '確認新密碼',
  'account.error.passNotMatch': '密碼與確認密碼不相同',
  'account.success.editPassword': '成功更改密碼，請重新登錄',
  'account.basic.title': '會員信息',
  'account.btn.defaultAddress': '預設',
  'account.section.basic': '基本資料',
  'account.field.subscription': '我願意收到{name}最新電子報',
  'address.title.add': '新增收貨地址',
  'address.field.name': '收件人',
  'address.field.phone': '聯絡電話',
  'address.field.zipcode': '郵遞區號',
  'address.field.city': '縣/市',
  'address.field.district': '區/鄉/鎮',
  'address.field.address1': '地址',
  'address.field.address2': '地址2',
  'orderList.title': '訂單管理',
  'orderList.table.orderNo': '訂單號',
  'orderList.table.transDate': '交易日期',
  'orderList.table.totalAmount': '總金額',
  'orderList.table.status': '訂單狀態',
  'orderList.status.PENDING': '待處理',
  'orderList.status.SHIP_PENDING': '待出貨',
  'orderList.status.PAY_PENDING': '待付款',
  'orderList.status.REFUND_PENDING': '待退款',
  'orderList.status.FULL_REFUND': '部份退款',
  'orderList.status.PARTIAL_REFUND': '已退款',
  'orderList.status.ACTIVE': '已完成',
  'orderList.status.INACTIVE': '已取消',
  'orderDetail.title': '訂單詳情',
  'orderDetail.title.cancel': '取消訂單',
  'orderDetail.message.cancel': '確認取消訂單？',
  'orderDetail.btn.cancelOrder': '取消訂單',
  'orderDetail.section.order': '訂單信息',
  'orderDetail.section.shipping': '收貨地址',
  'orderDetail.section.payment': '付款信息',
  'orderDetail.section.shippingStatus': '出貨狀態',
  'orderDetail.section.refund': '退貨紀錄',
  'orderDetail.table.product': '商品',
  'orderDetail.table.unitPrice': '單價 (NT)',
  'orderDetail.table.quantity': '數量',
  'orderDetail.table.price': '總計 (NT)',
  'orderDetail.field.ticketNo': '訂單編號',
  'orderDetail.field.transDate': '訂單日期',
  'orderDetail.field.paymentMethod': '支付方式',
  'orderDetail.field.userInfo.name': '訂購人姓名',
  'orderDetail.field.userInfo.phone': '訂購人電話',
  'orderDetail.field.userInfo.address': '訂購人地址',
  'orderDetail.field.shipping.name': '收件人姓名',
  'orderDetail.field.shipping.phone': '收件人電話',
  'orderDetail.field.shipping.address': '收件人地址',
  'orderDetail.field.shipping.memo': '備註',
  'orderDetail.field.orderPrice': '訂單金額',
  'orderDetail.field.discountCode': '折扣碼',
  'orderDetail.field.discountPrice': '折扣金額',
  'orderDetail.field.totalPrice': '總金額',
  'orderDetail.field.shippingFee': '運費',
  'orderDetail.field.status': '訂單狀態',
  'orderDetail.btn.addRefund': '申請退款',
  'order.status.INACTIVE': '已取消',
  'order.status.PENDING': '待處理',
  'order.status.ACTIVE': '已完成',
  'order.status.SHIP_PENDING': '待出貨',
  'order.status.SHIPPED': '已發貨',
  'order.status.PAY_PENDING': '待付款',
  'order.status.ARRIVED': '已送達',
  'order.status.FULL_REFUND': '部份退款',
  'order.status.PARTIAL_REFUND': '已退款',
  'support.title': '聯絡客服',
  'support.field.type': '類型',
  'support.field.subject': '標題',
  'support.field.message': '內容...',
  'support.field.reply': '回覆...',
  'support.field.status': '狀態',
  'support.field.createdAt': '日期',
  'support.type.BILLING': '支付',
  'support.type.PRODUCT': '商品',
  'support.type.ORDER': '訂單',
  'support.type.OTHER': '其他',
  'support.status.OPEN': '處理中',
  'support.status.CLOSED': '關閉',
  'support.status.RESOLVED': '已處理',
}
