import { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import AppContext from 'contexts/AppContext'
import { NAV_SET } from 'constants/actionType'

export default ({ navId, title, formatTitle = true, children }) => {
  const app = useContext(AppContext)
  const { formatMessage } = useIntl()

  useEffect(() => {
    document.title = formatTitle ? formatMessage({ id: title }) : title

    if (navId) {
      app.dispatch({ type: NAV_SET, id: navId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navId, title])

  return children
}
