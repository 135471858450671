import React, { useState } from 'react'
import { Flex, Button } from 'rebass'
import Message from 'components/Message'
import Modal from 'components/Modal'

export default ({
  title,
  message,
  onClick,
  children,
  variant,
  fontSize,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button
        type="button"
        variant={variant || 'table'}
        onClick={handleOpen}
        {...props}
      >
        <Flex alignItems="center" fontSize={fontSize || 4}>
          {children}
        </Flex>
      </Button>
      <Modal bg="grey.0" title={title} open={open} onClose={handleClose} width={300}>
        <Message id={message} textAlign="left" />
        <Flex justifyContent="flex-end" alignItems="center" mt={3}>
          <Button
            mr={2}
            type="button"
            variant="plain"
            onClick={handleClose}
          >
            <Message id="btn.cancel" />
          </Button>
          <Button
            type="button"
            variant="accent"
            onClick={() => onClick(handleClose)}
          >
            <Message id="btn.confirm" />
          </Button>
        </Flex>
      </Modal>
    </>
  )
}
