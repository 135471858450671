import React from 'react'
import { withRouter } from 'react-router'
import { Button } from 'rebass'

export default withRouter(({ history, href, onClick, children, ...props }) => (
  <Button
    type="button"
    onClick={() => (onClick ? onClick({ history }) : history.push(href || '/'))}
    {...props}
  >
    {children}
  </Button>
))
