import React from 'react'
import { Flex, Button } from 'rebass'
import Form from 'components/Form'
import Message from 'components/Message'
import Modal from 'components/Modal'
import * as model from 'models/supportModal'

export default ({ open, onClose, refreshList }) => (
  <Modal
    title="support.title"
    open={open}
    onClose={onClose}
    width={[1, 400]}
    contentProps={{ bg: 'grey.0' }}
  >
    <Form {...model} actions={{ handleClose: onClose, refreshList }}>
      {({ form, action, state }) => (
        <>
          {form.type}
          {form.subject}
          {form.message}
          <Flex justifyContent="flex-end" alignItems="center" mt={3}>
            <Button type="button" variant="default" mr={2} onClick={onClose}>
              <Message id="btn.cancel" />
            </Button>
            <Button type="submit" variant="accent">
              <Message id="btn.submit" />
            </Button>
          </Flex>
        </>
      )}
    </Form>
  </Modal>
)
