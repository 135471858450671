import React from 'react'
import { Flex, Box } from 'rebass'

export default ({ children, fullWidth, sx, contentProps, ...props }) => (
  <Flex
    width="100%"
    justifyContent="center"
    alignItems="flex-start"
    sx={{
      overflowX: 'auto',
      ...sx
    }}
    {...props}
  >
    <Box
      width={fullWidth ? '100%' : ['100%', '100%', '100%', '992px']}
      px={fullWidth ? 4 : 0}
      py={4}
      {...contentProps}
    >
      {children}
    </Box>
  </Flex>
)
