import React from 'react'
import { FormattedMessage } from 'react-intl'
import { request } from 'utilities/requestUtil'
import { BREADCRUMB_SET } from 'constants/actionType'

export const onLoad = async ({ setState, session, app, key }) => {
  const merchantId = app.state.merchant.id
  if (!merchantId) return

  const variables = { merchantId, input: { key } }
  const query = `
    query($merchantId: ID!, $input: ProductQueryInput) {
      productShelves(merchantId: $merchantId, input: $input) {
        id
        categoryId
        productName
        primaryImage {
          src
          alt
        }
        priceRange
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return

  setState({
    categories: data.categories,
    products: data.productShelves.map(item => ({
      ...item,
      spu: item.productName
    }))
  })
}

export const setBreadcrumb = ({ app, categories, categoryId }) => {
  if (categories.length === 0 || !categoryId) return

  const category = categories.find(item => item.id === categoryId)
  const items = [{ label: 'Home', url: '/' }]

  if (category) {
    items.push({ label: category.name })
  } else {
    items.push({ label: <FormattedMessage id="category.title" /> })
  }

  app.dispatch({ type: BREADCRUMB_SET, items })
}

export const findNodeIdx = (nodes, id) => {
  if (!id) return -1

  for (var i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    if (node.id === id) return i
    if (findNode(node.children, id)) return i
  }

  return -1
}

export const findNode = (node, id) => {
  if (!node || node.length === 0) return null

  for (var item of node) {
    if (item.id === `${id}`) return item

    const category = findNode(item.children, id)
    if (category) return category
  }

  return null
}

export const findChildIds = node => {
  const result = []
  const addChildren = children => {
    if (!children || children.length === 0) return

    for (var item of children) {
      result.push(item.id)
      addChildren(item.children)
    }
  }

  result.push(node.id)
  addChildren(node.children)

  return result
}
