import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Button } from 'rebass'
import Message from 'components/Message'
import Form from 'components/Form'
import Page from 'components/Page'
import Container from 'components/Container'
import * as model from 'models/loginForget'

export default () => (
  <Page title="reset.title">
    <Container
      minHeight="calc(100vh - 242px)"
      bg="grey.0"
      contentProps={{ width: '300px' }}
    >
      <Form {...model}>
        {({ form, state }) => (
          <>
            <Flex variant="header" mb={3}>
              <Message id="reset.title" />
            </Flex>
            {form.username}
            {form.captchaValue}
            <Flex justifyContent="center">
              <Box dangerouslySetInnerHTML={{ __html: state.captchaImage }} />
            </Flex>
            <Button mt={3} width={1} variant="accent">
              <FormattedMessage id="btn.submit" />
            </Button>
          </>
        )}
      </Form>
    </Container>
  </Page>
)
