import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, Flex, Button } from 'rebass'
import { MdSearch } from 'react-icons/md'

export default ({ placeholder, value, onChange, ...props }) => {
  const [state, setState] = useState(value || '')
  const [focus, setFocus] = useState(false)
  const { formatMessage: message } = useIntl()

  useEffect(() => {
    setState(value || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Box
      as="form"
      width={1}
      onSubmit={event => {
        event.preventDefault()
        onChange(state)
      }}
    >
      <Flex
        alignItems="center"
        width={1}
        p={0}
        m={0}
        bg="grey.0"
        sx={{
          boxSizing: 'border-box',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: focus ? 'accent.1' : 'grey.3',
          borderRadius: '4px',
          transition: 'border-color 0.2s linear',
          willChange: 'border-color'
        }}
        {...props}
      >
        <Box
          as="input"
          bg="transparent"
          placeholder={placeholder ? message({ id: placeholder }) : ''}
          width={1}
          py={2}
          pl={3}
          mr="auto"
          fontSize={2}
          value={state}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={event => setState(event.target.value)}
          sx={{
            border: 'none',
            outline: 'none'
          }}
        />
        <Button
          variant="plain"
          m={0}
          py={0}
          px={3}
          fontSize={3}
          lineHeight={0}
          onClick={() => {
            onChange(state)
          }}
        >
          <MdSearch />
        </Button>
      </Flex>
    </Box>
  )
}
