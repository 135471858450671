import React, { useEffect, useContext, useState } from 'react'
import { Box } from 'rebass'
import { onLoad, initialState, fields } from 'models/account'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Divider from 'components/Divider'
import Page from 'components/Page'
import Message from 'components/Message'
import AddressFragment from 'views/AddressFragment'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState())

  useEffect(() => {
    onLoad({ state, setState, app, session })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const form = fields({ app, session, state, setState })

  return (
    <Page title="account.basic.title">
      <Box bg="grey.0" mb={3}>
        <Message
          id="account.section.basic"
          variant="dark"
          p={3}
          justifyContent="center"
        />
        <Divider bg="grey.3" />
        {form.subscription}
      </Box>
      <Box bg="grey.0" mb={3}>
        <Message
          id="cart.shipping"
          variant="dark"
          p={3}
          justifyContent="center"
        />
        <Divider bg="grey.3" />
        <AddressFragment />
      </Box>
    </Page>
  )
}
