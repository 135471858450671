import React, { useState } from 'react'
import { Flex, Box, Button } from 'rebass'

export default ({ value, ...props }) => {
  const [active, setActive] = useState(value[0].id)

  return (
    <Box {...props}>
      <Flex
        sx={{
          overflow: 'hidden',
          borderBottomWidth: '5px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'primary.2'
        }}
      >
        {value.map(item => (
          <Button
            key={item.id}
            variant={item.id === active ? 'tabActive' : 'tab'}
            onClick={() => setActive(item.id)}
          >
            {item.label}
          </Button>
        ))}
      </Flex>

      {value
        .filter(item => item.id === active)
        .map(item => (
          <Box
            key={item.id}
            p={3}
            bg="grey.0"
            sx={{
              borderBottomRightRadius: '3px',
              borderBottomLeftRadius: '3px',
              boxShadow: 'small'
            }}
          >
            {item.render()}
          </Box>
        ))}
    </Box>
  )
}
