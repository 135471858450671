import React, { useState } from 'react'
import { Flex, Button } from 'rebass'
import Modal from 'components/Modal'
import Message from 'components/Message'
import TextInput from 'components/TextInput'

export default ({ open, action }) => {
  const [state, setState] = useState('')

  return (
    <Modal
      title="checkout.title.discount"
      open={open}
      onClose={action.handleClose}
      width={300}
      contentProps={{ bg: 'grey.0' }}
      as="form"
      onSubmit={(event) => {
        event.preventDefault()

        const ok = action.handleSubmit(state)
        if (!ok) return false

        action.handleClose()
        setState('')
      }}
    >
      <TextInput
        placeholder="checkout.field.discountCode"
        value={state}
        onChange={({ target }) => {
          setState(target.value)
        }}
      />
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          type="button"
          variant="secondary"
          mr={2}
          onClick={action.handleClose}
        >
          <Message id="btn.close" />
        </Button>
        <Button type="submit" variant="accent">
          <Message id="btn.confirm" />
        </Button>
      </Flex>
    </Modal>
  )
}
