import React, { Suspense, lazy } from 'react'
import { Box, Flex, Text } from 'rebass'
import { FormattedMessage } from 'react-intl'
import Image from 'components/Image'
import Link from 'components/Link'
import {
  ALERT_ADD,
  BREADCRUMB_SET,
  CART_SET,
  USER_INFO_SET,
} from 'constants/actionType'
import { request } from 'utilities/requestUtil'
import Checkbox from 'components/Checkbox'
import Message from 'components/Message'
import TextInput from 'components/TextInput'
import {
  handleKeyPress,
  handleSelectChange,
  handleTextChange,
  initializeState,
  validateField,
  validateForm,
} from 'utilities/formUtil'
import Select from 'components/Select'
import Center from 'components/Center'
import { getLocalJson } from 'reducers/app'

const Address = lazy(() => import('components/TaiwanAddress'))

export const initialState = (value = {}, app, message) => {
  // const { channelProviders = [] } = app.state.merchant
  return {
    isCvs: value.isCvs || false,
    isCvsChecked: value.isCvsChecked || false,
    isD2dChecked: value.isD2dChecked || false,
    cvsCartItems: value.cvsCartItems || [],
    d2dCartItems: value.d2dCartItems || [],
    cvsDiscounts: value.cvsDiscounts || [],
    d2dDiscounts: value.d2dDiscounts || [],
    showCvsDetail: value.showCvsDetail || false,
    showD2dDetail: value.showD2dDetail || false,
    shippingProvider: value.shippingProvider || [],
    shippingCollect: getShippingCollect(value.shippingCollect, message),
    cvsId: value.cvsId,
    cvsName: value.cvsName,
    cvsAddress: value.cvsAddress,
    cvsTelephone: value.cvsTelephone,
    sameAsUser: value.sameAsUser,
    ...initializeState({
      name: value.name || '',
      phone: value.phone || '',
      zipcode: value.zipcode || '',
      city: value.city || '',
      district: value.district || '',
      address: value.address || '',
      recipientName: value.recipientName || '',
      recipientPhone: value.recipientPhone || '',
      recipientZipcode: value.recipientZipcode || '',
      recipientCity: value.recipientCity || '',
      recipientDistrict: value.recipientDistrict || '',
      recipientAddress: value.recipientAddress || '',
      receiptType: value.receiptType || {
        value: 'RECEIPT_PERSONAL',
        label: message({ id: 'receipt.type.RECEIPT_PERSONAL' }),
      },
      receiptNo: value.receiptNo || '',
      receiptTitle: value.receiptTitle || '',
      // paymentMethod: getActivePaymentMethod(channelProviders),
      paymentMethod: '',
    }),
  }
}

function getShippingProviders(cvsCartItems, message) {
  if (!cvsCartItems || cvsCartItems.length === 0) return []
  const cartItems = cvsCartItems.filter((item) => item.isChecked)

  let famiCount = 0
  let unimartCount = 0
  let hilifeCount = 0

  cartItems.forEach((cartItem) => {
    const { shippingProviders } = cartItem.extra || {}
    const { FAMI, HILIFE, UNIMART } = shippingProviders || {}
    if (FAMI && FAMI.status === 'ACTIVE') famiCount++
    if (HILIFE && HILIFE.status === 'ACTIVE') hilifeCount++
    if (UNIMART && UNIMART.status === 'ACTIVE') unimartCount++
  })

  const result = []

  if (famiCount === cartItems.length) {
    result.push({
      value: 'FAMI',
      label: message({ id: 'shipping.provider.FAMI' }),
    })
  }

  if (unimartCount === cartItems.length) {
    result.push({
      value: 'UNIMART',
      label: message({ id: 'shipping.provider.UNIMART' }),
    })
  }

  if (hilifeCount === cartItems.length) {
    result.push({
      value: 'HILIFE',
      label: message({ id: 'shipping.provider.HILIFE' }),
    })
  }

  return result
}

function getShippingCollects(message) {
  return [
    {
      value: 'N',
      label: message({ id: 'shipping.collect.N' }),
    },
    {
      value: 'Y',
      label: message({ id: 'shipping.collect.Y' }),
    },
  ]
}

function getShippingCollect(value, message) {
  const shippingCollects = getShippingCollects(message)
  return value || shippingCollects[0]
}

const validation = (state) => {
  const { isCvs } = state
  const result = {
    name: [
      { type: 'required', message: 'error.required' },
      { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
    ],
    phone: [
      { type: 'required', message: 'error.required' },
      { type: 'maxLength', val: 20, message: ['error.maxLength', { val: 25 }] },
    ],
    receiptType: [{ type: 'required', message: 'error.required' }],
  }
  if (isCvs) return result

  result.recipientName = [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
  ]
  result.recipientPhone = [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 20, message: ['error.maxLength', { val: 25 }] },
  ]
  return result
}

function renderProductList({ state, setState, cartItems, actions }) {
  if (!cartItems) return null
  return (
    <Box>
      {renderProductHeader({ state, setState })}
      {cartItems.map((item, index) =>
        renderProductItem({ state, setState, item, index, actions })
      )}
    </Box>
  )
}

function renderProductHeader({ state, setState }) {
  const label = state.isCvs ? 'checkout.tab.cvs' : 'checkout.tab.d2d'
  return (
    <Flex
      alignItems="center"
      py={2}
      px={3}
      bg="grey.0"
      sx={{
        borderBottomColor: 'grey.3',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
      }}
    >
      {renderHeaderCheckbox({ state, setState })}
      <Message id={label} fontSize={3} fontWeight="bold" />
    </Flex>
  )
}

export function renderHeaderCheckbox({ state, setState }) {
  const isCheckedName = state.isCvs ? 'isCvsChecked' : 'isD2dChecked'
  const isChecked = state[isCheckedName]
  return (
    <Checkbox
      mr={3}
      checked={isChecked}
      onChange={() => {
        const isCheckedName = state.isCvs ? 'isCvsChecked' : 'isD2dChecked'
        const isChecked = !state[isCheckedName]
        const cartItemName = state.isCvs ? 'cvsCartItems' : 'd2dCartItems'
        const cartItems = state[cartItemName]
        const newCartItems = cartItems.map((item) => ({ ...item, isChecked }))

        setState({
          ...state,
          [isCheckedName]: isChecked,
          [cartItemName]: newCartItems,
        })
      }}
    />
  )
}

function renderProductItem({ state, setState, item, index, actions }) {
  if (!item) return null

  const { isCvs } = state
  const cartItemName = isCvs ? 'cvsCartItems' : 'd2dCartItems'
  const cartItems = state[cartItemName]
  const cartItem = cartItems[index]
  const { isChecked } = cartItem || false
  const { shippingProviders } = item.extra || {}
  const isSwitchable = isCvs
    ? hasD2dProvider(shippingProviders)
    : hasCvsProvider(shippingProviders)
  const outOfStock = item.balance < item.quantity
  return (
    <Flex
      key={`product-item-${index}`}
      alignItems="center"
      py={2}
      px={3}
      mb={1}
      bg="grey.0"
    >
      <Checkbox
        disabled={outOfStock}
        checked={isChecked}
        onChange={() => {
          const newCartItems = [...cartItems]
          const newCartItem = newCartItems[index]
          newCartItem.isChecked = !newCartItem.isChecked
          setState({ ...state, [cartItemName]: newCartItems })
        }}
      />
      <Center mx={2} flex={1}>
        <Image
          src={item.image.src}
          sx={{ maxWidth: '130px', maxHeight: '130px', objectFit: 'contain' }}
        />
      </Center>
      <Flex flex={3} flexDirection={['column', 'row']} alignItems="center">
        <Flex flex={3} flexDirection="column" width={1}>
          <Text fontSize={1} color="grey.4">
            {item.sku}
          </Text>
          <Link href={`/product/${item.productId}`} fontWeight="bold">
            {item.spu}
          </Link>
          {item.options.map((item, index) => (
            <Flex my={1} key={`option-${index}`} alignItems="center">
              <Text mr={2} fontWeight="bold">
                {item.name + ': '}
              </Text>
              <Text>{item.value}</Text>
            </Flex>
          ))}
          {outOfStock && (
            <Message
              mr={2}
              id="cart.table.outOfStock"
              sx={{ whiteSpace: 'nowrap', color: 'accent.1' }}
            />
          )}
        </Flex>
        <Flex flex={1} width={[1, 'auto']} flexDirection="column">
          <Flex alignItems="center">
            <Message
              mr={2}
              id="cart.table.unitPrice"
              sx={{ whiteSpace: 'nowrap' }}
            />
            <Text color="accent.1" fontSize={1}>
              NT.
            </Text>
            <Text color="accent.1" fontSize={4}>
              {item.price}
            </Text>
          </Flex>
          <Flex alignItems="baseline">
            <Message
              mr={2}
              id="cart.table.quantity"
              sx={{ whiteSpace: 'nowrap' }}
            />
            <Text>{item.quantity}</Text>
          </Flex>
        </Flex>
        <Flex
          flex={1}
          flexDirection={['row', 'column']}
          justifyContent="flex-end"
          width={1}
        >
          <Link
            mr={[2, 0]}
            disabled={!isSwitchable}
            onClick={() => actions.handleShippingOpen(index)}
            text="checkout.btn.editShipping"
            sx={{ whiteSpace: 'nowrap' }}
          />
          <Link
            sx={{ whiteSpace: 'nowrap' }}
            onClick={() => {
              const newCartItems = [...cartItems]
              newCartItems.splice(index, 1)
              const showDetail = state.isCvs ? 'showCvsDetail' : 'showD2dDetail'
              setState({
                ...state,
                [cartItemName]: newCartItems,
                [showDetail]: false,
              })
            }}
          >
            <Message id="checkout.btn.remove" />
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}

function hasCvsProvider(providers) {
  const { FAMI, HILIFE, UNIMART } = providers || {}
  if (FAMI && FAMI.status === 'ACTIVE') return true
  if (HILIFE && HILIFE.status === 'ACTIVE') return true
  if (UNIMART && UNIMART.status === 'ACTIVE') return true
  return false
}

function hasD2dProvider(providers) {
  const { DOOR_TO_DOOR } = providers || {}
  return DOOR_TO_DOOR && DOOR_TO_DOOR.status === 'ACTIVE'
}

export const fields = ({ state, setState, message, actions }) => {
  const validator = validation(state)
  const onTextChange = (id) => handleTextChange(id, state, setState, validator)

  return {
    cvsProduct: renderProductList({
      state,
      setState,
      cartItems: state.cvsCartItems,
      actions,
    }),
    d2dProduct: renderProductList({
      state,
      setState,
      cartItems: state.d2dCartItems,
      actions,
    }),
    shippingProvider: (
      <Select
        isClearable={false}
        isSearchable={false}
        fieldProps={{ flex: 1, mb: 0 }}
        label="shipping.provider"
        options={getShippingProviders(state.cvsCartItems, message)}
        value={state.shippingProvider}
        onChange={(shippingProvider) => {
          setState({
            ...state,
            shippingProvider,
            cvsId: '',
            cvsName: '',
            cvsAddress: '',
            cvsTelephone: '',
          })
        }}
      />
    ),
    shippingCollect: (
      <Select
        isClearable={false}
        isSearchable={false}
        fieldProps={{ mb: 0, ml: 2, width: 1 }}
        label="shipping.collect"
        options={getShippingCollects(message)}
        value={state.shippingCollect}
        onChange={(shippingCollect) => {
          setState({ ...state, shippingCollect, paymentMethod: null })
        }}
      />
    ),
    name: (
      <TextInput
        placeholder="checkout.field.name"
        autoComplete="name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    phone: (
      <TextInput
        placeholder="checkout.field.phone"
        value={state.phone}
        autoComplete="tel"
        onKeyPress={handleKeyPress(
          state,
          setState,
          'phone',
          /[0-9|\-|\\(|\\)]/
        )}
        onChange={onTextChange('phone')}
        errMsg={state.__error__.phone}
      />
    ),
    address: (
      <Suspense fallback="Loading...">
        <Address
          id="address"
          value={{
            zipcode: state.zipcode,
            city: state.city,
            district: state.district,
            street: state.address,
          }}
          onChange={(value) => {
            const { message } = validateField(value, validator.address, state)
            const newState = {
              ...state,
              zipcode: value.zipcode,
              city: value.city,
              district: value.district,
              address: value.street,
              __error__: { ...state.__error__, address: message },
            }
            if (state.sameAsUser) {
              newState.recipientName = value.name
              newState.recipientPhone = value.phone
              newState.recipientZipcode = value.zipcode
              newState.recipientCity = value.city
              newState.recipientDistrict = value.district
              newState.recipientAddress = value.street
            }
            setState(newState)
          }}
          errMsg={state.__error__.address}
        />
      </Suspense>
    ),
    recipientName: (
      <TextInput
        placeholder="checkout.field.recipientName"
        autoComplete="name"
        value={state.recipientName}
        onChange={onTextChange('recipientName')}
        errMsg={state.__error__.recipientName}
      />
    ),
    recipientPhone: (
      <TextInput
        placeholder="checkout.field.phone"
        value={state.recipientPhone}
        autoComplete="tel"
        onKeyPress={handleKeyPress(
          state,
          setState,
          'recipientPhone',
          /[0-9|\-|\\(|\\)]/
        )}
        onChange={onTextChange('recipientPhone')}
        errMsg={state.__error__.recipientPhone}
      />
    ),
    recipientAddress: (
      <Suspense fallback="Loading...">
        <Address
          id="recipientAddress"
          value={{
            zipcode: state.recipientZipcode,
            city: state.recipientCity,
            district: state.recipientDistrict,
            street: state.recipientAddress,
          }}
          onChange={(value) => {
            const { message } = validateField(
              value,
              validator.recipientAddress,
              state
            )
            setState({
              ...state,
              recipientZipcode: value.zipcode,
              recipientCity: value.city,
              recipientDistrict: value.district,
              recipientAddress: value.street,
              __error__: { ...state.__error__, recipientAddress: message },
            })
          }}
          errMsg={state.__error__.recipientAddress}
        />
      </Suspense>
    ),
    receiptType: (
      <Select
        isClearable={false}
        isSearchable={false}
        fieldProps={{ flex: 1, mb: 0 }}
        placeholder="receipt.type"
        options={[
          {
            value: 'RECEIPT_PERSONAL',
            label: message({ id: 'receipt.type.RECEIPT_PERSONAL' }),
          },
          {
            value: 'RECEIPT_BUSINESS',
            label: message({ id: 'receipt.type.RECEIPT_BUSINESS' }),
          },
          // {
          //   value: 'RECEIPT_DONATE',
          //   label: message({ id: 'receipt.type.RECEIPT_DONATE' }),
          // },
        ]}
        value={state.receiptType}
        onChange={(item) => {
          handleSelectChange('receiptType', state, setState, validator, () => ({
            receiptNo: '',
            receiptTitle: '',
          }))(item)
        }}
      />
    ),
    receiptNo: (
      <TextInput
        containerProps={{ mb: 0 }}
        placeholder="receipt.field.receiptNo"
        value={state.receiptNo}
        onChange={onTextChange('receiptNo')}
        onKeyPress={handleKeyPress(state, setState, 'receiptNo', /[0-9]/)}
        errMsg={state.__error__.receiptNo}
      />
    ),
    receiptTitle: (
      <TextInput
        containerProps={{ mb: 0 }}
        placeholder="receipt.field.receiptTitle"
        value={state.receiptTitle}
        onChange={onTextChange('receiptTitle')}
        errMsg={state.__error__.receiptTitle}
      />
    ),
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  message,
  url,
  history,
  actions,
}) => ({
  handleLoad: async () => {
    const cvsId = url.searchParams.get('storeId')
    const cvsName = url.searchParams.get('storeName')
    const cvsAddress = url.searchParams.get('storeAddress')
    const cvsTelephone = url.searchParams.get('storeTelephone')

    const data = await getUser({ app, session })
    if (!data) {
      let redirectUrl = '/checkout'
      if (cvsId) {
        redirectUrl += `?storeId=${cvsId}&storeName=${cvsName}&storeAddress=${cvsAddress}&storeTelephone=${cvsTelephone}`
      }
      history.push('/login?redirect=' + encodeURIComponent(redirectUrl))
      return
    }
    setBreadcrumb({ app })

    // const { checkoutData } = app.state
    const checkoutData = getLocalJson('checkout')
    if (checkoutData) {
      checkoutData.cvsId = cvsId
      checkoutData.cvsName = cvsName
      checkoutData.cvsAddress = cvsAddress
      checkoutData.cvsTelephone = cvsTelephone
      setState(initialState(checkoutData, app, message))
      // app.dispatch({ type: CHECKOUT_SET, item: null })
      window.localStorage.setItem('checkout', null)
      return
    }

    const products = await getCartItems(app, session)
    const { cvsCartItems, d2dCartItems } = filterCartItems(products)
    const { userInfo } = app.state
    const initState = {
      ...state,
      ...userInfo,
      cvsCartItems,
      d2dCartItems,
    }
    setState(initialState(initState, app, message))
  },
  confirmProduct: () => {
    // const { channelProviders = [] } = app.state.merchant
    // const { isCvs, shippingCollect = {} } = state
    // const isCvsCollect = shippingCollect.value || 'N'
    // const disableCash = isCvs && isCvsCollect === 'N'
    // const paymentMethod = getActivePaymentMethod(channelProviders, disableCash)
    const shippingProviders = getShippingProviders(state.cvsCartItems, message)
    const showDetail = state.isCvs ? 'showCvsDetail' : 'showD2dDetail'
    setState({
      ...state,
      paymentMethod: null,
      shippingProviders,
      shippingProvider: shippingProviders[0],
      [showDetail]: true,
    })
  },
  handleCvsMap: async (event) => {
    event.preventDefault()
    const data = await getUser({ app, session })
    if (!data) {
      history.push('/login?redirect=' + encodeURI('/checkout'))
      return
    }
    // app.dispatch({ type: CHECKOUT_SET, item: state })
    window.localStorage.setItem('checkout', JSON.stringify(state))
    document.forms['cvs-map'].submit()
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    const validator = validation(state)
    if (!validateForm({ state, setState, validation: validator })) return
    if (!validateFormExtra({ session, state })) return

    const { isCvs, paymentMethod } = state
    const cartItemName = isCvs ? 'cvsCartItems' : 'd2dCartItems'
    const cartItems = state[cartItemName].filter((item) => item.isChecked)
    const userInfo = {
      name: state.name,
      phone: state.phone,
      zipcode: state.zipcode,
      city: state.city,
      district: state.district,
      address: state.address,
    }
    app.dispatch({ type: USER_INFO_SET, item: userInfo })
    actions.setDisableSubmit(true)

    const ticketId = await createOrder({ session, app, state, cartItems })
    if (!ticketId) {
      actions.setDisableSubmit(false)
      return
    }

    const resp = await createWebpay({
      state,
      session,
      app,
      ticketId,
      paymentMethod,
      cartItems,
    })
    if (!resp) {
      actions.setDisableSubmit(false)
      return
    }

    const restCartItems = isCvs ? state.d2dCartItems : state.cvsCartItems
    restCartItems.concat(state[cartItemName].filter((item) => !item.isChecked))

    // app.dispatch({ type: CHECKOUT_SET, item: null })
    window.localStorage.setItem('checkout', null)
    app.dispatch({ type: CART_SET, items: restCartItems })

    if (paymentMethod === 'CASH') {
      history.push('/member/order')
      return
    }

    if (paymentMethod === 'ECPAY') {
      const { url, form } = resp
      ecpayRedirect({ url, form })
      return
    }

    if (paymentMethod === 'LINEPAY') {
      window.location.href = resp.paymentUrl.web
      return
    }
  },
  getCheckoutCount: () => {
    const cartItemName = state.isCvs ? 'cvsCartItems' : 'd2dCartItems'
    const cartItems = state[cartItemName].filter((item) => item.isChecked)
    return cartItems.length || 0
  },
  getProductAmount: () => getProductAmount(state),
  getDiscountAmount: () => getDiscountAmount(state),
  getInvoiceAmount: () => getInvoiceAmount(state),
  getShippingFee: () => getShippingFee(state),
  getShippingConfig: () => getShippingConfig(state),
  getBillingAmount: () => getBillingAmount(state),
  switchShippingType: (index) => {
    const cvsCartItems = [...state.cvsCartItems]
    const d2dCartItems = [...state.d2dCartItems]

    if (state.isCvs) {
      const cvsCartItem = { ...cvsCartItems[index] }
      cvsCartItems.splice(index, 1)
      d2dCartItems.push(cvsCartItem)
    } else {
      const d2dCartItem = { ...d2dCartItems[index] }
      d2dCartItems.splice(index, 1)
      cvsCartItems.push(d2dCartItem)
    }

    setState({
      ...state,
      cvsCartItems,
      d2dCartItems,
      showCvsDetail: false,
      showD2dDetail: false,
    })
  },
  addDiscount: async (value) => {
    if (!value) return

    const cartName = state.isCvs ? 'cvsCartItems' : 'd2dCartItems'
    const cartItems = state[cartName]
    const discountName = state.isCvs ? 'cvsDiscounts' : 'd2dDiscounts'
    const discounts = [...state[discountName]]
    const discount = await getDiscountConfig({
      app,
      session,
      state,
      discounts,
      code: value,
      cartItems,
    })
    if (!discount) return

    discounts.push(discount)
    validateDiscount({ session, state, discounts })
    setState({ ...state, [discountName]: discounts })
  },
  removeDiscount: async () => {
    const discountName = state.isCvs ? 'cvsDiscounts' : 'd2dDiscounts'
    setState({ ...state, [discountName]: [] })
  },
  setPaymentMethod: (paymentMethod) => {
    setState({ ...state, paymentMethod })
  },
})

function getProductAmount(state) {
  const cartItemName = state.isCvs ? 'cvsCartItems' : 'd2dCartItems'
  const cartItems = state[cartItemName].filter((item) => item.isChecked)
  return cartItems.reduce((total, item) => {
    total += item.price * item.quantity
    return total
  }, 0)
}

function getDiscountAmount(state) {
  const discountName = state.isCvs ? 'cvsDiscounts' : 'd2dDiscounts'
  return Math.round(
    state[discountName].reduce((result, item) => {
      result += item.amount
      return result
    }, 0)
  )
}

function getShippingFee(state) {
  return getShippingConfig(state).shippingFee
}

function getShippingConfig(state) {
  const { isCvs, shippingProvider = {} } = state
  const discountName = isCvs ? 'cvsDiscounts' : 'd2dDiscounts'
  const discounts = state[discountName]
  const hasFreeShipping = discounts.some((i) => i.type === 'FREE_SHIPPING')
  if (hasFreeShipping) return { shippingFee: 0, shippingWaiverFee: 0 }

  const invoicePrice = getInvoiceAmount(state)
  const cartItemName = state.isCvs ? 'cvsCartItems' : 'd2dCartItems'
  const cartItems = state[cartItemName].filter((item) => item.isChecked)
  if (cartItems.length === 0) return { shippingFee: 0, shippingWaiverFee: 0 }

  const providerId = isCvs ? shippingProvider.value : 'DOOR_TO_DOOR'
  let maxShippingFee = 0
  let maxShippingWaiverFee = 0

  cartItems.forEach((item) => {
    const extra = item.extra || {}
    const shippingProviders = extra.shippingProviders || {}
    const shippingWaiverFee = extra.shippingWaiverFee || 0
    const shippingProvider = shippingProviders[providerId] || {}
    const { shippingFee = 0 } = shippingProvider

    if (shippingFee > maxShippingFee) {
      maxShippingFee = shippingFee
    }
    if (shippingWaiverFee > maxShippingWaiverFee) {
      maxShippingWaiverFee = shippingWaiverFee
    }
  })
  return {
    shippingFee: invoicePrice >= maxShippingWaiverFee ? 0 : maxShippingFee,
    shippingWaiverFee: maxShippingWaiverFee,
  }
}

function getInvoiceAmount(state) {
  const amount = getProductAmount(state) - getDiscountAmount(state)
  return amount < 0 ? 0 : amount
}

function getBillingAmount(state) {
  const invoiceAmount = getInvoiceAmount(state)
  const shippingFee = getShippingFee(state)
  return invoiceAmount + shippingFee
}

const getUser = async ({ app, session }) => {
  const query = `
    query {
      user {
        id
        username
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query }, { session, app })
  if (!ok) return null

  return data.user
}

export const setBreadcrumb = ({ app }) => {
  const items = [
    { label: 'Home', url: '/' },
    { label: <FormattedMessage id="cart.title" /> },
  ]

  app.dispatch({ type: BREADCRUMB_SET, items })
}

async function getCartItems(app, session) {
  const { cartItems } = app.state
  const productVariantId = cartItems.map((item) => item.id)
  const products = await getProducts({ app, session, productVariantId })
  const balances = await getBalances({ app, session, productVariantId })
  // const shippingConfigs = getShippingConfigs(cartItems, products)

  return products.map((item) => {
    const cartItem = cartItems.find((i) => i.id === item.id)
    item.quantity = cartItem.quantity

    const balance = balances.find((b) => b.productVariantId === item.id)
    item.balance = balance ? balance.quantity : 0

    return item
  })
}

async function getProducts({ app, session, productVariantId }) {
  if (productVariantId.length === 0) return []

  const merchantId = app.state.merchant.id
  const variables = { merchantId, input: { productVariantId } }
  const query = `
    query($merchantId: ID!, $input: ProductVariantQueryInput) {
      productVariants(merchantId: $merchantId, input: $input) {
        id
        productId
        spu
        sku
        barcode
        price
        postedPrice
        options {
          name
          value
        }
        image {
          src
          alt
        }
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return []

  return data.productVariants
}

async function getBalances({ app, session, productVariantId }) {
  if (productVariantId.length === 0) return []

  const merchantId = app.state.merchant.id
  const variables = { productVariantId, merchantId }
  const query = `
    query($merchantId: ID!, $productVariantId: [ID!]!) {
      userInventoryBalances(merchantId: $merchantId, variants: $productVariantId) {
        productVariantId
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) {
    return []
  }

  return data.userInventoryBalances
}

// function getShippingConfigs(cartItems, products) {
//   return cartItems.reduce((result, item) => {
//     const { productId, shippingWaiverFee } = item
//     const shippingConfig = result[productId]
//     if (shippingConfig) return result

//     const product = products.find((i) => i.productId === productId)
//     const { extra } = product || {}
//     const { shippingProviders } = extra || {}
//     result[productId] = { shippingWaiverFee, shippingProviders }
//     return result
//   }, {})
// }

function filterCartItems(products) {
  const cvsCartItems = []
  const d2dCartItems = []
  products.forEach((item) => {
    const { shippingProviders } = item.extra || {}
    if (!shippingProviders) {
      d2dCartItems.push(item)
      return
    }

    const { FAMI, HILIFE, UNIMART, DOOR_TO_DOOR } = shippingProviders || {}
    const hasCvs =
      FAMI.status === 'ACTIVE' ||
      HILIFE.status === 'ACTIVE' ||
      UNIMART.status === 'ACTIVE'

    if (DOOR_TO_DOOR.status === 'ACTIVE' || !hasCvs) {
      d2dCartItems.push(item)
      return
    }

    cvsCartItems.push(item)
  })
  return { cvsCartItems, d2dCartItems }
}

async function getDiscountConfig({ app, session, discounts, code, cartItems }) {
  const hasCode = discounts.some(
    (item) => item.code.toLowerCase() === code.toLowerCase()
  )
  if (hasCode) return null

  const discount = await fetchDiscount({ session, app, code, cartItems })
  if (!discount) return null

  return {
    id: discount.discountConfigId,
    type: discount.discountType,
    code,
    amount: discount.discountValue,
    allowCombination: discount.allowCombination,
  }
}

async function fetchDiscount({ session, app, code, cartItems }) {
  const products = cartItems.map((item) => ({
    productVariantId: item.id,
    price: item.price,
    quantity: item.quantity,
  }))
  const variables = { input: { code, products } }
  const query = `
    query ($input: DiscountPriceInput!) {
      discountPrice(input: $input) {
        discountConfigId
        discountType
        discountValue
        allowCombination
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data.discountPrice
}

function validateDiscount({ session, discounts }) {
  if (
    discounts.length > 1 &&
    discounts.some((item) => item.allowCombination === 'NO')
  ) {
    session.dispatch({
      type: ALERT_ADD,
      item: {
        type: 'error',
        message: 'error.discount.combinationNotAllowed',
      },
    })
    return
  }
}

// function getActivePaymentMethod(channelProviders, disableCash = false) {
//   const hasCash = channelProviders.some((item) => item === 'CASH')
//   if (hasCash && !disableCash) return 'CASH'

//   const hasEcpay = channelProviders.some((item) => item === 'ECPAY')
//   if (hasEcpay) return 'ECPAY'

//   const hasLinepay = channelProviders.some((item) => item === 'LINEPAY')
//   if (hasLinepay) return 'LINEPAY'

//   return null
// }

function getCanonicalValue(str) {
  if (typeof str !== 'string') return str
  return str.replace(/[\s-().,]/g, '')
}

function validateFormExtra({ session, state }) {
  const { isCvs, paymentMethod } = state

  if (!state.city) {
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'error', message: 'error.checkout.missingCity' },
    })
    return false
  }

  if (!state.district) {
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'error', message: 'error.checkout.missingDistrict' },
    })
    return false
  }

  if (!state.address) {
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'error', message: 'error.checkout.missingStreet' },
    })
    return false
  }

  if (!paymentMethod) {
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'error', message: 'error.checkout.missingPaymentMethod' },
    })
    return false
  }

  if (isCvs) {
    const phone = getCanonicalValue(state.phone)
    if (phone.length !== 10) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.checkout.invalidPhone' },
      })
      return false
    }

    if (!state.cvsId) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.checkout.missingCvsId' },
      })
      return false
    }
  } else {
    if (!state.recipientCity) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.checkout.missingCity' },
      })
      return false
    }

    if (!state.recipientDistrict) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.checkout.missingDistrict' },
      })
      return false
    }

    if (!state.recipientAddress) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.checkout.missingStreet' },
      })
      return false
    }
  }

  return true
}

async function createOrder({ session, app, state, cartItems }) {
  const { isCvs, paymentMethod } = state
  const ticketItems = cartItems.map((item) => ({
    merchantId: item.merchantId,
    productVariantId: item.id,
    quantity: item.quantity,
    extra: { backorder: item.backorder },
  }))
  const discountName = state.isCvs ? 'cvsDiscounts' : 'd2dDiscounts'
  const discounts = state[discountName]
  const userInfo = {
    name: state.name,
    phone: state.phone,
    zipcode: state.zipcode,
    city: state.city,
    district: state.district,
    address: state.address,
  }
  const shipping = {
    name: state.recipientName,
    phone: state.recipientPhone,
    zipcode: state.recipientZipcode,
    city: state.recipientCity,
    district: state.recipientDistrict,
    address: state.recipientAddress,
  }
  const receipt = {
    type: state.receiptType.value,
    receiptNo: state.receiptNo,
    receiptTitle: state.receiptTitle,
  }
  const input = {
    ticketType: 'SELL',
    ticketItems,
    price: getInvoiceAmount(state),
    extra: {
      userInfo,
      shipping,
      receipt,
      discountCodes: discounts.map(({ code }) => code),
      paymentMethod,
    },
  }
  if (isCvs) {
    input.extra = {
      ...input.extra,
      shippingProvider: state.shippingProvider.value,
      // shippingCollect: state.shippingCollect.value,
      shippingCollect: 'N',
      cvsId: state.cvsId,
      cvsName: state.cvsName,
      cvsAddress: state.cvsAddress,
      cvsTelephone: state.cvsTelephone,
    }
  }
  const variables = { input }
  const query = `
    mutation($input: TicketInput!) {
      createTicket(input: $input)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data.createTicket
}

async function createWebpay({
  state,
  session,
  app,
  ticketId,
  paymentMethod,
  cartItems,
}) {
  const { merchant } = app.state
  const { name, companyName } = merchant.estore
  const itemName = cartItems.reduce((result, item) => {
    const { spu, price, quantity } = item
    result += `${spu}(NT.${price})x${quantity}#`
    return result
  }, '')
  const input = {
    channelProvider: paymentMethod,
    channelType: 'CREDIT_CARD',
    ticketId,
    tradeDesc: companyName || name,
    itemName,
    shipping: {
      name: state.name,
      phone: state.phone,
      address: getAddress(state),
    },
  }
  const variables = { input }
  const query = `
    mutation($input: PaymentInput!) {
      createWebpay(input: $input)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data.createWebpay
}

function getAddress(state) {
  const zipcode = state.zipcode || ''
  const city = state.city || ''
  const district = state.district || ''
  const address = state.address || ''
  return zipcode + city + district + address
}

export function ecpayRedirect({ url, form }) {
  const inputs = Object.entries(form).map(([key, val]) => {
    return `<input type="hidden" name="${key}" id="${key}" value="${val}" />`
  })
  document.write(`
    <form id="ecpay" action="${url}" method="post">
      ${inputs.join('')}
    </form>
    <script type="text/javascript">document.getElementById("ecpay").submit();</script>
  `)
}
