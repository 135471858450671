import React, { useState, useContext } from 'react'
import { Box, Flex, Text, Button } from 'rebass'
import { useHistory } from 'react-router-dom'
import AppContext from 'contexts/AppContext'
import {
  CARTDRAWER_TOGGLE,
  SIGNUP_MODAL_TOGGLE,
  LOGIN_MODAL_TOGGLE,
  SIDEBAR_TOGGLE,
} from 'constants/actionType'
import Badge from 'components/Badge'
import Message from 'components/Message'
import Link from 'components/Link'
import Image from 'components/Image'
import Container from 'components/Container'
import SearchInput from 'components/SearchInput'
import Modal from 'components/Modal'
import Popover from 'components/Popover'
import LoginDialog from 'views/LoginModal'
import SignupModal from 'views/SignupModal'
import {
  MdShoppingCart,
  MdPerson,
  MdExitToApp,
  MdPortrait,
} from 'react-icons/md'
import Logo from 'assets/svg/Logo'
import { MdMenu, MdSearch } from 'react-icons/md'

export default () => {
  const history = useHistory()
  const { state, dispatch } = useContext(AppContext)
  const [openSearch, setOpenSearch] = useState(false)
  const { estore } = state.merchant || {}
  const { name, headerLogoUrl } = estore
  const cartItemCount = state.cartItems.reduce((total, item) => {
    total += item.quantity
    return total
  }, 0)
  const url = new URL(window.location.href)
  const key = url.searchParams.get('key')

  return (
    <>
      <Container
        bg="primary.0"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
          boxShadow: '0 2px 4px rgba(0, 0, 0, .125)',
          overflowX: 'visible',
        }}
        contentProps={{ py: 0 }}
      >
        <Flex height="60px" justifyContent="space-between">
          <Flex flex={1}>
            <Button
              mr="auto"
              px={[2, 0]}
              display={['flex', 'none']}
              variant="table"
              onClick={() => dispatch({ type: SIDEBAR_TOGGLE })}
              sx={{ alignItems: 'center', color: 'grey.5' }}
            >
              <MdMenu size="24px" />
              {name ? (
                <Text ml={3}>{name}</Text>
              ) : (
                <Message ml={3} id="app.name" />
              )}
            </Button>
            <Link pl={[2, 0]} display={['none', 'flex']} href="/">
              {headerLogoUrl ? (
                <Image
                  // width="28px"
                  width="auto"
                  height="38px"
                  src={headerLogoUrl}
                  sx={{ objectFit: 'contain' }}
                />
              ) : (
                <>
                  <Logo color="#666" />
                  <Message ml={3} id="app.name" />
                </>
              )}
              {/* {name ? (
                <Text ml={3}>{name}</Text>
              ) : (
                <Message ml={3} id="app.name" />
              )} */}
            </Link>
          </Flex>
          <Box flex={3} alignItems="center" display={['none', 'flex']}>
            <SearchInput
              placeholder="app.search"
              value={key}
              onChange={(value) => {
                if (!value) return
                history.push(`/category?key=${value}`)
              }}
            />
          </Box>
          <Flex justifyContent="flex-end" flex={1}>
            <Link
              display={['flex', 'none']}
              mx={2}
              fontSize={4}
              onClick={() => setOpenSearch(true)}
            >
              <MdSearch />
            </Link>
            {state.token.accessToken ? (
              <Popover icon={<MdPerson />}>
                <Link href="/member" px={3} py={2} width={1}>
                  <MdPortrait size="24px" />
                  <Message ml={2} id="account.title" />
                </Link>
                <Link href="/logout" px={3} py={2} width={1}>
                  <MdExitToApp size="24px" />
                  <Message ml={2} id="app.logout" />
                </Link>
              </Popover>
            ) : (
              <>
                <Link
                  mx={2}
                  onClick={() => dispatch({ type: LOGIN_MODAL_TOGGLE })}
                >
                  <Message id="app.login" />
                </Link>
                <Link
                  mx={2}
                  onClick={() => dispatch({ type: SIGNUP_MODAL_TOGGLE })}
                >
                  <Message id="app.signup" />
                </Link>
              </>
            )}
            <Link
              mx={2}
              fontSize={4}
              onClick={() => dispatch({ type: CARTDRAWER_TOGGLE })}
            >
              <MdShoppingCart />
              {cartItemCount > 0 && <Badge>{cartItemCount}</Badge>}
            </Link>
          </Flex>
        </Flex>
      </Container>
      <LoginDialog
        open={state.loginModal}
        onClose={() => dispatch({ type: LOGIN_MODAL_TOGGLE })}
      />
      <SignupModal
        open={state.signupModal}
        onClose={() => dispatch({ type: SIGNUP_MODAL_TOGGLE })}
      />
      <Modal
        open={openSearch}
        onClose={() => setOpenSearch(false)}
        width={300}
        contentProps={{ bg: 'grey.0' }}
      >
        <SearchInput
          placeholder="app.search"
          onChange={(value) => {
            if (!value) return
            history.push(`/category?key=${value}`)
            setOpenSearch(false)
          }}
        />
      </Modal>
    </>
  )
}
