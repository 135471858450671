import React from 'react'
import { Flex, Button, Text } from 'rebass'

export default ({ value, min = 1, max = 1000, onChange, ...props }) => {
  if (max === 0) {
    min = 0
  }
  return (
    <Flex alignItems="center" {...props}>
      <Button
        type="button"
        variant="default"
        width={40}
        height={40}
        px={2}
        onClick={() => {
          if (value <= min) return
          onChange(value - 1)
        }}
        sx={{ borderRadius: '3px 0 0 3px' }}
      >
        -
      </Button>
      <Flex
        justifyContent="center"
        alignItems="center"
        width={60}
        height={40}
        sx={{
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'grey.3',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'grey.3'
        }}
      >
        <Text>{value}</Text>
      </Flex>
      <Button
        type="button"
        variant="default"
        width={40}
        height={40}
        px={2}
        onClick={() => {
          if (value >= max) return
          onChange(value + 1)
        }}
        sx={{ borderRadius: '0 3px 3px 0' }}
      >
        +
      </Button>
    </Flex>
  )
}
