import React from 'react'
import { injectIntl } from 'react-intl'
import { Box } from 'rebass'
import FormField from 'components/FormField'

const TextInput = injectIntl(({ intl, placeholder, hasError, ...props }) => (
  <Box
    as="input"
    placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
    width={1}
    py={2}
    px={2}
    m={0}
    fontSize={2}
    sx={{
      boxSizing: 'border-box',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: hasError ? 'error.1' : 'grey.3',
      borderRadius: '4px',
      transition: 'border-color 0.2s linear',
      willChange: 'border-color',
      '&:focus': {
        outline: 'none',
        borderColor: hasError ? 'error.1' : 'accent.1'
      }
    }}
    {...props}
  />
))

export default ({ id, label, errMsg, containerProps, ...props }) => (
  <FormField id={id} label={label} errMsg={errMsg} {...containerProps}>
    <TextInput id={id} hasError={!!errMsg} {...props} />
  </FormField>
)
