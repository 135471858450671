import React from 'react'
import { Flex, Text } from 'rebass'
import Message from 'components/Message'

export default ({
  show = true,
  direction = 'column',
  label,
  value,
  children,
  labelProps,
  valueProps,
  ...props
}) => {
  if (!show) return null
  if (value && typeof value === 'object') return null

  const isVert = direction === 'column'
  const isEmpty = value === undefined || value === null || value === ''

  return (
    <Flex
      flexDirection={direction}
      alignItems={isVert ? 'flex-start' : 'center'}
      mt={3}
      {...props}
    >
      <Message id={label} color="'dark.0" {...labelProps} />
      {children ? (
        children
      ) : (
        <Text
          mt={isVert ? 1 : 0}
          ml={isVert ? 0 : 3}
          sx={{ whiteSpace: 'pre-line' }}
          // color={isEmpty ? 'dark.0' : 'inherit'}
          {...valueProps}
        >
          {isEmpty ? '\u2014' : value}
        </Text>
      )}
    </Flex>
  )
}
