import React from 'react'
import TextInput from 'components/TextInput'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}) =>
  initializeState({
    name: value.name || '',
    phone: value.phone || '',
    zipcode: value.zipcode || '',
    city: value.city || '',
    district: value.district || '',
    address1: value.address1 || '',
    address2: value.address2 || ''
  })

const validation = {
  name: [{ type: 'required', message: 'error.required' }],
  phone: [{ type: 'required', message: 'error.required' }],
  city: [{ type: 'required', message: 'error.required' }],
  district: [{ type: 'required', message: 'error.required' }],
  address1: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    name: (
      <TextInput
        placeholder="address.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    phone: (
      <TextInput
        placeholder="address.field.phone"
        value={state.phone}
        onChange={onTextChange('phone')}
        errMsg={state.__error__.phone}
      />
    ),
    zipcode: (
      <TextInput
        placeholder="address.field.zipcode"
        value={state.zipcode}
        onChange={onTextChange('zipcode')}
        errMsg={state.__error__.zipcode}
      />
    ),
    city: (
      <TextInput
        placeholder="address.field.city"
        value={state.city}
        onChange={onTextChange('city')}
        errMsg={state.__error__.city}
      />
    ),
    district: (
      <TextInput
        placeholder="address.field.district"
        value={state.district}
        onChange={onTextChange('district')}
        errMsg={state.__error__.district}
      />
    ),
    address1: (
      <TextInput
        placeholder="address.field.address1"
        value={state.address1}
        onChange={onTextChange('address1')}
        errMsg={state.__error__.address1}
      />
    ),
    address2: (
      <TextInput
        placeholder="address.field.address2"
        value={state.address2}
        onChange={onTextChange('address2')}
        errMsg={state.__error__.address2}
      />
    )
  }
}

export const handlers = ({ state, setState, app, session, actions }) => ({
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) {
      return
    }

    const variables = {
      input: {
        name: state.name,
        phone: state.phone,
        zipcode: state.zipcode,
        city: state.city,
        district: state.district,
        address1: state.address1,
        address2: state.address2
      }
    }
    const query = `
      mutation ($input: UserAddressInput!) {
        addAddress(input: $input)
      }
    `
    const [ok, data] = await request({ query, variables }, { app, session })
    if (!ok) return

    const id = data.addAddress
    if (!id) {
      actions.onClose()
      return
    }
    
    actions.onConfirm({ ...state, id })
  }
})
