import React from 'react'
import { Box, Flex, Button } from 'rebass'
import Form from 'components/Form'
import Message from 'components/Message'
import Modal from 'components/Modal'
import * as model from 'models/signup'

export default ({ open, onClose }) => (
  <Modal
    title="signup.title"
    open={open}
    onClose={onClose}
    width={300}
    contentProps={{ bg: 'grey.0' }}
  >
    <Form {...model} reload={open} actions={{ handleClose: onClose }}>
      {({ form, action, state }) => (
        <>
          {form.username}
          {form.password}
          {form.passwordConfirm}
          {form.captchaValue}
          <Flex mb={3} justifyContent="center">
            <Box dangerouslySetInnerHTML={{ __html: state.captchaImage }} />
          </Flex>
          {form.subscription}
          {form.privacy}
          <Flex justifyContent="center" alignItems="center" mt={3}>
            <Button
              disabled={!state.privacy}
              type="submit"
              variant="accent"
              flex={1}
              justifyContent="center"
            >
              <Message id="signup.btn.signup" />
            </Button>
          </Flex>
        </>
      )}
    </Form>
  </Modal>
)
