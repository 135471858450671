import React from 'react'
import { Flex } from 'rebass'
import { FormattedMessage } from 'react-intl'
import { BREADCRUMB_SET } from 'constants/actionType'
import { initializeState } from 'utilities/formUtil'
import Switch from 'components/Switch'
import Message from 'components/Message'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}) =>
  initializeState({
    subscription: value.subscription || false,
  })

export const fields = ({ app, session, state, setState }) => {
  const { name } = app.state.merchant.estore
  return {
    subscription: (
      <Flex justifyContent="space-between" alignItems="center" p={3}>
        <Message id="account.field.subscription" values={{ name }} />
        <Switch
          checked={state.subscription}
          onClick={() => toggleSubscription({ state, setState, app, session })}
        />
      </Flex>
    ),
  }
}

export const onLoad = async ({ state, setState, app, session }) => {
  const user = await getUser({ app, session })
  const { subscription } = user.extra || {}
  setState(initialState({ ...state, subscription }))
  setBreadcrumb({ app })
}

async function getUser({ app, session }) {
  const query = `
    query {
      user {
        extra
      }
    }
  `
  const [ok, data] = await request({ query }, { app, session })
  if (!ok) return {}
  return data.user
}

async function toggleSubscription({ state, setState, app, session }) {
  const subscription = !state.subscription
  const variables = {
    input: { extra: { subscription } },
  }
  const query = `
    mutation($input: UserInput!) {
      editUser(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { app, session })
  if (!ok) return

  setState({ ...state, subscription })
}

const setBreadcrumb = ({ app }) => {
  const items = [
    { label: 'Home', url: '/' },
    { label: <FormattedMessage id="account.basic.title" /> },
  ]

  app.dispatch({ type: BREADCRUMB_SET, items })
}
