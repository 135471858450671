import React from 'react'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm
} from 'utilities/formUtil'
import TextInput from 'components/TextInput'
import TextArea from 'components/TextArea'
import Select from 'components/Select'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}, message) =>
  initializeState({
    type: value.type || '',
    subject: value.subject || '',
    message: value.message || ''
  })

const validation = {
  type: [{ type: 'required', message: 'error.required' }],
  subject: [
    { type: 'required', message: 'error.required' },
    {
      type: 'maxLength',
      val: 45,
      message: ['error.maxLength', { val: 45 }]
    }
  ],
  message: [
    { type: 'required', message: 'error.required' },
    {
      type: 'maxLength',
      val: 1500,
      message: ['error.maxLength', { val: 1500 }]
    }
  ]
}

export const fields = ({ app, session, state, setState, message }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    type: (
      <Select
        isClearable={false}
        isSearchable={false}
        fieldProps={{ width: 1 }}
        placeholder="support.field.type"
        options={[
          {
            value: 'BILLING',
            label: message({ id: 'support.type.BILLING' })
          },
          {
            value: 'PRODUCT',
            label: message({ id: 'support.type.PRODUCT' })
          },
          {
            value: 'ORDER',
            label: message({ id: 'support.type.ORDER' })
          },
          {
            value: 'OTHER',
            label: message({ id: 'support.type.OTHER' })
          }
        ]}
        value={state.type}
        onChange={handleSelectChange('type', state, setState, validation)}
      />
    ),
    subject: (
      <TextInput
        placeholder="support.field.subject"
        value={state.subject}
        onChange={onTextChange('subject')}
        errMsg={state.__error__.subject}
      />
    ),
    message: (
      <TextArea
        rows="6"
        placeholder="support.field.message"
        value={state.message}
        onChange={onTextChange('message')}
        errMsg={state.__error__.message}
      />
    )
  }
}

export const handlers = ({ state, setState, session, app, actions }) => ({
  handleLoad: () => {},
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) {
      return
    }

    const [ok] = await addSupport({ session, app, state })
    if (!ok) return

    actions.refreshList()
    actions.handleClose()
  }
})

async function addSupport({ session, app, state }) {
  const variables = {
    input: {
      merchantId: app.state.merchant.id,
      userId: app.state.user.id,
      type: state.type.value,
      subject: state.subject,
      message: state.message
    }
  }
  const query = `
    mutation($input: SupportMessageInput!) {
      addSupportMessage(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}
