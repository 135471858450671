import React, { useEffect, useContext, useState } from 'react'
import {
  setBreadcrumb,
  initialState,
  fields,
  handlers
} from 'models/supportPage'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import { Box, Flex, Text, Button } from 'rebass'
import Page from 'components/Page'
import Message from 'components/Message'

export default ({ match }) => {
  const { id } = match.params
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState())
  const field = fields({ app, session, state, setState })
  const handler = handlers({ id, state, setState, session, app })

  useEffect(() => {
    handler.handleLoad({ id, setState, app, session })
    setBreadcrumb({ app })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderForm = () => (
    <form onSubmit={handler.handleSubmit}>
      {field.message}
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button type="submit" variant="accent">
          <Message id="btn.submit" />
        </Button>
      </Flex>
    </form>
  )

  const renderMessage = item => (
    <Box
      key={item.id}
      mt={4}
      mb={3}
      sx={{
        bg: item.staffName ? 'primary.0' : 'grey.0',
        borderRadius: '5px'
      }}
    >
      <Flex p={3} justifyContent="space-between">
        <Text>{item.userName || item.staffName}</Text>
        <Text>{item.createdAt}</Text>
      </Flex>
      <Text pb={3} px={3}>
        {item.message}
      </Text>
    </Box>
  )

  return (
    <Page title="support.title">
      {renderForm()}
      {state.messages.map(item => renderMessage(item))}
    </Page>
  )
}
