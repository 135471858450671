import React, { useReducer } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'emotion-theming'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { IntlProvider } from 'react-intl'
import zjTW from 'messages/zh-TW'
import { RootRoute } from 'configs/routes'
import theme from 'configs/theme'
import LoadingBar from 'components/LoadingBar'
import Notification from 'components/Notification'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import * as root from 'reducers/session'
import * as app from 'reducers/app'
import * as serviceWorker from './serviceWorker'

const history = createBrowserHistory()

const Root = () => {
  const [rootState, rootDispatch] = useReducer(root.reducer, root.initialState)
  const [appState, appDispatch] = useReducer(app.reducer, app.initialState)

  return (
    <IntlProvider locale="zh" messages={zjTW}>
      <ThemeProvider theme={theme}>
        <SessionContext.Provider
          value={{ state: rootState, dispatch: rootDispatch }}
        >
          <AppContext.Provider
            value={{ state: appState, dispatch: appDispatch }}
          >
            <Router history={history}>
              <RootRoute />
            </Router>
            <LoadingBar loading={rootState.loading} />
            <Notification values={rootState.alerts} />
          </AppContext.Provider>
        </SessionContext.Provider>
      </ThemeProvider>
    </IntlProvider>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))
serviceWorker.unregister()
