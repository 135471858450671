import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Button } from 'rebass'
import Modal from 'components/Modal'
import Form from 'components/Form'
import * as model from 'models/orderRefundModal'

export default ({ value, open, actions }) => (
  <Modal
    title="refund.title.add"
    open={open}
    onClose={actions.handleRefundClose}
    width={[1, 500]}
    bg="grey.0"
  >
    <Form {...model} value={value} actions={actions}>
      {({ form }) => (
        <>
          {form.product}
          <Flex justifyContent="flex-end" alignItems="center" mt={3}>
            <Button
              mr={2}
              type="button"
              variant="default"
              onClick={actions.handleRefundClose}
            >
              <FormattedMessage id="btn.close" />
            </Button>
            <Button variant="accent">
              <FormattedMessage id="btn.submit" />
            </Button>
          </Flex>
        </>
      )}
    </Form>
  </Modal>
)
