import React from 'react'
import { Box, Flex, Image } from 'rebass'
import { Preload } from 'react-preload'
import { MdPhoto } from 'react-icons/md'
import LoadingIcon from 'components/LoadingIcon'
import RatioBox from 'components/RatioBox'
import Link from 'components/Link'

const rootUrl = process.env.REACT_APP_STATIC_URL

export default ({
  src,
  alt,
  url,
  width = 1,
  height = 'auto',
  ratio,
  sx,
  ...props
}) => {
  if (!src) return renderPlaceholder(width, height, ratio)
  return (
    <Box width={width} height={height}>
      <Preload
        loadingIndicator={renderLoader()}
        images={[rootUrl + src]}
        autoResolveDelay={3000}
        resolveOnError={true}
        mountChildren={true}
      >
        <RatioBox ratio={ratio}>
          {url ? (
            <Link width={1} href={url} height={height} {...props}>
              <Image
                src={rootUrl + src}
                alt={alt}
                width={width}
                height="100%"
                sx={{
                  objectFit: 'cover',
                  ...sx
                }}
              />
            </Link>
          ) : (
            <Image
              src={rootUrl + src}
              alt={alt}
              width={width}
              height="100%"
              sx={{
                objectFit: 'cover',
                ...sx
              }}
              {...props}
            />
          )}
        </RatioBox>
      </Preload>
    </Box>
  )
}

const renderPlaceholder = (width, height, ratio) => (
  <Box width={width} height={height}>
    <RatioBox ratio={ratio}>
      <Flex
        justifyContent="center"
        alignItems="center"
        width={1}
        height="100%"
        bg="grey.0"
        color="grey.3"
        fontSize={6}
      >
        <MdPhoto />
      </Flex>
    </RatioBox>
  </Box>
)

const renderLoader = () => (
  <Flex
    width={1}
    height="100%"
    bg="transparent"
    justifyContent="center"
    alignItems="center"
  >
    <LoadingIcon />
  </Flex>
)
