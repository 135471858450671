import React from 'react'
import { TOKEN_SET, ALERT_ADD } from 'constants/actionType'
import TextInput from 'components/TextInput'
import {
  initializeState,
  handleTextChange,
  validateForm,
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}) =>
  initializeState({
    username: value.username || '',
    password: value.password || '',
  })

const validation = {
  username: [{ type: 'required', message: 'error.required' }],
  password: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    username: (
      <TextInput
        placeholder="login.field.username"
        value={state.username}
        autoComplete="email"
        onChange={onTextChange('username')}
        errMsg={state.__error__.username}
      />
    ),
    password: (
      <TextInput
        type="password"
        placeholder="login.field.password"
        value={state.password}
        autoComplete="current-password"
        onChange={onTextChange('password')}
        errMsg={state.__error__.password}
      />
    ),
  }
}

export const handlers = ({
  state,
  setState,
  app,
  session,
  history,
  actions,
}) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) {
      return
    }

    // const { hostname } = window.location
    const hostname = process.env.REACT_APP_HOSTNAME
    const { username, password } = state
    const variables = { hostname, username, password }
    const query = `
      mutation($hostname: String!, $username: String!, $password: String!) {
        createUserToken(hostname: $hostname, username: $username, password: $password) {
          accessToken
          refreshToken
        }
      }
    `
    const [ok, data] = await request({ query, variables }, { session })
    if (!ok) {
      return
    }

    const token = data.createUserToken
    app.dispatch({ type: TOKEN_SET, token })
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'login.success' },
    })

    if (actions && actions.handleClose) {
      actions.handleClose()
    }

    const url = new URL(window.location.href)
    const redirect = url.searchParams.get('redirect')
    if (redirect) {
      history.push(redirect)
    } else {
      history.push('/member/order')
    }
  },
})
