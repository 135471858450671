import { request } from 'utilities/requestUtil'
import { STATUS_INACTIVE, STATUS_ACTIVE } from 'constants/status'

export const activateUser = async ({ setState, session, id, code }) => {
  const variables = { id, code }
  const query = `
    mutation($id: ID!, $code: String!) {
      activateUser(id: $id, code: $code)
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  setState(ok && data.activateUser ? STATUS_ACTIVE : STATUS_INACTIVE)
}
