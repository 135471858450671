import React, { useEffect, useContext } from 'react'
import { MERCHANT_SET, CATEGORY_SET } from 'constants/actionType'
import AppContext from 'contexts/AppContext'
import { request } from 'utilities/requestUtil'
import { MainRoute } from 'configs/routes'
import { Box } from 'rebass'
import TagManager from 'react-gtm-module'
import Header from 'components/Navbar'
import Footer from 'components/Footer'
import Sidebar from 'components/Sidebar'
import CartDrawer from 'components/CartDrawer'
import ScrollToTop from 'components/ScrollToTop'
import LoadingIcon from 'components/LoadingIcon'
// import { subscribe, unsubscribeAll } from 'utilities/graphql'
// import gql from 'graphql-tag'

export default () => {
  const { state, dispatch } = useContext(AppContext)
  const merchant = state.merchant || {}
  const url = new URL(window.location.href)
  // const { hostname } = url
  const hostname = process.env.REACT_APP_HOSTNAME

  useEffect(() => {
    if (!merchant.id || hostname !== merchant.hostname) {
      setMerchant({ state, dispatch, hostname })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostname, merchant.id, merchant.hostname])

  const { gtmId } = merchant.estore || {}
  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtmId])

  let categoryId = url.searchParams.get('id')
  if (url.pathname === '/category/ecostore') categoryId = '6630730998956949504'

  useEffect(() => {
    if (merchant.id) {
      setCategories({ dispatch, merchantId: merchant.id, categoryId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant.id, categoryId])

  // useEffect(() => {
  //   subscribe({
  //     query: gql`
  //       subscription somethingChanged {
  //         somethingChanged
  //       }
  //     `,
  //     next(data) {
  //       console.log('subscription triggered!', data)
  //     },
  //   })
  //   return unsubscribeAll
  // }, [])

  if (!merchant.id) {
    return <LoadingIcon>LOADING...</LoadingIcon>
  }

  return (
    <ScrollToTop>
      <Header />
      <Box mt="60px">
        <MainRoute />
      </Box>
      <Footer />
      <Sidebar />
      <CartDrawer />
    </ScrollToTop>
  )
}

async function setMerchant({ dispatch, hostname }) {
  const variables = { hostname }
  const query = `
    query($hostname: String!) {
      userMerchant(hostname: $hostname) {
        id
        hostname
        channelProviders
        channelConfigs
        estore
      }
    }
  `
  const [ok, data] = await request({ query, variables })
  if (!ok) return
  dispatch({ type: MERCHANT_SET, item: data.userMerchant })
}

async function setCategories({ dispatch, merchantId, categoryId }) {
  const variables = { merchantId }
  const query = `
    query($merchantId: ID!) {
      categories(merchantId: $merchantId) {
        id
        parentCategoryId
        name
        images
        isPublic
      }
    }
  `
  const [ok, data] = await request({ query, variables })
  if (!ok) return

  dispatch({ type: CATEGORY_SET, items: data.categories, id: categoryId })
}
