import React, {
  forwardRef,
  useState,
  useContext,
  useEffect,
  useImperativeHandle
} from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'

const Form = (
  {
    match,
    initialState,
    fields,
    value,
    reload = true,
    handlers,
    actions,
    refs,
    children
  },
  ref
) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const { formatMessage: message } = useIntl()
  const history = useHistory()
  const [state, setState] = useState(initialState(value, message))
  const form = fields({
    history,
    message,
    match,
    state,
    setState,
    session,
    app,
    actions,
    refs
  })
  const action = handlers({
    history,
    match,
    state,
    setState,
    session,
    app,
    actions,
    message,
    refs
  })

  useEffect(() => {
    if (action.handleLoad && reload) {
      action.handleLoad()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  useEffect(() => {
    setState(initialState(value, message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useImperativeHandle(ref, () => ({ state, setState }))

  return (
    <form onSubmit={action.handleSubmit}>
      {children({ form, action, state })}
    </form>
  )
}

export default forwardRef(Form)
