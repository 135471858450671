import React, { useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import AppContext from 'contexts/AppContext'
import { removeCheckoutItems } from 'models/payment'
import { Flex } from 'rebass'
import Container from 'components/Container'
import Page from 'components/Page'

const ecpayUrl = process.env.REACT_APP_API_ECPAY
const paymentType = 'CREDIT'

function Payment({ match, history }) {
  const app = useContext(AppContext)

  const getMessage = ({ data }) => {
    const message = typeof data === 'string' ? JSON.parse(data) : data
    if (message.RtnCode === '1') {
      removeCheckoutItems({ app })
      history.push('/member/order')
    }
  }

  useEffect(() => {
    window.addEventListener('message', getMessage)

    return function cleanup() {
      window.removeEventListener('message', getMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { merchantId, token } = match.params
  const src = `${ecpayUrl}/SP/SPCheckOut?MerchantID=${merchantId}&SPToken=${token}&PaymentType=${paymentType}&ts=${Date.now()}`

  return (
    <Page title="payment.title">
      <Container minHeight="calc(100vh - 212px)" bg="grey.1">
        <Flex justifyContent="center">
          <iframe
            id={'iframeECPay_' + paymentType}
            title="ecpay"
            src={src}
            frameBorder={0}
            width="100%"
            height="750px"
          />
        </Flex>
      </Container>
    </Page>
  )
}

export default withRouter(Payment)
