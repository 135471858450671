import * as type from 'constants/actionType'
import { listToTree } from 'utilities/listUtil'

export const initialState = {
  signupModal: false,
  loginModal: false,
  sidebar: false,
  cartDrawer: false,
  cartItems: getLocalJson('cart') || [],
  token: getLocalJson('token') || { accessToken: '', refreshToken: '' },
  user: {},
  userInfo: getLocalJson('userInfo') || {},
  merchant: {
    id: '',
    hostname: '',
    channelProviders: [],
    channelConfigs: [],
    estore: { gtmId: null, backorder: false },
  },
  orders: [],
  breadcrumbs: [],
  categories: [],
  categoryTree: null,
  categoryId: null,
  checkoutData: getLocalJson('checkout') || null,
}

export function reducer(state, action) {
  let token, cartItems

  switch (action.type) {
    case type.SIGNUP_MODAL_TOGGLE:
      return { ...state, signupModal: !state.signupModal }
    case type.LOGIN_MODAL_TOGGLE:
      return { ...state, loginModal: !state.loginModal }
    case type.BREADCRUMB_SET:
      return { ...state, breadcrumbs: action.items }
    case type.CATEGORY_SET:
      return {
        ...state,
        categoryId: action.id,
        categories: action.items,
        categoryTree: createCategoryTree(action.items),
      }
    case type.CATEGORY_ID_SET:
      return { ...state, categoryId: action.id }
    case type.SIDEBAR_TOGGLE:
      return { ...state, sidebar: !state.sidebar }
    case type.CARTDRAWER_TOGGLE:
      return { ...state, cartDrawer: !state.cartDrawer }
    case type.CART_ADD:
      cartItems = addItem(state.cartItems, action.item)
      setLocalJson('cart', cartItems)
      return { ...state, cartItems }
    case type.CART_SET:
      setLocalJson('cart', action.items)
      return { ...state, cartItems: action.items }
    case type.CART_EDIT:
      cartItems = replaceItem(state.cartItems, action.item)
      setLocalJson('cart', cartItems)
      return { ...state, cartItems }
    case type.CART_REMOVE:
      cartItems = removeItem(state.cartItems, action.id)
      setLocalJson('cart', cartItems)
      return { ...state, cartItems }
    case type.CART_CLEAR:
      setLocalJson('cart', [])
      return { ...state, cartItems: [] }
    case type.TOKEN_SET:
      setLocalJson('token', action.token)
      return { ...state, token: action.token }
    case type.TOKEN_REMOVE:
      token = { accessToken: '', refreshToken: '' }
      setLocalJson('token', token)
      return { ...state, token }
    case type.MERCHANT_SET:
      return { ...state, merchant: action.item }
    case type.USER_INFO_SET:
      setLocalJson('userInfo', action.item)
      return { ...state, userInfo: action.item }
    case type.CHECKOUT_SET:
      setLocalJson('checkout', action.item)
      return { ...state, checkoutData: action.item }
    default:
      return state
  }
}

export function getLocalJson(key) {
  const val = window.localStorage.getItem(key)
  if (!val) return null

  try {
    return JSON.parse(val)
  } catch (e) {
    return null
  }
}

function setLocalJson(key, val) {
  window.localStorage.setItem(key, JSON.stringify(val))
}

function addItem(list, data) {
  const result = [...list]
  const idx = list.findIndex((item) => item.id === data.id)
  if (idx === -1) {
    result.push(data)
    return result
  }

  result.splice(idx, 1, data)
  return result
}

function replaceItem(list, data) {
  const idx = list.findIndex((item) => item.id === data.id)
  if (idx === -1) {
    return list
  }

  const result = [...list]
  result.splice(idx, 1, data)
  return result
}

function removeItem(list, id) {
  const idx = list.findIndex((item) => item.id === id)
  if (idx === -1) {
    return list
  }

  const result = [...list]
  result.splice(idx, 1)
  return result
}

const createCategoryTree = (categories) => {
  if (!categories || categories.length === 0) return []

  // return listToTree(
  //   categories.filter((item) => item.isPublic),
  //   { parentIdKey: 'parentCategoryId' }
  // )
  return listToTree(categories, { parentIdKey: 'parentCategoryId' })
}
