import React, { useContext } from 'react'
import { Box, Flex, Text } from 'rebass'
import { FormattedMessage } from 'react-intl'
import { CARTDRAWER_TOGGLE, CART_REMOVE } from 'constants/actionType'
import AppContext from 'contexts/AppContext'
import Message from 'components/Message'
import Image from 'components/Image'
import Link from 'components/Link'
import LinkButton from 'components/LinkButton'
import Divider from 'components/Divider'
import Overlay from 'components/Overlay'
import { MdDelete, MdShoppingCart } from 'react-icons/md'
import { FaInbox } from 'react-icons/fa'

const drawerWidth = '293px'

export default (props) => {
  const { state, dispatch } = useContext(AppContext)

  const handleClick = (url) => ({ history }) => {
    history.push(url)
    dispatch({ type: CARTDRAWER_TOGGLE })
  }

  const renderProducts = () => {
    if (state.cartItems.length === 0) {
      return (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={1}
          my={4}
          color="grey.4"
        >
          <FaInbox fontSize={48} />
          <Text>No Data</Text>
        </Flex>
      )
    }

    return state.cartItems.map((item) => (
      <Flex key={item.id} alignItems="center" p={3}>
        <Link onClick={handleClick(`/product/${item.productId}`)}>
          <Image
            src={item.image.src}
            width="48px"
            height="48px"
            sx={{ objectFit: 'contain' }}
          />
        </Link>
        <Flex flex={1} mx={3} flexDirection="column">
          <Link onClick={handleClick(`/product/${item.productId}`)}>
            {item.spu}
          </Link>
          <Text fontSize={1} color="light.4">
            NT.{item.price} {item.quantity === 1 ? '' : `x${item.quantity}`}
          </Text>
        </Flex>
        <Link onClick={() => dispatch({ type: CART_REMOVE, id: item.id })}>
          <MdDelete size="24px" />
        </Link>
      </Flex>
    ))
  }

  const renderTotal = () => {
    if (state.cartItems.length === 0) return null

    const total = state.cartItems.reduce((result, item) => {
      result += item.price * item.quantity
      return result
    }, 0)
    return (
      <Flex justifyContent="space-between" alignItems="center" p={3}>
        <Message id="cart.invoice.price" />
        <Text>NT.{total}</Text>
      </Flex>
    )
  }

  return (
    <>
      <Overlay
        sx={{
          zIndex: 2000,
          transform: state.cartDrawer ? 'scale(1)' : 'scale(0)',
          opacity: state.cartDrawer ? 0.6 : 0,
          transition: 'opacity 0.3s ease-out',
        }}
        onClick={() => dispatch({ type: CARTDRAWER_TOGGLE })}
      />
      <Box
        width={state.cartDrawer ? drawerWidth : 0}
        height="100%"
        bg="grey.0"
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          zIndex: 2001,
          overflowX: 'hidden',
          transition: 'width 0.3s',
        }}
        {...props}
      >
        <Box width={drawerWidth}>
          <Flex alignItems="center" p={3} color="grey.5">
            <MdShoppingCart size="24px" />
            <Message id="cart.title" fontSize={3} ml={3} />
          </Flex>
          <Divider bg="grey.2" />
          {renderProducts()}
          <Divider bg="grey.2" />
          {renderTotal()}
          <Divider bg="grey.2" />
          <Flex justifyContent="center" mt={3}>
            <LinkButton variant="accent" onClick={handleClick('/checkout')}>
              <FormattedMessage id="cart.btn.viewDetail" />
            </LinkButton>
          </Flex>
        </Box>
      </Box>
    </>
  )
}
