import React from 'react'
import { Flex, Button } from 'rebass'
import Link from 'components/Link'
import Form from 'components/Form'
import Message from 'components/Message'
import Modal from 'components/Modal'
import * as model from 'models/login'

export default ({ open, onClose }) => (
  <Modal
    title="login.title"
    open={open}
    onClose={onClose}
    width={300}
    contentProps={{ bg: 'grey.0' }}
  >
    <Form {...model} actions={{ handleClose: onClose }}>
      {({ form, action, state }) => (
        <>
          {form.username}
          {form.password}
          <Flex justifyContent="flex-end" alignItems="center">
            <Link
              onClick={({ history }) => {
                onClose()
                history.push('/forget')
              }}
            >
              <Message id="login.btn.reset" />
            </Link>
          </Flex>
          <Flex justifyContent="center" alignItems="center" mt={3}>
            <Button
              type="submit"
              variant="accent"
              flex={1}
              justifyContent="center"
            >
              <Message id="login.btn.login" />
            </Button>
          </Flex>
        </>
      )}
    </Form>
  </Modal>
)
