const rose = ['#e98074', '#e85a4f', '#e85a4f']
const beige = ['#eae7dc', '#d8c3a5', '#8e8d8a']
const blue = ['#45c2d1', '#00acc1', '#007e8d']
const green = ['#7cc47f', '#4caf50', '#38803b']
const yellow = ['#ffb445', '#ff9800', '#ba6f00']
const red = ['#f7766c', '#f44336', '#b23128']
const grey = [
  '#fff',
  '#f9f9f9',
  '#efefef',
  '#d0d0d0',
  '#999',
  '#666',
  '#333',
  '#000',
]

export default {
  breakpoints: ['576px', '768px', '992px', '1200px'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    primary: beige,
    secondary: blue,
    accent: rose,
    success: green,
    warning: yellow,
    error: red,
    grey,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 2px 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {
    default: {
      color: 'grey.5',
      borderRadius: '3px',
      transition: 'color 0.2s ease-out',
    },
    success: {
      color: 'grey.0',
      bg: 'success.1',
      borderRadius: '3px',
      boxShadow: `0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)`,
    },
    error: {
      color: 'grey.0',
      bg: 'error.1',
      borderRadius: '3px',
      boxShadow: `0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)`,
    },
    header: {
      p: 2,
      bg: 'transparent',
      justifyContent: 'center',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'grey.4',
      fontSize: 3,
    },
    card: {
      bg: 'grey.0',
      justifyContent: 'center',
      borderRadius: '3px',
      boxShadow: 'small',
    },
    thumbnail: {
      objectFit: 'contain',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'grey.2',
    },
    link: {
      color: 'grey.5',
      borderRadius: '3px',
      cursor: 'pointer',
      userSelect: 'none',
      transition: 'color 0.2s ease-out',
      '&:hover': {
        color: 'accent.1',
      },
    },
    disabledLink: {
      cursor: 'not-allowed',
      userSelect: 'none',
      color: 'grey.4',
    },
    darkLink: {
      color: 'grey.5',
      cursor: 'pointer',
      // transition: 'background-color 0.2s ease',
      '&:hover': {
        bg: 'accent.2',
        color: 'grey.0',
      },
    },
    primary: {
      color: 'accent.1',
      transition: 'color 0.2s ease-out',
      cursor: 'pointer',
      '&:hover': {
        color: 'accent.2',
      },
    },
    secondary: {
      color: 'secondary.1',
      cursor: 'pointer',
      '&:hover': {
        color: 'secondary.1',
      },
    },
  },
  text: {
    heading: {
      fontSize: 3,
      fontWeight: 300,
      lineHeight: '30px',
    },
    error: {
      color: 'error.1',
    },
  },
  buttons: {
    default: {
      color: 'grey.5',
      bg: 'grey.0',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'grey.3',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: `accent.1`,
      },
    },
    plain: {
      color: 'secondary.1',
      bg: 'grey.0',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: `secondary.1`,
      },
      '&:disabled': {
        color: 'grey.4',
        borderColor: 'grey.3',
        boxShadow: 'none',
      },
    },
    accent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'grey.0',
      bg: 'accent.1',
      boxShadow: `0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)`,
      transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1)',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: `0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)`,
      },
      '&:disabled': {
        bg: 'grey.3',
        boxShadow: 'none',
        cursor: 'not-allowed',
      },
    },
    glass: {
      p: 0,
      color: 'grey.0',
      borderRadius: '3px',
      bg: 'rgba(0, 0, 0, 0.3)',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      transition: 'color 300ms linear, background-color 300ms linear',
      '&:hover': {
        bg: 'rgba(0, 0, 0, 0.5)',
      },
    },
    table: {
      p: 0,
      color: 'grey.4',
      bg: 'transparent',
      border: 'none',
      outline: 'none',
      lineHeight: 0,
      cursor: 'pointer',
      '&:hover': {
        color: `accent.1`,
      },
    },
    card: {
      py: 0,
      px: 1,
      color: 'grey.2',
      bg: 'transparent',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: `grey.0`,
      },
    },
    chip: {
      p: 0,
      color: 'grey.5',
      bg: 'transparent',
      border: 'none',
      outline: 'none',
      lineHeight: 0,
      cursor: 'pointer',
      '&:hover': {
        color: `accent.1`,
      },
    },
    tab: {
      mx: 1,
      color: 'grey.5',
      bg: 'primary.0',
      border: 'none',
      borderTopRightRadius: '5px',
      borderTopLeftRadius: '5px',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      outline: 'none',
      cursor: 'pointer',
      transition: 'color 300ms linear',
      '&:hover': {
        color: `accent.1`,
      },
    },
    tabActive: {
      mx: 1,
      color: 'grey.0',
      bg: 'primary.2',
      border: 'none',
      borderTopRightRadius: '5px',
      borderTopLeftRadius: '5px',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      outline: 'none',
      boxShadow: `0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)`,
      cursor: 'pointer',
    },
    tabDivider: {
      overflow: 'hidden',
      borderBottomWidth: '5px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'primary.2',
    },
    tabLink: {
      mx: 1,
      color: 'grey.5',
      bg: 'primary.0',
      border: 'none',
      borderTopRightRadius: '5px',
      borderTopLeftRadius: '5px',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      outline: 'none',
      cursor: 'pointer',
      transition: 'color 300ms linear',
      '&:hover': {
        color: `accent.1`,
      },
    },
    tabLinkActive: {
      mx: 1,
      color: 'grey.0',
      bg: 'accent.2',
      border: 'none',
      borderTopRightRadius: '5px',
      borderTopLeftRadius: '5px',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      outline: 'none',
      cursor: 'pointer',
    },
  },
}
