import React, { useContext } from 'react'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import { Redirect } from 'react-router-dom'
import { TOKEN_SET, ALERT_ADD } from 'constants/actionType'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)

  app.dispatch({
    type: TOKEN_SET,
    token: { accessToken: '', refreshToken: '' }
  })
  session.dispatch({
    type: ALERT_ADD,
    item: { type: 'success', message: 'logout.success' }
  })

  return <Redirect to="/home" />
}
