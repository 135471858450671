import React, { useContext } from 'react'
import { Box, Flex } from 'rebass'
import AppContext from 'contexts/AppContext'
import { SIDEBAR_TOGGLE } from 'constants/actionType'
import Message from 'components/Message'
import Link from 'components/Link'
import Divider from 'components/Divider'
import Overlay from 'components/Overlay'
import { FaSitemap } from 'react-icons/fa'

const sidebarWidth = '293px'

export default (props) => {
  const { state, dispatch } = useContext(AppContext)
  const { categoryTree, categoryId } = state

  const handleClick =
    (url) =>
    ({ history }) => {
      history.push(url)
      dispatch({ type: SIDEBAR_TOGGLE })
    }

  const renderCategoryTree = (items) => {
    if (!items || items.length === 0) return null
    return (
      <Box mb={3}>
        {items
          .filter((c) => c.isPublic)
          .map((item) => {
            const isRoot = item.parentCategoryId === '0'
            return (
              <Box key={item.id}>
                <Link
                  px={3}
                  pb={2}
                  color={
                    item.id === categoryId
                      ? 'accent.1'
                      : isRoot
                      ? 'grey.4'
                      : 'grey.5'
                  }
                  onClick={handleClick(`/category?id=${item.id}`)}
                >
                  {item.name}
                </Link>
                {renderCategoryTree(item.children)}
              </Box>
            )
          })}
      </Box>
    )
  }

  return (
    <>
      <Overlay
        sx={{
          zIndex: 2000,
          transform: state.sidebar ? 'scale(1)' : 'scale(0)',
          opacity: state.sidebar ? 0.6 : 0,
          transition: 'opacity 0.3s ease-out',
        }}
        onClick={() => dispatch({ type: SIDEBAR_TOGGLE })}
      />
      <Box
        width={state.sidebar ? sidebarWidth : 0}
        height="100%"
        bg="grey.0"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 2001,
          overflowX: 'hidden',
          transition: 'width 0.3s',
        }}
        {...props}
      >
        <Box width={sidebarWidth}>
          <Flex alignItems="center" p={3} color="grey.5">
            <FaSitemap size="24px" />
            <Message id="sidebar.title" fontSize={3} ml={3} />
          </Flex>
          <Divider bg="grey.2" />
          {renderCategoryTree(categoryTree)}
        </Box>
      </Box>
    </>
  )
}
