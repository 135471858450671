import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Text } from 'rebass'
import { FaInbox } from 'react-icons/fa'

const Table = ({ children, ...props }) => (
  <Box
    as="table"
    width={1}
    sx={{ overflowX: 'auto', borderCollapse: 'collapse' }}
    {...props}
  >
    {children}
  </Box>
)

const Row = ({ index, children, ...props }) => (
  <Box as="tr" bg={index % 2 === 0 ? 'grey.1' : 'grey.0'} {...props}>
    {children}
  </Box>
)

const EmptyRow = ({ columnCount }) => (
  <Box as="tr" bg="grey.1">
    <Cell colSpan={columnCount} color="grey.3" sx={{ textAlign: 'center' }}>
      <FaInbox fontSize={48} />
      <Text>No Data</Text>
    </Cell>
  </Box>
)

const HeaderCell = ({ label, align, children }) => (
  <Box
    as="th"
    py={3}
    px={2}
    sx={{
      borderBottom: 'none',
      textAlign: align || 'left',
      fontWeight: 300,
      whiteSpace: 'nowrap'
    }}
  >
    {children ? children : label && <FormattedMessage id={label} />}
  </Box>
)

const Cell = ({ align, width, children, ...props }) => (
  <Box
    as="td"
    py={3}
    px={2}
    width={width || 'auto'}
    color="grey.5"
    sx={{
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'grey.3',
      textAlign: align || 'left'
    }}
    {...props}
  >
    {children}
  </Box>
)

export default ({ columns, rows, footer, rowProps, cellProps, ...props }) => {
  const renderCell = (column, row, index) => {
    if (column.render) {
      return column.render({ row, index })
    }
    return <Text>{row[column.id]}</Text>
  }

  return (
    <Table {...props}>
      <thead>
        <Row>
          {columns.map(column => (
            <HeaderCell
              key={column.id}
              label={column.label}
              align={column.align}
            />
          ))}
        </Row>
      </thead>
      <tbody>
        {rows && rows.length > 0 ? (
          rows.map((row, index) => (
            <Row key={index} index={index} {...rowProps}>
              {columns.map(column => (
                <Cell
                  key={column.id}
                  align={column.align}
                  width={column.width}
                  {...cellProps}
                >
                  {renderCell(column, row, index)}
                </Cell>
              ))}
            </Row>
          ))
        ) : (
          <EmptyRow columnCount={columns.length} />
        )}
      </tbody>
      {footer && <tfoot>{footer}</tfoot>}
    </Table>
  )
}
