import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import MainLayout from 'layouts/MainLayout'
import MemberLayout from 'layouts/MemberLayout'
import Home from 'views/Home'
import Login from 'views/Login'
import LoginReset from 'views/LoginReset'
import LoginForget from 'views/LoginForget'
import Logout from 'views/Logout'
import Activation from 'views/Activate'
import Category from 'views/Category'
import Product from 'views/Product'
import CheckoutProduct from 'views/CheckoutProduct'
import ECPay from 'views/ECPay'
import LinePay from 'views/LinePay'
import AccountPassword from 'views/AccountPassword'
import Account from 'views/Account'
import OrderList from 'views/OrderList'
import OrderPage from 'views/OrderPage'
import SupportList from 'views/SupportList'
import SupportPage from 'views/SupportPage'
import Privacy from 'views/Privacy'

export const RootRoute = () => (
  <Switch>
    <Route exact path="/logout" component={Logout} />
    <Route path="/" component={MainLayout} />
  </Switch>
)

export const MainRoute = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route exact path="/reset" component={LoginReset} />
    <Route path="/forget" component={LoginForget} />
    <Route path="/activate" component={Activation} />
    <Route path="/home" component={Home} />
    <Route path="/category" component={Category} />
    <Route path="/product/:id" component={Product} />
    <Route path="/checkout" component={CheckoutProduct} />
    <Route path="/payment/linepay" component={LinePay} />
    <Route path="/payment/:merchantId/token/:token" component={ECPay} />
    <Route path="/privacy" component={Privacy} />
    <Route path="/member" component={MemberLayout} />
    <Redirect from="/" to="/home" />
  </Switch>
)

export const MemberRoute = () => (
  <Switch>
    <Route path="/member/basic" component={Account} />
    <Route path="/member/password" component={AccountPassword} />
    <Route exact path="/member/order" component={OrderList} />
    <Route path="/member/order/:id" component={OrderPage} />
    <Route exact path="/member/support" component={SupportList} />
    <Route path="/member/support/:id" component={SupportPage} />
    <Redirect from="/member" to="/member/order" />
  </Switch>
)
