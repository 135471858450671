import React from 'react'
import { Flex, Text } from 'rebass'
import { FormattedMessage } from 'react-intl'
import Image from 'components/Image'
import Select from 'components/Select'
import TextInput from 'components/TextInput'
import TextArea from 'components/TextArea'
import Link from 'components/Link'
import Table from 'components/Table'
import Counter from 'components/Counter'
import { MdDelete } from 'react-icons/md'
import {
  BREADCRUMB_SET,
  CART_REMOVE,
  CART_EDIT,
  CART_CLEAR,
  ALERT_ADD,
  CHECKOUT_SET,
} from 'constants/actionType'
import { request } from 'utilities/requestUtil'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  handleKeyPress,
  validateForm,
} from 'utilities/formUtil'

export const initialState = (value = {}, message) => ({
  shippingType: getShippingType(value.shippingType, message),
  shippingProvider: getShippingProvider(value.shippingProvider, message),
  shippingCollect: getShippingCollect(value.shippingCollect, message),
  cvsId: value.cvsId,
  cvsName: value.cvsName,
  cvsAddress: value.cvsAddress,
  cvsTelephone: value.cvsTelephone,
  cvsCartItems: value.cvsCartItems,
  d2dCartItems: value.d2dCartItems,
  ...initializeState({
    name: getDefaultValue(value, 'name'),
    phone: getDefaultValue(value, 'phone'),
    zipcode: getDefaultValue(value, 'zipcode'),
    city: getDefaultValue(value, 'city'),
    district: getDefaultValue(value, 'district'),
    address: getDefaultValue(value, 'address1'),
    discountCode: value.discountCode || '',
    discountConfigs: [],
    memo: value.memo || '',
    paymentMethod: value.paymentMethod,
    receiptType: value.receiptType || {
      value: 'RECEIPT_PERSONAL',
      label: message({ id: 'receipt.type.RECEIPT_PERSONAL' }),
    },
    receiptNo: value.receiptNo || '',
    receiptTitle: value.receiptTitle || '',
    donationNo: value.donationNo || '',
    storeAddress: true,
    openAddress: false,
    addresses: value.addresses || [],
  }),
})

function getShippingTypes(message) {
  return [
    {
      value: 'SHIP_TO_HOME',
      label: message({ id: 'shipping.type.SHIP_TO_HOME' }),
    },
    {
      value: 'SHIP_TO_CVS',
      label: message({ id: 'shipping.type.SHIP_TO_CVS' }),
    },
  ]
}

function getShippingType(value, message) {
  const shippingTypes = getShippingTypes(message)
  return value || shippingTypes[0]
}

function getShippingProviders(message) {
  return [
    {
      value: 'FAMI',
      label: message({ id: 'shipping.provider.FAMI' }),
    },
    {
      value: 'UNIMART',
      label: message({ id: 'shipping.provider.UNIMART' }),
    },
    {
      value: 'HILIFE',
      label: message({ id: 'shipping.provider.HILIFE' }),
    },
  ]
}

function getShippingProvider(value, message) {
  const shippingProviders = getShippingProviders(message)
  return value || shippingProviders[0]
}

function getShippingCollects(message) {
  return [
    {
      value: 'N',
      label: message({ id: 'shipping.collect.N' }),
    },
    {
      value: 'Y',
      label: message({ id: 'shipping.collect.Y' }),
    },
  ]
}

function getShippingCollect(value, message) {
  const shippingCollects = getShippingCollects(message)
  return value || shippingCollects[0]
}

const validation = (state) => {
  const result = {
    name: [
      { type: 'required', message: 'error.required' },
      { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
    ],
    phone: [
      { type: 'required', message: 'error.required' },
      { type: 'maxLength', val: 20, message: ['error.maxLength', { val: 25 }] },
    ],
    memo: [
      {
        type: 'maxLength',
        val: 500,
        message: ['error.maxLength', { val: 500 }],
      },
    ],
    shippingType: [{ type: 'required', message: 'error.required' }],
    receiptType: [{ type: 'required', message: 'error.required' }],
  }

  const shippingType = state.shippingType ? state.shippingType.value : null

  if (shippingType === 'SHIP_TO_HOME') {
    result.zipcode = [
      { type: 'required', message: 'error.required' },
      { type: 'minLength', val: 3, message: ['error.minLength', { val: 3 }] },
      { type: 'maxLength', val: 5, message: ['error.maxLength', { val: 5 }] },
    ]
    result.city = [
      { type: 'required', message: 'error.required' },
      { type: 'maxLength', val: 10, message: ['error.maxLength', { val: 10 }] },
    ]
    result.district = [
      { type: 'required', message: 'error.required' },
      { type: 'maxLength', val: 10, message: ['error.maxLength', { val: 10 }] },
    ]
    result.address = [
      { type: 'required', message: 'error.required' },
      { type: 'maxLength', val: 65, message: ['error.maxLength', { val: 65 }] },
    ]
  }

  if (shippingType === 'SHIP_TO_CVS') {
    result.cvsId = [{ type: 'required', message: 'error.required' }]
  }

  return result
}

export const fields = ({ app, session, state, setState, message }) => {
  const validator = validation(state)
  const onTextChange = (id) => handleTextChange(id, state, setState, validator)
  return {
    shippingType: (
      <Select
        isClearable={false}
        isSearchable={false}
        fieldProps={{ mb: 0, width: 1 }}
        placeholder="shipping.type"
        options={getShippingTypes(message)}
        value={state.shippingType}
        onChange={(shippingType) => {
          setState({
            ...state,
            shippingType,
            cvsId: '',
            cvsName: '',
            cvsAddress: '',
            cvsTelephone: '',
          })
        }}
        errMsg={state.__error__.shippingType}
      />
    ),
    shippingProvider: (
      <Select
        isClearable={false}
        isSearchable={false}
        fieldProps={{ mb: 0, width: 1 }}
        label="shipping.provider"
        options={getShippingProviders(message)}
        value={state.shippingProvider}
        onChange={(shippingProvider) => {
          setState({
            ...state,
            shippingProvider,
            cvsId: '',
            cvsName: '',
            cvsAddress: '',
            cvsTelephone: '',
          })
        }}
      />
    ),
    shippingCollect: (
      <Select
        isClearable={false}
        isSearchable={false}
        fieldProps={{ mb: 0, ml: 2, width: 1 }}
        label="shipping.collect"
        options={getShippingCollects(message)}
        value={state.shippingCollect}
        onChange={(shippingCollect) => {
          setState({ ...state, shippingCollect })
        }}
      />
    ),
    cvsProduct: (
      <Table
        columns={[
          {
            id: 'cvsProduct',
            label: 'cart.table.product',
            render: ({ row }) => (
              <Flex flexDirection={['column', 'row']} alignItems="center">
                <Image
                  src={row.image.src}
                  width="64px"
                  height="64px"
                  sx={{ objectFit: 'contain' }}
                />
                <Link href={`/product/${row.productId}`} mx={3}>
                  {row.spu}
                </Link>
              </Flex>
            ),
          },
          {
            id: 'unitPrice',
            label: 'cart.table.unitPrice',
            render: ({ row }) => <Text>NT.{row.price}</Text>,
          },
          {
            id: 'quantity',
            label: 'cart.table.quantity',
            render: ({ row }) => (
              <Counter
                value={row.quantity}
                onChange={async (quantity) => {
                  if (quantity <= 1) {
                    quantity = 1
                  }
                  const item = { ...row, quantity }
                  app.dispatch({ type: CART_EDIT, item })

                  setState({ ...state, discountConfigs: [] })
                }}
              />
            ),
          },
          {
            id: 'price',
            label: 'cart.table.price',
            render: ({ row }) => <Text>NT.{row.price * row.quantity}</Text>,
          },
          {
            id: 'action',
            align: 'end',
            render: ({ row }) => (
              <Link
                onClick={() => {
                  app.dispatch({ type: CART_REMOVE, id: row.id })
                  setState({ ...state, discountConfigs: [] })
                }}
              >
                <MdDelete size="24px" />
              </Link>
            ),
          },
        ]}
        rows={state.cvsCartItems}
      />
    ),
    d2dProduct: (
      <Table
        columns={[
          {
            id: 'd2dProduct',
            label: 'cart.table.product',
            render: ({ row }) => (
              <Flex flexDirection={['column', 'row']} alignItems="center">
                <Image
                  src={row.image.src}
                  width="64px"
                  height="64px"
                  sx={{ objectFit: 'contain' }}
                />
                <Link href={`/product/${row.productId}`} mx={3}>
                  {row.spu}
                </Link>
              </Flex>
            ),
          },
          {
            id: 'unitPrice',
            label: 'cart.table.unitPrice',
            render: ({ row }) => <Text>NT.{row.price}</Text>,
          },
          {
            id: 'quantity',
            label: 'cart.table.quantity',
            render: ({ row }) => (
              <Counter
                value={row.quantity}
                onChange={async (quantity) => {
                  if (quantity <= 1) {
                    quantity = 1
                  }
                  const item = { ...row, quantity }
                  app.dispatch({ type: CART_EDIT, item })

                  setState({ ...state, discountConfigs: [] })
                }}
              />
            ),
          },
          {
            id: 'price',
            label: 'cart.table.price',
            render: ({ row }) => <Text>NT.{row.price * row.quantity}</Text>,
          },
          {
            id: 'action',
            align: 'end',
            render: ({ row }) => (
              <Link
                onClick={() => {
                  app.dispatch({ type: CART_REMOVE, id: row.id })
                  setState({ ...state, discountConfigs: [] })
                }}
              >
                <MdDelete size="24px" />
              </Link>
            ),
          },
        ]}
        rows={state.d2dCartItems}
      />
    ),
    invoicePrice: getInvoicePrice(app.state.cartItems),
    discountPrice: getDiscountPrice(state.discountConfigs),
    shippingFee: getShippingFee(app.state.cartItems, state.discountConfigs),
    totalPrice: getTotalPrice(app.state.cartItems, state.discountConfigs),
    name: (
      <TextInput
        placeholder="checkout.field.name"
        autoComplete="name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    phone: (
      <TextInput
        placeholder="checkout.field.phone"
        value={state.phone}
        autoComplete="tel"
        onKeyPress={handleKeyPress(
          state,
          setState,
          'phone',
          /[0-9|\-|\\(|\\)]/
        )}
        onChange={onTextChange('phone')}
        errMsg={state.__error__.phone}
      />
    ),
    zipcode: (
      <TextInput
        placeholder="checkout.field.zipcode"
        autoComplete="postal-code"
        value={state.zipcode}
        onChange={onTextChange('zipcode')}
        errMsg={state.__error__.zipcode}
      />
    ),
    city: (
      <TextInput
        containerProps={{ px: 2 }}
        placeholder="checkout.field.city"
        autoComplete="address-level1"
        value={state.city}
        onChange={onTextChange('city')}
        errMsg={state.__error__.city}
      />
    ),
    district: (
      <TextInput
        placeholder="checkout.field.district"
        autoComplete="address-level2"
        value={state.district}
        onChange={onTextChange('district')}
        errMsg={state.__error__.district}
      />
    ),
    address: (
      <TextInput
        placeholder="checkout.field.address"
        autoComplete="street-address"
        value={state.address}
        onChange={onTextChange('address')}
        errMsg={state.__error__.address}
      />
    ),
    discountCode: (
      <TextInput
        containerProps={{ flex: 1 }}
        placeholder="checkout.field.discountCode"
        value={state.discountCode}
        onChange={onTextChange('discountCode')}
        errMsg={state.__error__.discountCode}
      />
    ),
    memo: (
      <TextArea
        containerProps={{ mb: 0 }}
        rows="3"
        placeholder="checkout.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
        errMsg={state.__error__.memo}
      />
    ),
    receiptType: (
      <Select
        isClearable={false}
        isSearchable={false}
        fieldProps={{ mb: 0, width: 1 }}
        placeholder="receipt.type"
        options={[
          {
            value: 'RECEIPT_PERSONAL',
            label: message({ id: 'receipt.type.RECEIPT_PERSONAL' }),
          },
          {
            value: 'RECEIPT_BUSINESS',
            label: message({ id: 'receipt.type.RECEIPT_BUSINESS' }),
          },
          // {
          //   value: 'RECEIPT_DONATE',
          //   label: message({ id: 'receipt.type.RECEIPT_DONATE' }),
          // },
        ]}
        value={state.receiptType}
        onChange={(item) => {
          state.receiptNo = ''
          state.receiptTitle = ''
          state.donationNo = ''
          handleSelectChange('receiptType', state, setState, validator)(item)
        }}
      />
    ),
    receiptNo: (
      <TextInput
        containerProps={{ width: 1 }}
        placeholder="receipt.field.receiptNo"
        value={state.receiptNo}
        onChange={onTextChange('receiptNo')}
        onKeyPress={handleKeyPress(state, setState, 'receiptNo', /[0-9]/)}
        errMsg={state.__error__.receiptNo}
      />
    ),
    receiptTitle: (
      <TextInput
        containerProps={{ width: 1 }}
        placeholder="receipt.field.receiptTitle"
        value={state.receiptTitle}
        onChange={onTextChange('receiptTitle')}
        errMsg={state.__error__.receiptTitle}
      />
    ),
    donationNo: (
      <TextInput
        containerProps={{ width: 1 }}
        placeholder="receipt.field.donationNo"
        value={state.donationNo}
        onChange={onTextChange('donationNo')}
        errMsg={state.__error__.donationNo}
      />
    ),
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  actions,
  message,
  url,
}) => ({
  handleLoad: async () => {
    const data = await getUser({ app, session })
    if (!data) {
      history.push('/login?redirect=' + encodeURI('/checkout'))
      return
    }
    const { checkoutData } = app.state
    if (checkoutData) {
      checkoutData.cvsId = url.searchParams.get('storeId')
      checkoutData.cvsName = url.searchParams.get('storeName')
      checkoutData.cvsAddress = url.searchParams.get('storeAddress')
      checkoutData.cvsTelephone = url.searchParams.get('storeTelephone')
      setState(initialState(checkoutData, message))
    } else {
      const products = await getCartItems(app, session)
      const { cvsCartItems, d2dCartItems } = filterCartItems(products)
      const { addresses = [] } = data.extra || {}
      const { channelProviders = [] } = app.state.merchant
      const paymentMethod =
        channelProviders.length > 0 ? channelProviders[0] : null
      const shippingType = state.shippingType
      setState(
        initialState(
          {
            ...state,
            shippingType,
            cvsCartItems,
            d2dCartItems,
            addresses,
            paymentMethod,
          },
          message
        )
      )
    }
    setBreadcrumb({ app })
  },
  handleCvsMap: () => {
    app.dispatch({ type: CHECKOUT_SET, item: state })
    return false
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    const validator = validation(state)
    if (!validateForm({ state, setState, validation: validator })) return

    const { shippingType, shippingCollect } = state
    const isCvs = shippingType.value === 'SHIP_TO_CVS'
    const isCvsCollect = shippingCollect.value === 'Y'
    const paymentMethod = isCvs && isCvsCollect ? 'CASH' : state.paymentMethod

    if (isCvs) {
      const phone = getCanonicalValue(state.phone)
      if (phone.length !== 10) {
        session.dispatch({
          type: ALERT_ADD,
          item: { type: 'error', message: 'error.checkout.invalidPhone' },
        })
        return
      }
    }

    if (!paymentMethod) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.checkout.missingPaymentMethod' },
      })
      return
    }

    if (state.shippingType.value === 'SHIP_TO_CVS' && !state.cvsId) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.checkout.missingCvsId' },
      })
      return
    }

    actions.setDisableSubmit(true)
    const ticketId = await createOrder({ state, session, app, paymentMethod })
    if (!ticketId) {
      actions.setDisableSubmit(false)
      return
    }

    // const totalPrice = getTotalPrice(app.state.cartItems, state.discountConfigs)

    const resp = await createWebpay({
      state,
      session,
      app,
      ticketId,
      channelProvider: paymentMethod,
    })
    if (!resp) {
      actions.setDisableSubmit(false)
      return
    }

    app.dispatch({ type: CHECKOUT_SET, item: null })

    if (paymentMethod === 'CASH') {
      app.dispatch({ type: CART_CLEAR })
      history.push('/member/order')
      return
    }

    if (paymentMethod === 'ECPAY') {
      const { url, form } = resp
      ecpayRedirect({ url, form })
      return
    }

    if (paymentMethod === 'LINEPAY') {
      window.location.href = resp.paymentUrl.web
      return
    }
  },
  getProductCount: () => {
    return app.state.cartItems.length
  },
  getDiscountAmount: async () => {
    const code = state.discountCode
    if (!code) return

    const discount = await getDiscount({ session, app, code })
    if (!discount) return

    if (state.discountConfigs.some((item) => item.code === code)) {
      return
    }

    const discountConfigs = [
      ...state.discountConfigs,
      {
        id: discount.discountConfigId,
        code,
        amount: discount.discountValue,
        allowCombination: discount.allowCombination,
      },
    ]

    if (
      discountConfigs.length > 1 &&
      discountConfigs.some((item) => item.allowCombination === 'NO')
    ) {
      session.dispatch({
        type: ALERT_ADD,
        item: {
          type: 'error',
          message: 'error.discount.combinationNotAllowed',
        },
      })
      return
    }

    setState({
      ...state,
      discountCode: '',
      discountConfigs,
    })
  },
  getWaiverFee: () => {
    const { shippingWaiverFee } = getPotentialShippingFee(app.state.cartItems)
    return shippingWaiverFee
  },
  removeDiscount: async () => {
    setState({ ...state, discountConfigs: [] })
  },
  setPaymentMethod: (paymentMethod) => {
    setState({ ...state, paymentMethod })
  },
  toggleStoreAddress: () => {
    setState({ ...state, storeAddress: !state.storeAddress })
  },
  openAddress: (openAddress) => {
    setState({ ...state, openAddress })
  },
  onAddressClick: (item) => {
    setState({
      ...state,
      openAddress: false,
      name: item.name,
      phone: item.phone,
      zipcode: item.zipcode || '',
      city: item.city || '',
      district: item.district || '',
      address: item.address1,
    })
  },
})

const getUser = async ({ app, session }) => {
  const query = `
    query {
      user {
        id
        username
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query }, { session, app })
  if (!ok) return null

  return data.user
}

export const setBreadcrumb = ({ app }) => {
  const items = [
    { label: 'Home', url: '/' },
    { label: <FormattedMessage id="cart.title" /> },
  ]

  app.dispatch({ type: BREADCRUMB_SET, items })
}

function getInvoicePrice(cartItems) {
  return cartItems.reduce((total, item) => {
    total += item.price * item.quantity
    return total
  }, 0)
}

function getDiscountPrice(discountConfigs) {
  return Math.round(
    discountConfigs.reduce((result, item) => {
      result += item.amount
      return result
    }, 0)
  )
}

function getPotentialShippingFee(cartItems) {
  if (cartItems.length === 0) return { shippingFee: 0, shippingWaiverFee: 0 }

  let { shippingFee, shippingWaiverFee } = cartItems[0]
  cartItems.forEach((item) => {
    if (item.shippingFee > shippingFee) {
      shippingFee = item.shippingFee
      shippingWaiverFee = item.shippingWaiverFee
    }
  })
  return { shippingFee, shippingWaiverFee }
}

function getShippingFee(cartItems, discountConfigs) {
  const totalPrice = getPreShippingPrice(cartItems, discountConfigs)
  const { shippingFee, shippingWaiverFee } = getPotentialShippingFee(cartItems)
  return totalPrice >= shippingWaiverFee ? 0 : shippingFee
}

function getPreShippingPrice(cartItems, discountConfigs) {
  let totalPrice = getInvoicePrice(cartItems)

  if (discountConfigs.length > 0) {
    totalPrice -= getDiscountPrice(discountConfigs)
  }

  return totalPrice < 0 ? 0 : totalPrice
}

function getTotalPrice(cartItems, discountConfigs) {
  const totalPrice = getPreShippingPrice(cartItems, discountConfigs)
  const shippingFee = getShippingFee(cartItems, discountConfigs)
  return totalPrice + shippingFee
}

async function createOrder({ state, session, app, paymentMethod }) {
  const { cartItems } = app.state
  const ticketItems = cartItems.map((item) => ({
    merchantId: item.merchantId,
    productVariantId: item.id,
    quantity: item.quantity,
    extra: { backorder: item.backorder },
  }))
  const { shippingType, discountConfigs } = state
  const isCvs = shippingType.value === 'SHIP_TO_CVS'
  const totalAmount = getInvoicePrice(cartItems)
  const shipping = {
    name: state.name,
    phone: state.phone,
    zipcode: state.zipcode,
    city: state.city,
    district: state.district,
    address: state.address,
    memo: state.memo,
  }
  const receipt = {
    type: state.receiptType.value,
    receiptNo: state.receiptNo,
    receiptTitle: state.receiptTitle,
    donationNo: state.donationNo,
  }
  const input = {
    ticketType: 'SELL',
    ticketItems,
    price: totalAmount,
    extra: {
      shipping,
      receipt,
      discountCodes: discountConfigs.map(({ code }) => code),
      storeAddress: state.storeAddress,
      paymentMethod,
    },
  }
  if (isCvs) {
    input.extra = {
      ...input.extra,
      cvsProvider: state.shippingProvider.value,
      cvsCollect: state.shippingCollect.value,
      cvsId: state.cvsId,
      cvsName: state.cvsName,
      cvsAddress: state.cvsAddress,
      cvsTelephone: state.cvsTelephone,
    }
  }
  const variables = { input }
  const query = `
    mutation($input: TicketInput!) {
      createTicket(input: $input)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data.createTicket
}

async function createWebpay({
  state,
  session,
  app,
  ticketId,
  channelProvider,
}) {
  const { merchant, cartItems } = app.state
  const { name, companyName } = merchant.estore
  const itemName = cartItems.reduce((result, item) => {
    const { spu, price, quantity } = item
    result += `${spu}(NT.${price})x${quantity}#`
    return result
  }, '')
  const input = {
    channelProvider,
    channelType: 'CREDIT_CARD',
    ticketId,
    tradeDesc: companyName || name,
    itemName,
    shipping: {
      name: state.name,
      phone: state.phone,
      address: getAddress(state),
    },
  }
  const variables = { input }
  const query = `
    mutation($input: PaymentInput!) {
      createWebpay(input: $input)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data.createWebpay
}

function getDefaultValue(value, field) {
  const address = getDefaultAddress(value)
  if (!address) return value[field] || ''

  return address[field] || ''
}

function getDefaultAddress(value) {
  const { addresses } = value
  if (!addresses || addresses.length === 0) return ''

  return addresses.find((item) => item.default)
}

function getAddress(state) {
  const zipcode = state.zipcode || ''
  const city = state.city || ''
  const district = state.district || ''
  const address = state.address || ''
  return zipcode + city + district + address
}

async function getDiscount({ session, app, code }) {
  const variables = {
    input: {
      code,
      products: app.state.cartItems.map((item) => ({
        productVariantId: item.id,
        price: item.price,
        quantity: item.quantity,
      })),
    },
  }
  const query = `
    query ($input: DiscountPriceInput!) {
      discountPrice(input: $input) {
        discountConfigId
        discountValue
        allowCombination
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data.discountPrice
}

export function ecpayRedirect({ url, form }) {
  const inputs = Object.entries(form).map(([key, val]) => {
    return `<input type="hidden" name="${key}" id="${key}" value="${val}" />`
  })
  document.write(`
    <form id="ecpay" action="${url}" method="post">
      ${inputs.join('')}
    </form>
    <script type="text/javascript">document.getElementById("ecpay").submit();</script>
  `)
}

function getCanonicalValue(str) {
  if (typeof str !== 'string') return str
  return str.replace(/[\s-().,]/g, '')
}

async function getCartItems(app, session) {
  const { cartItems } = app.state
  const productVariantId = cartItems.map((item) => item.id)
  const products = await getProducts({ app, session, productVariantId })
  const balances = await getBalances({ app, session, productVariantId })
  return products.map((item) => {
    const cartItem = cartItems.find((i) => i.id === item.id)
    item.quantity = cartItem.quantity

    const balance = balances.find((b) => b.productVariantId === item.id)
    item.balance = balance ? balance.quantity : 0

    return item
  })
}

async function getProducts({ app, session, productVariantId }) {
  const merchantId = app.state.merchant.id
  const variables = { merchantId, input: { productVariantId } }
  const query = `
    query($merchantId: ID!, $input: ProductVariantQueryInput) {
      productVariants(merchantId: $merchantId, input: $input) {
        id
        sku
        barcode
        price
        postedPrice
        options {
          name
          value
        }
        image {
          src
          alt
        }
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return data.productVariants

  return data.productVariants
}

async function getBalances({ app, session, productVariantId }) {
  const merchantId = app.state.merchant.id
  const variables = { productVariantId, merchantId }
  const query = `
    query($merchantId: ID!, $productVariantId: [ID!]!) {
      userInventoryBalances(merchantId: $merchantId, variants: $productVariantId) {
        productVariantId
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) {
    return []
  }

  return data.userInventoryBalances
}

function filterCartItems(products) {
  const cvsCartItems = []
  const d2dCartItems = []
  products.forEach((item) => {
    const { shippingProviders } = item.extra || {}
    if (!shippingProviders) {
      d2dCartItems.push(item)
      return
    }

    const { FAMI, HILIFE, UNIMART, DOOR_TO_DOOR } = shippingProviders
    const hasCvs =
      FAMI.status === 'ACTIVE' ||
      HILIFE.status === 'ACTIVE' ||
      UNIMART.status === 'ACTIVE'

    if (DOOR_TO_DOOR.status === 'ACTIVE' || !hasCvs) {
      d2dCartItems.push(item)
      return
    }

    cvsCartItems.push(item)
  })
  return { cvsCartItems, d2dCartItems }
}
