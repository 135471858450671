import React from 'react'
import { Flex, Button } from 'rebass'
import Form from 'components/Form'
import Message from 'components/Message'
import Page from 'components/Page'
import Container from 'components/Container'
import * as model from 'models/loginReset'

export default () => {
  const url = new URL(window.location.href)
  const id = url.searchParams.get('id')
  const resetToken = url.searchParams.get('resetToken')

  return (
    <Page title="reset.title">
      <Container
        minHeight="calc(100vh - 242px)"
        bg="grey.0"
        contentProps={{ width: '300px' }}
      >
        <Form {...model} value={{ id, resetToken }}>
          {({ form }) => (
            <>
              <Flex variant="header" mb={3}>
                <Message id="reset.title" />
              </Flex>
              {form.password}
              {form.passwordConfirm}
              <Button width={1} variant="accent">
                <Message id="btn.submit" />
              </Button>
            </>
          )}
        </Form>
      </Container>
    </Page>
  )
}
