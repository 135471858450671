import React from 'react'
import Message from 'components/Message'
import { request } from 'utilities/requestUtil'
import { BREADCRUMB_SET } from 'constants/actionType'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import TextArea from 'components/TextArea'

export const initialState = (value = {}, message) =>
  initializeState({
    id: value.id,
    message: value.message || '',
    messages: value.messages || []
  })

const validation = {
  message: [
    { type: 'required', message: 'error.required' },
    {
      type: 'maxLength',
      val: 1500,
      message: ['error.maxLength', { val: 1500 }]
    }
  ]
}

export const fields = ({ app, session, state, setState, message }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    message: (
      <TextArea
        rows="6"
        placeholder="support.field.reply"
        value={state.message}
        onChange={onTextChange('message')}
        errMsg={state.__error__.message}
      />
    )
  }
}

export const handlers = ({ id, state, setState, session, app }) => ({
  handleLoad: async () => {
    const [ok, data] = await listMessages({ id, session, app, state })
    if (!ok) return

    setState(initialState({ id, messages: data.supportMessages }))
  },
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) {
      return
    }

    const [ok] = await addReply({ session, app, state })
    if (!ok) return

    const [listOk, ListData] = await listMessages({ id, session, app, state })
    if (!listOk) return

    setState({ ...state, message: '', messages: ListData.supportMessages })
  }
})

const listMessages = async ({ id, app, session }) => {
  const variables = { input: { rootId: id } }
  const query = `
    query($input: SupportMessageInput) {
      supportMessages(input: $input) {
        id
        userName
        staffName
        message
        createdAt
      }
    }
  `
  return request({ query, variables }, { session, app })
}

async function addReply({ session, app, state }) {
  const variables = {
    parentId: state.id,
    message: state.message
  }
  const query = `
    mutation($parentId: ID!, $message: String!) {
      addSupportReply(parentId: $parentId, message: $message)
    }
  `
  return request({ query, variables }, { session, app })
}

export const setBreadcrumb = ({ app, title }) => {
  const items = [
    { label: 'Home', url: '/' },
    { label: <Message id="support.title" />, url: '/support' },
    { label: title }
  ]

  app.dispatch({ type: BREADCRUMB_SET, items })
}
