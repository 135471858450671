import React, { useState, useEffect, useContext } from 'react'
import { Box, Flex, Text, Button } from 'rebass'
import { useHistory } from 'react-router-dom'
import Link from 'components/Link'
import Message from 'components/Message'
import Divider from 'components/Divider'
import Image from 'components/Image'
import Tab from 'components/Tab'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Table from 'components/Table'
import LoadingIcon from 'components/LoadingIcon'
import Page from 'components/Page'
import Definition from 'components/Definition'
import OrderRefundModal from 'views/OrderRefundModal'
import {
  onLoad,
  setBreadcrumb,
  cancelOrder,
  getRefundBalance,
  makePayment,
  disableCancel,
} from 'models/orderPage'
import { formatDate } from 'utilities/dateUtil'
import DataPlaceholder from 'components/DataPlaceholder'
import RefundRule from 'components/RefundInfo'
import ConfirmButton from 'components/ConfirmButton'
// import Link from 'components/Link'

export default ({ match }) => {
  const history = useHistory()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState({})
  const [refundOpen, setRefundOpen] = useState(false)
  const [refundValue, setRefundValue] = useState({})
  const ticketId = match.params.id
  const { ticketItems, discountTransactions, payments, refTickets } = state
  const ticket = state.userTicket

  useEffect(() => {
    onLoad({ setState, app, session, ticketId })
    setBreadcrumb({ app })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId])

  const handleRefundClose = () => {
    setRefundOpen(false)
    setRefundValue({})
  }

  const handleRefundOpen = (item) => {
    const hasDiscounts = discountTransactions.length > 0
    setRefundValue(getRefundBalance(item, refTickets, hasDiscounts))
    setRefundOpen(true)
  }

  const handleRefresh = () => {
    onLoad({ setState, app, session, ticketId })
  }

  if (!ticket) {
    return <LoadingIcon>LOADING...</LoadingIcon>
  }

  const extra = ticket.extra || {}
  const userInfo = extra.userInfo || {}
  const shipping = extra.shipping || {}
  const paymentMethod = extra.paymentMethod || ''
  const receipt = extra.receipt || {}
  const timeline = extra.shipmentTimeline || []
  const cvs = getCvsInfo(ticket)

  return (
    <Page title="orderList.title">
      <Tab
        value={[
          {
            id: 'basic',
            label: <Message id="orderDetail.section.order" />,
            render: renderOrderTab({
              setState,
              session,
              app,
              ticket,
              discountTransactions,
              paymentMethod,
              receipt,
              userInfo,
              shipping,
              cvs,
              ticketItems,
            }),
          },
          {
            id: 'payment',
            label: <Message id="orderDetail.section.payment" />,
            render: renderPaymentTab({ session, app, history, payments }),
          },
          {
            id: 'shipping',
            label: <Message id="orderDetail.section.shippingStatus" />,
            render: renderShipping({ timeline }),
          },
          {
            id: 'refund',
            label: <Message id="orderDetail.section.refund" />,
            render: renderRefundTab({
              ticket,
              ticketItems,
              refTickets,
              payments,
              handleRefundOpen,
            }),
          },
        ]}
      />
      <OrderRefundModal
        open={refundOpen}
        value={refundValue}
        actions={{ handleRefundClose, handleRefresh }}
      />
    </Page>
  )
}

const renderOrderTab = ({
  setState,
  session,
  app,
  ticket,
  discountTransactions,
  paymentMethod,
  receipt,
  userInfo,
  shipping,
  cvs,
  ticketItems,
}) => () => (
  <>
    <Flex justifyContent="flex-end" alignItems="center" mb={3}>
      <ConfirmButton
        disabled={disableCancel(ticket)}
        fontSize={2}
        title="orderDetail.title.cancel"
        message="orderDetail.message.cancel"
        variant="accent"
        onClick={(handleClose) => {
          cancelOrder({
            setState,
            app,
            session,
            ticketId: ticket.id,
            handleClose,
          })
        }}
      >
        <Message id="orderDetail.btn.cancelOrder" />
      </ConfirmButton>
    </Flex>
    <Box
      mt={2}
      mb={2}
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 1fr'],
        gridGap: 3,
      }}
    >
      <Box>
        <Definition label="orderDetail.field.ticketNo" value={ticket.id} />
        <Definition
          label="orderDetail.field.transDate"
          value={formatDate(new Date(ticket.transDate))}
        />
        <Definition
          label="orderDetail.field.orderPrice"
          value={`NT.${ticket.price}`}
        />
        <Definition
          label="orderDetail.field.shippingFee"
          value={`NT.${ticket.shippingFee}`}
        />
        {discountTransactions.length > 0 && (
          <Definition
            label="orderDetail.field.discountCode"
            value={discountTransactions.map(({ code }) => code).join(', ')}
          />
        )}
        {discountTransactions.length > 0 && (
          <Definition
            label="orderDetail.field.discountPrice"
            value={
              'NT.' +
              discountTransactions.reduce((result, item) => {
                result += item.discountValue
                return result
              }, 0)
            }
          />
        )}
        <Definition
          label="orderDetail.field.paymentMethod"
          value={getPaymentMethod(paymentMethod)}
        />
        <Definition label="orderDetail.field.status">
          <Message id={`orderList.status.${ticket.status}`} />
        </Definition>
        <Definition label="receipt.field.receiptType">
          <Message id={`receipt.type.${receipt.type}`} />
        </Definition>
        {receipt.receiptNo && (
          <Definition
            label="receipt.field.receiptNo"
            value={receipt.receiptNo}
          />
        )}
        {receipt.receiptTitle && (
          <Definition
            label="receipt.field.receiptTitle"
            value={receipt.receiptTitle}
          />
        )}
        {receipt.donationNo && (
          <Definition
            label="receipt.field.donationNo"
            value={receipt.donationNo}
          />
        )}
      </Box>
      <Box>
        <Definition
          label="orderDetail.field.userInfo.name"
          value={userInfo.name}
        />
        <Definition
          label="orderDetail.field.userInfo.phone"
          value={userInfo.phone}
        />
        <Definition
            label="orderDetail.field.userInfo.address"
            value={getShippingAddress(userInfo)}
          />
        <Definition
          label="orderDetail.field.shipping.name"
          value={shipping.name}
        />
        <Definition
          label="orderDetail.field.shipping.phone"
          value={shipping.phone}
        />
        {!cvs ? (
          <Definition
            label="orderDetail.field.shipping.address"
            value={getShippingAddress(shipping)}
          />
        ) : (
          <>
            <Definition
              show={!!cvs.cvsName}
              label="checkout.field.cvsName"
              value={cvs.cvsName}
            />
            <Definition
              show={!!cvs.cvsAddress}
              label="checkout.field.cvsAddress"
              value={cvs.cvsAddress}
            />
            <Definition
              show={!!cvs.cvsTelephone}
              label="checkout.field.cvsTelephone"
              value={cvs.cvsTelephone}
            />
          </>
        )}
        <Definition
          label="orderDetail.field.shipping.memo"
          value={shipping.memo}
        />
      </Box>
    </Box>
    <Table
      width={1}
      columns={[
        {
          id: 'product',
          label: 'orderDetail.table.product',
          render: ({ row }) => (
            <Flex alignItems="center">
              <Image
                src={row.image.src}
                width="64px"
                height="64px"
                sx={{ objectFit: 'contain' }}
              />
              <Text mx={3}>{row.spu}</Text>
            </Flex>
          ),
        },
        {
          id: 'unitPrice',
          label: 'orderDetail.table.unitPrice',
          align: 'right',
          render: ({ row }) => <Text>{row.price}</Text>,
        },
        {
          id: 'quantity',
          label: 'orderDetail.table.quantity',
          align: 'right',
        },
        {
          id: 'price',
          label: 'orderDetail.table.price',
          align: 'right',
          render: ({ row }) => {
            const { price, quantity } = row
            return <Text>{price * quantity}</Text>
          },
        },
      ]}
      rows={ticketItems}
    />
  </>
)

const renderPaymentTab = ({ session, app, history, payments }) => () => (
  <Table
    width={1}
    columns={[
      {
        id: 'transType',
        label: 'payment.field.transType',
        render: ({ row }) => <Text>{getTransType(row.transType)}</Text>,
      },
      {
        id: 'channelType',
        label: 'payment.field.channelType',
        render: ({ row }) => (
          <Message id={`payment.channelProvider.${row.channelProvider}`} />
        ),
      },
      {
        id: 'transDate',
        label: 'payment.field.transDate',
        render: ({ row }) => <Text>{formatDate(new Date(row.transDate))}</Text>,
      },
      {
        id: 'status',
        label: 'payment.field.status',
        render: ({ row }) => (
          <Flex>
            {row.status === 'PENDING' ? (
              <Link
                variant="secondary"
                onClick={() =>
                  makePayment({ session, app, history, payment: row })
                }
              >
                <Message id="payment.btn.pay" />
              </Link>
            ) : (
              <Message id={`payment.status.${row.status}`} />
            )}
          </Flex>
        ),
      },
      {
        id: 'transAmount',
        label: 'payment.field.transAmount',
        align: 'right',
      },
    ]}
    rows={payments.filter((item) => item.status !== 'INACTIVE')}
  />
)

const renderShipping = ({ timeline }) => () => (
  <Table
    width={1}
    columns={[
      {
        id: 'timestamp',
        label: 'shipping.field.timestamp',
      },
      {
        id: 'status',
        label: 'shipping.field.status',
        render: ({ row }) => <Message id={`order.status.${row.status}`} />,
      },
      {
        id: 'memo',
        label: 'shipping.field.memo',
      },
    ]}
    rows={timeline}
  />
)

// const renderShipping = ({ timeline }) => () =>
//   timeline && timeline.length > 0 ? (
//     timeline.map((item, index) => (
//       <Flex key={`timeline-${index}`} pb={4} lineHeight={1.5}>
//         <Text flex={1} color="grey.5">
//           {item.timestamp}
//         </Text>
//         <Box flex={3}>
//           <Text>{getShippingStatus(item.status)}</Text>
//           <Text mt={2} color="grey.4">
//             {item.memo}
//           </Text>
//         </Box>
//       </Flex>
//     ))
//   ) : (
//     <DataPlaceholder py={3} />
//   )

const renderRefundTab = ({
  ticket,
  ticketItems,
  refTickets,
  payments,
  handleRefundOpen,
}) => () => (
  <>
    {refTickets.length > 0 ? (
      refTickets.map((item, index) => {
        const payment = payments.find(({ ticketId }) => ticketId === ticket.id)
        const extra = payment.extra || {}
        return (
          <Box key={item.id} mb={4}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gridColumnGap: 3,
              }}
            >
              <Definition label="refund.field.id" value={item.id} />
              <Definition
                label="refund.field.transDate"
                value={formatDate(new Date(item.transDate))}
              />
              <Definition
                show={extra.cvsReturnNo}
                label="refund.field.cvsReturnNo"
                value={extra.cvsReturnNo}
              />
              <Definition label="refund.field.status">
                <Message id={`refund.status.${item.status}`} />
              </Definition>
            </Box>
            <Table
              width={1}
              columns={[
                {
                  id: 'product',
                  label: 'orderDetail.table.product',
                  render: ({ row }) => {
                    const product = ticketItems.find(
                      (item) =>
                        item.productVariantId === `${row.productVariantId}`
                    )
                    return (
                      <Flex alignItems="center">
                        <Image
                          src={product.image.src}
                          width="64px"
                          height="64px"
                          sx={{ objectFit: 'contain' }}
                        />
                        <Text mx={3}>{product.spu}</Text>
                      </Flex>
                    )
                  },
                },
                {
                  id: 'quantity',
                  label: 'refund.field.quantity',
                  align: 'right',
                },
              ]}
              rows={item.ticketItems}
            />
            {index < refTickets.length - 1 && <Divider mt={4} bg="grey.3" />}
          </Box>
        )
      })
    ) : (
      <DataPlaceholder />
    )}
    <Flex justifyContent="center" alignItems="center" mt={3}>
      <Button
        disabled={ticket.status === 'INACTIVE'}
        type="button"
        variant="accent"
        onClick={() =>
          handleRefundOpen({
            id: ticket.id,
            cvsPaymentId: ticket.cvsPaymentId,
            ticketItems,
          })
        }
      >
        <Message id="orderDetail.btn.addRefund" />
      </Button>
    </Flex>
    <Box
      bg="grey.1"
      p={3}
      my={3}
      sx={{
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'grey.3',
        borderRadius: '4px',
      }}
    >
      <RefundRule />
    </Box>
  </>
)

function getShippingAddress(shipping) {
  let address = ''
  if (shipping.zipcode) address += shipping.zipcode + ' '
  if (shipping.city) address += shipping.city
  if (shipping.district) address += shipping.district
  if (shipping.address) address += shipping.address
  return address
}

function getTransType(status) {
  switch (status) {
    case 'PAYMENT':
      return <Message id="payment.transType.payment" />
    case 'REFUND':
      return <Message id="payment.transType.refund" />
    default:
      return status
  }
}

function getPaymentMethod(value) {
  switch (value) {
    case 'CASH':
      return <Message id="payment.method.cash" />
    default:
      return value
  }
}

function getCvsInfo(ticket) {
  if (!ticket || !ticket.extra.cvsPaymentId) return null

  const extra = ticket.extra
  return {
    cvsPaymentId: extra.cvsPaymentId,
    cvsName: extra.cvsName,
    cvsAddress: extra.cvsAddress,
    cvsTelephone: extra.cvsTelephone,
  }
}
