import React from 'react'
import { FormattedMessage } from 'react-intl'
import { request } from 'utilities/requestUtil'
import { BREADCRUMB_SET } from 'constants/actionType'
import { ecpayRedirect } from 'models/checkout'

export const onLoad = async ({ setState, app, session, ticketId }) => {
  const variables = { ticketId, ticketType: 'REFUND', input: { ticketId } }
  const query = `
    query($ticketId: ID!, $ticketType: String, $input: DiscountQueryInput!) {
      userTicket(ticketId: $ticketId) {
        id
        ticketType
        transDate
        price
        amountBilled
        amountAcquired
        shippingFee
        extra
        status
      }
      ticketItems(ticketId: $ticketId) {
        productVariantId
        spu
        sku
        options {
          name
          value
        }
        image {
          src
          alt
          variantId
        }
        price
        quantity
        transDate
        status
      }
      discountTransactions(input: $input) {
        code
        discountValue
      }
      payments(ticketId: $ticketId) {
        id
        ticketId
        transDate
        transType
        channelType
        channelProvider
        transAmount
        extra
        status
      }
      refTickets(id: $ticketId, ticketType: $ticketType) {
        id
        transDate
        ticketItems {
          productVariantId
          quantity
        }
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  setState(data)
}

export const setBreadcrumb = ({ app }) => {
  const items = [
    { label: 'Home', url: '/' },
    {
      label: <FormattedMessage id="orderList.title" />,
      url: '/member/order',
    },
    { label: <FormattedMessage id="orderDetail.title" /> },
  ]

  app.dispatch({ type: BREADCRUMB_SET, items })
}

export const getRefundBalance = (item, refundOrders, hasDiscounts) => {
  const refundItems = refundOrders.reduce((result, item) => {
    const { ticketItems, status } = item
    if (status === 'INACTIVE') return result

    ticketItems.forEach((item) => {
      const quantity = result[item.productVariantId] || 0
      result[item.productVariantId] = quantity + item.quantity
    })
    return result
  }, {})

  item.ticketItems.forEach((item) => {
    let refundQuantity = refundItems[item.productVariantId] || 0
    if (refundQuantity > item.quantity) {
      refundQuantity = item.quantity
    }
    item.quantity -= refundQuantity
  })
  item.hasDiscounts = hasDiscounts
  return item
}

export async function cancelOrder({
  setState,
  app,
  session,
  ticketId,
  handleClose,
}) {
  const variables = { id: ticketId, ticketType: 'SELL' }
  const query = `
    mutation($id: ID!, $ticketType: String!) {
      cancelTicket(id: $id, ticketType: $ticketType)
    }
  `
  const [ok] = await request({ query, variables }, { app, session })
  if (!ok) return null

  onLoad({ setState, app, session, ticketId })
  handleClose()
}

export async function makePayment({ session, app, history, payment }) {
  const resp = await createWebpay({ session, app, payment })

  if (payment.channelProvider === 'ECPAY') {
    const { url, form } = resp
    ecpayRedirect({ url, form })
    return
  }

  if (payment.channelProvider === 'LINEPAY') {
    window.location.href = resp.paymentUrl.web
    return
  }
}

async function createWebpay({ session, app, payment }) {
  const input = {
    channelProvider: payment.channelProvider,
    channelType: payment.channelType,
    ticketId: payment.ticketId,
    paymentId: payment.id,
  }
  const variables = { input }
  const query = `
    mutation($input: PaymentInput!) {
      createWebpay(input: $input)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data.createWebpay
}

export const disableCancel = (ticket) => {
  const { amountAcquired, extra, status } = ticket
  const doneStates = [
    'INACTIVE',
    'ACTIVE',
    'REFUND_PENDING',
    'FULL_REFUND',
    'PARTIAL_REFUND',
  ]
  if (doneStates.includes(status)) return true
  if (amountAcquired > 0) return true

  const { paymentMethod } = extra
  if (paymentMethod === 'CASH' && status === 'PAY_PENDING') {
    return true
  }

  return false
}
