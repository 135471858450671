import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Box, Button } from 'rebass'
import Link from 'components/Link'
import Message from 'components/Message'
import Form from 'components/Form'
import Page from 'components/Page'
import Container from 'components/Container'
import TextDivider from 'components/TextDivider'
import * as loginModel from 'models/login'
import * as signupModel from 'models/signup'

const Login = () => (
  <Page title="login.title">
    <Container minHeight="calc(100vh - 242px)" bg="grey.0">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr auto 1fr'],
          justifyItems: 'center',
          gridGap: 0,
        }}
      >
        <Form {...loginModel} width="300px" p={3}>
          {({ form }) => (
            <>
              <Flex variant="header" mb={4}>
                <Message id="app.section.login" />
              </Flex>
              {form.username}
              {form.password}
              <Flex justifyContent="flex-end" alignItems="center" mb={3}>
                <Link href="/forget">
                  <Message id="login.btn.reset" />
                </Link>
              </Flex>
              <Button width={1} variant="accent">
                <FormattedMessage id="login.btn.login" />
              </Button>
            </>
          )}
        </Form>
        <TextDivider label="Or" />
        <Form {...signupModel} width="300px" p={3}>
          {({ form, state }) => (
            <>
              <Flex variant="header" mb={4}>
                <Message id="app.section.signup" />
              </Flex>
              {form.username}
              {form.password}
              {form.passwordConfirm}
              {form.captchaValue}
              <Flex mb={3} justifyContent="center">
                <Box dangerouslySetInnerHTML={{ __html: state.captchaImage }} />
              </Flex>
              {form.subscription}
              {form.privacy}
              <Button
                disabled={!state.privacy}
                width={1}
                variant="accent"
                mt={3}
              >
                <FormattedMessage id="signup.btn.signup" />
              </Button>
            </>
          )}
        </Form>
      </Box>
    </Container>
  </Page>
)

export default Login
