import React from 'react'
import { Flex, Text } from 'rebass'
import { FaInbox } from 'react-icons/fa'

export default props => (
  <Flex
    p={3}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width={1}
    height="100%"
    bg="grey.1"
    color="grey.3"
    {...props}
  >
    <FaInbox fontSize={48} />
    <Text>No Data</Text>
  </Flex>
)
