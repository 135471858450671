import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import { BREADCRUMB_SET, ALERT_ADD, TOKEN_SET } from 'constants/actionType'
import TextInput from 'components/TextInput'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}) =>
  initializeState({
    password: value.password || '',
    newPassword: value.newPassword || '',
    newPasswordConfirm: value.newPasswordConfirm || ''
  })

const validation = {
  password: [{ type: 'required', message: 'error.required' }],
  newPassword: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] }
  ],
  newPasswordConfirm: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] },
    {
      type: 'fieldEqual',
      name: 'newPassword',
      message: 'account.error.passNotMatch'
    }
  ]
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    password: (
      <TextInput
        type="password"
        placeholder="account.field.password"
        value={state.password}
        onChange={onTextChange('password')}
        errMsg={state.__error__.password}
      />
    ),
    newPassword: (
      <TextInput
        type="password"
        placeholder="account.field.newPassword"
        value={state.newPassword}
        onChange={onTextChange('newPassword')}
        errMsg={state.__error__.newPassword}
      />
    ),
    newPasswordConfirm: (
      <TextInput
        type="password"
        placeholder="account.field.newPasswordConfirm"
        value={state.newPasswordConfirm}
        onChange={onTextChange('newPasswordConfirm')}
        errMsg={state.__error__.newPasswordConfirm}
      />
    )
  }
}

export const handlers = ({ state, setState, session, app }) => ({
  handleLoad: () => {
    setBreadcrumb({ app })
  },
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) {
      return
    }

    const { password, newPassword } = state
    const variables = { password, newPassword }
    const query = `
      mutation($password: String!, $newPassword: String!) {
        editUserPass(password: $password, newPassword: $newPassword) 
      }
    `
    const [ok] = await request({ query, variables }, { session, app })
    if (!ok) {
      return
    }

    app.dispatch({
      type: TOKEN_SET,
      token: { accessToken: '', refreshToken: '' }
    })
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'account.success.editPassword' }
    })
  }
})

function setBreadcrumb({ app }) {
  const items = [
    { label: 'Home', url: '/' },
    { label: <FormattedMessage id="account.password.title" /> }
  ]

  app.dispatch({ type: BREADCRUMB_SET, items })
}
