import { CART_CLEAR, TOKEN_SET } from 'constants/actionType'
import { request } from 'utilities/requestUtil'

export const onLoad = async ({ history, session, app, transNo }) => {
  const variables = { transNo }
  const query = `
    mutation($transNo: String) {
      confirmPayment(transNo: $transNo) {
        accessToken
        refreshToken
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return

  app.dispatch({ type: TOKEN_SET, token: data.confirmPayment })
  app.dispatch({ type: CART_CLEAR })
  history.push('/member/order')
}
