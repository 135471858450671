import React, { useState, useEffect, useContext } from 'react'
import { Box, Flex, Text, Button } from 'rebass'
import { onLoad, deleteAddress, setDefaultAddress } from 'models/address'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Message from 'components/Message'
import AddressModal from 'views/AddressModal'
import {
  MdAdd,
  MdDelete,
  MdStar,
  MdPerson,
  MdPhone,
  MdLocationOn
} from 'react-icons/md'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    onLoad({ setState, app, session })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = item => {
    setState([...state, item])
    setOpen(false)
  }

  const renderAddresses = () =>
    state.map(item => (
      <Flex
        key={item.id}
        flexDirection="column"
        bg="primary.0"
        width={1}
        sx={{
          borderRadius: '10px',
          boxShadow: 'small'
        }}
      >
        <Box flex={1} py={2} px={3} color="grey.5">
          <Flex alignItems="center" my={2}>
            <MdPerson />
            <Text ml={2}>{item.name}</Text>
          </Flex>
          {item.phone && (
            <Flex alignItems="center" my={2}>
              <MdPhone />
              <Text ml={2}>{item.phone}</Text>
            </Flex>
          )}
          <Flex my={2}>
            <Box pt={1}>
              <MdLocationOn />
            </Box>
            <Box ml={2}>
              <Text>{`
                ${item.zipcode || ''}
                ${item.city || ''}
                ${item.district || ''}
              `}</Text>
              <Text>{item.address1}</Text>
              <Text>{item.address2}</Text>
            </Box>
          </Flex>
        </Box>
        <Flex
          justifyContent="space-between"
          py={2}
          px={3}
          bg="primary.2"
          color="grey.0"
          sx={{
            borderRadius: '0 0 10px 10px',
            boxShadow: 'small'
          }}
        >
          <Button
            variant="card"
            onClick={() =>
              setDefaultAddress({ id: item.id, app, session, state, setState })
            }
          >
            <Flex alignItems="center">
              <MdStar color={item.default ? 'orange' : null} />
              <Message ml={1} mr={2} id="account.btn.defaultAddress" />
            </Flex>
          </Button>
          <Button
            variant="card"
            onClick={() =>
              deleteAddress({ id: item.id, app, session, state, setState })
            }
          >
            <Flex alignItems="center">
              <MdDelete />
              <Message ml={1} mr={2} id="btn.delete" />
            </Flex>
          </Button>
        </Flex>
      </Flex>
    ))

  return (
    <>
      <Box
        p={3}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
          justifyItems: 'center',
          gridGap: 3
        }}
      >
        {renderAddresses()}
        <Flex
          p={2}
          width={1}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg="grey.0"
          color="primary.2"
          sx={{
            borderWidth: '5px',
            borderStyle: 'dashed',
            borderColor: 'primary.0',
            borderRadius: '10px',
            cursor: 'pointer'
          }}
          onClick={() => setOpen(true)}
        >
          <MdAdd size="48px" />
          <Message id="btn.add" />
        </Flex>
      </Box>
      <AddressModal
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  )
}
