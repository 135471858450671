import { request } from 'utilities/requestUtil'

export const onLoad = async ({ setState, app, session }) => {
  const data = await getData({ app, session })
  const { addresses = [] } = data.extra || {}
  setState(addresses)
}

const getData = async ({ app, session }) => {
  const query = `
    query {
      user {
        id
        username
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query }, { session, app })
  if (!ok) return null

  return data.user
}

export const deleteAddress = async ({ id, state, setState, app, session }) => {
  const variables = { id }
  const query = `
    mutation ($id: ID!) {
      deleteAddress(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return null

  const addressIdx = state.findIndex(item => item.id === id)
  const addresses = [...state]
  addresses.splice(addressIdx, 1)
  setState(addresses)
}

export const setDefaultAddress = async ({
  id,
  state,
  setState,
  app,
  session
}) => {
  const variables = { id }
  const query = `
    mutation ($id: ID!) {
      setDefaultAddress(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return null

  setState(state.map(item => ({ ...item, default: item.id === id })))
}
