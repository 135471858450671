import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from 'rebass'
import { MemberRoute } from 'configs/routes'
import AppContext from 'contexts/AppContext'
import Message from 'components/Message'
import Link from 'components/Link'
import Container from 'components/Container'
import Breadcrumb from 'components/Breadcrumb'

export default () => {
  const { state } = useContext(AppContext)
  const history = useHistory()
  const { accessToken } = state.token

  useEffect(() => {
    if (!accessToken) {
      history.push('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  return (
    <Container minHeight="calc(100vh - 212px)" bg="grey.1">
      <Breadcrumb mb={3} px={[3, 3, 3, 0]} items={state.breadcrumbs} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 3fr'],
          justifyItems: 'center',
          gridGap: 4
        }}
      >
        <Box width={1} bg="grey.0">
          <Message
            id="account.title"
            width={1}
            mb={3}
            p={2}
            bg="primary.2"
            color="grey.0"
            fontSize={2}
            textAlign="center"
          />
          <Link px={3} py={2} href="/member/basic">
            <Message id="account.basic.title" />
          </Link>
          <Link px={3} py={2} href="/member/password">
            <Message id="account.password.title" />
          </Link>
          <Link px={3} py={2} href="/member/order">
            <Message id="orderList.title" />
          </Link>
          <Link px={3} py={2} href="/member/support">
            <Message id="support.title" />
          </Link>
        </Box>
        <Box width={1}>
          <MemberRoute />
        </Box>
      </Box>
    </Container>
  )
}
