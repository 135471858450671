import React from 'react'
import { Flex, Box } from 'rebass'
import Message from 'components/Message'
import Divider from 'components/Divider'

const Heading = ({ title, actions, titleProps, actionProps }) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
  >
    {title && (
      <Box
        display="inline-flex"
        p={3}
        justifyContent="center"
        {...titleProps}
      >
        <Message id={title} />
      </Box>
    )}
    {actions && (
      <Box
        display="inline-flex"
        justifyContent="center"
        // alignSelf="flex-end"
        mx={3}
        {...actionProps}
      >
        {actions}
      </Box>
    )}
  </Flex>
)

export default ({
  title,
  actions,
  children,
  titleProps,
  actionProps,
  contentProps,
  ...props
}) => (
  <Box {...props}>
    {(title || actions) && (
      <>
        <Heading
          title={title}
          titleProps={titleProps}
          actions={actions}
          actionProps={actionProps}
        />
        <Divider bg="grey.2" />
      </>
    )}
    <Box p={3} {...contentProps}>
      {children}
    </Box>
  </Box>
)
