import React, { useState, useEffect } from 'react'
import { Flex, Box, Button } from 'rebass'

export default ({ value, options, sx, onClick, ...props }) => {
  const [activeId, setActiveId] = useState(value || options[0].id)

  useEffect(() => {
    if (value) {
      setActiveId(value)
    }
  }, [value])

  const activeContent = options.find((item) => item.id === activeId)

  return (
    <Box {...props}>
      <Flex
        mb={2}
        sx={{
          overflow: 'hidden',
          borderBottomWidth: '2px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'accent.2',
        }}
      >
        {options.map((item) => (
          <Button
            key={item.id}
            variant={item.id === activeId ? 'tabLinkActive' : 'tabLink'}
            onClick={() => {
              setActiveId(item.id)
              onClick(item.id)
            }}
          >
            {item.label}
          </Button>
        ))}
      </Flex>
      {activeContent.render()}
    </Box>
  )
}
