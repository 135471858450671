import React, { useContext } from 'react'
import { Link, Flex, Text } from 'rebass'
import AppContext from 'contexts/AppContext'
import Image from 'components/Image'
import Message from 'components/Message'
import Container from 'components/Container'
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'
import Logo from 'assets/svg/Logo'

export default () => {
  const { state } = useContext(AppContext)
  const { estore } = state.merchant || {}
  const {
    name,
    footerLogoUrl,
    // companyName,
    companyPhone,
    companyEmail,
    companyAddress
  } = estore
  return (
    <>
      <Container bg="primary.2">
        <Flex flexWrap="wrap" color="grey.0">
          <Flex
            px={4}
            mb={[3, 0]}
            flexDirection="column"
            flex={['100%', '50%', 1, 1]}
            justifyContent="center"
            alignItems="center"
          >
            {footerLogoUrl ? (
              <Image
                // width="48px"
                height="64px"
                src={footerLogoUrl}
                sx={{ objectFit: 'contain' }}
              />
            ) : (
              <>
                <Logo color="#fff" width="48px" height="48px" />
                {name ? (
                  <Text mt={2}>{name}</Text>
                ) : (
                  <Message mt={2} id="app.name" />
                )}
              </>
            )}
          </Flex>
          <Flex px={4} flexDirection="column" flex={['100%', '50%', 1, 1]}>
            {companyPhone && (
              <Flex my={2} alignItems="center">
                <FaPhone />
                <Text ml={3}>{companyPhone}</Text>
              </Flex>
            )}
            {companyEmail && (
              <Flex my={2} alignItems="center">
                <FaEnvelope />
                <Text ml={3}>{companyEmail}</Text>
              </Flex>
            )}
            {companyAddress && (
              <Flex my={2} alignItems="center">
                <FaMapMarkerAlt />
                <Text ml={3}>{companyAddress}</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Container>
      <Container bg="grey.5" contentProps={{ py: 0 }}>
        <Flex
          py={2}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          flex={['100%', 1]}
          color="grey.4"
        >
          <Link
            color="grey.3"
            // href="https://shop.mr-14.com"
            // target="_blank"
            sx={{ textDecoration: 'none' }}
          >
            本網站由慎康企業股份有限公司經營 統一編號：30955994
          </Link>
        </Flex>
      </Container>
    </>
  )
}
