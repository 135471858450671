import React, { useState, useEffect, useContext } from 'react'
import { Flex, Button } from 'rebass'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Message from 'components/Message'
import Link from 'components/Link'
import Table from 'components/Table'
import Page from 'components/Page'
import { onLoad, setBreadcrumb } from 'models/supportList'
import SupportModal from 'views/SupportModal'
import { MdAdd } from 'react-icons/md'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    onLoad({ setState, app, session })
    setBreadcrumb({ app })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="support.title">
      <Flex mb={3}>
        <Button
          display="flex"
          alignItems="center"
          variant="default"
          onClick={() => setOpen(true)}
        >
          <MdAdd size="24px" />
          <Message id="btn.add" ml={1} />
        </Button>
      </Flex>
      <Table
        width={1}
        bg="grey.0"
        columns={[
          {
            id: 'subject',
            label: 'support.field.subject',
            render: ({ row }) => (
              <Link color="secondary.1" href={`/member/support/${row.id}`}>
                {row.subject}
              </Link>
            )
          },
          {
            id: 'type',
            label: 'support.field.type',
            render: ({ row }) => <Message id={`support.type.${row.type}`} />
          },
          {
            id: 'status',
            label: 'support.field.status',
            render: ({ row }) => <Message id={`support.status.${row.status}`} />
          }
        ]}
        rows={state}
      />
      <SupportModal
        open={open}
        onClose={() => setOpen(false)}
        refreshList={() => onLoad({ setState, app, session })}
      />
    </Page>
  )
}
