export const ALERT_ADD = 'ALERT_ADD'
export const ALERT_REMOVE = 'ALERT_REMOVE'
export const LOADING_SHOW = 'LOADING_SHOW'
export const LOADING_HIDE = 'LOADING_HIDE'
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE'
export const CARTDRAWER_TOGGLE = 'CARTDRAWER_TOGGLE'
export const CART_ADD = 'CART_ADD'
export const CART_SET = 'CART_SET'
export const CART_EDIT = 'CART_EDIT'
export const CART_REMOVE = 'CART_REMOVE'
export const CART_CLEAR = 'CART_CLEAR'
export const CHECKOUT_SET = 'CHECKOUT_SET'
export const LOGIN_MODAL_TOGGLE = 'LOGIN_MODAL_TOGGLE'
export const SIGNUP_MODAL_TOGGLE = 'SIGNUP_MODAL_TOGGLE'
export const LOGIN = 'LOGIN'
export const TOKEN_SET = 'TOKEN_SET'
export const TOKEN_REMOVE = 'TOKEN_REMOVE'
export const MERCHANT_SET = 'MERCHANT_SET'
export const STAFF_SET = 'STAFF_SET'
export const USER_INFO_SET = 'USER_INFO_SET'
export const NAV_SET = 'NAV_SET'
export const BREADCRUMB_SET = 'BREADCRUMB_SET'
export const CATEGORY_SET = 'CATEGORY_SET'
export const CATEGORY_ID_SET = 'CATEGORY_ID_SET'
