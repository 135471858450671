import React from 'react'
import { ALERT_ADD } from 'constants/actionType'
import TextInput from 'components/TextInput'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}) =>
  initializeState({
    username: value.username || '',
    captchaKey: value.captchaKey || '',
    captchaValue: '',
    captchaImage: value.captchaImage || ''
  })

const validation = {
  username: [{ type: 'required', message: 'error.required' }],
  captchaValue: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    username: (
      <TextInput
        placeholder="login.field.username"
        value={state.username}
        autoComplete="on"
        onChange={onTextChange('username')}
        errMsg={state.__error__.username}
      />
    ),
    captchaValue: (
      <TextInput
        placeholder="signup.field.captchaValue"
        value={state.captchaValue}
        onChange={onTextChange('captchaValue')}
        errMsg={state.__error__.captchaValue}
      />
    )
  }
}

export const handlers = ({
  state,
  setState,
  app,
  session,
  history,
  actions
}) => ({
  handleLoad: async () => {
    const { captchaKey, captchaImage } = await createCaptcha({ session })
    setState(initialState({ captchaKey, captchaImage }))
  },
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) {
      return
    }

    const merchantId = app.state.merchant.id
    const { username, captchaKey, captchaValue } = state
    const variables = { merchantId, username, captchaKey, captchaValue }
    const query = `
      mutation($merchantId: ID!, $username: String!, $captchaKey: String!, $captchaValue: String!) {
        forgetUserPass(merchantId: $merchantId, username: $username, captchaKey: $captchaKey, captchaValue: $captchaValue)
      }
    `
    const [ok] = await request({ query, variables }, { session })
    const captchaData = await createCaptcha({ session })
    setState(
      initialState({
        username,
        captchaKey: captchaData.captchaKey,
        captchaImage: captchaData.captchaImage
      })
    )
    if (!ok) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'login.messsage.forget' }
    })
    setState(initialState())
  }
})

async function createCaptcha({ session }) {
  const query = `
      mutation {
        createCaptcha {
          key
          data
        }
      }
    `
  const [ok, data] = await request({ query }, { session })
  if (!ok) return {}

  const { createCaptcha } = data
  return { captchaKey: createCaptcha.key, captchaImage: createCaptcha.data }
}
