import React, { useEffect, useState, useContext } from 'react'
import { Flex, Box } from 'rebass'
import SessionContext from 'contexts/SessionContext'
import Message from 'components/Message'
import Page from 'components/Page'
import { activateUser } from 'models/activate'
import {
  STATUS_INACTIVE,
  STATUS_ACTIVE,
  STATUS_PENDING
} from 'constants/status'
import LoadingIcon from 'components/LoadingIcon'
import { MdErrorOutline, MdCheckCircle } from 'react-icons/md'

export default () => {
  const session = useContext(SessionContext)
  const [state, setState] = useState(STATUS_ACTIVE)
  const url = new URL(window.location.href)
  const id = url.searchParams.get('id')
  const code = url.searchParams.get('code')

  useEffect(() => {
    activateUser({ setState, session, id, code })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="activate.title">
      <Flex
        minHeight="calc(100vh - 212px)"
        bg="grey.0"
        color="grey.5"
        p={4}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {state === STATUS_PENDING && (
          <LoadingIcon>
            <Message id="activate.pending" />
          </LoadingIcon>
        )}
        {state === STATUS_INACTIVE && (
          <Flex flexDirection="column" alignItems="center" color="error.1">
            <MdErrorOutline size="64px" />
            <Message id="activate.failure" />
          </Flex>
        )}
        {state === STATUS_ACTIVE && (
          <>
            <Flex flexDirection="column" alignItems="center" color="success.1">
              <MdCheckCircle size="64px" />
              <Message id="activate.success" />
            </Flex>
            <Box mt={4}>
              <Message id="activate.welcome" color="grey.5" />
              <Message id="activate.login" color="grey.4" />
            </Box>
          </>
        )}
      </Flex>
    </Page>
  )
}
