import { request } from 'utilities/requestUtil'
import { BREADCRUMB_SET, CART_ADD, ALERT_ADD } from 'constants/actionType'

export const onLoad = async ({ setState, app, session, id }) => {
  const data = await getData({ app, session, id })
  const product = data.productShelf
  if (!product) return

  const variants = data.productVariants.map(({ id }) => id)
  const balances = variants ? await getBalances({ app, session, variants }) : []

  setState({
    product,
    productVariants: data.productVariants,
    balances,
    relatedProducts: data.relatedProducts,
    backorder: product.extra ? product.extra.backorder : false,
  })
}

export const setBreadcrumb = ({ app, product }) => {
  if (!product.id) return

  const { categories } = app.state
  const category = categories.find((item) => item.id === product.categoryId)
  const { name, id } = category || {}
  const items = [
    { label: 'Home', url: '/' },
    { label: name, url: `/category?id=${id}` },
    { label: product.spu },
  ]

  app.dispatch({ type: BREADCRUMB_SET, items })
}

export const addProduct = ({
  app,
  session,
  product,
  variant,
  quantity,
  backorder = false,
}) => {
  const item = {
    id: variant.id,
    merchantId: product.merchantId,
    productId: product.id,
    spu: product.spu,
    sku: variant.sku,
    options: variant.options,
    price: variant.price,
    // shippingFee: product.shippingFee,
    // shippingWaiverFee: product.shippingWaiverFee,
    image: variant.image || {},
    quantity,
    backorder,
  }

  app.dispatch({ type: CART_ADD, item })

  session.dispatch({
    type: ALERT_ADD,
    item: { type: 'success', message: 'product.success.addToCart' },
  })
}

async function getData({ app, session, id }) {
  const merchantId = app.state.merchant.id
  const variables = { id, merchantId }
  const query = `
    query($merchantId: ID!, $id: ID!) {
      productShelf(merchantId: $merchantId, productId: $id) {
        id
        merchantId
        categoryId
        spu
        specHtml
        descHtml
        images {
          src
          alt
          variantId
        }
        options {
          name
          value
        }
        priceRange
        extra
        status
      }
      productVariants(productId: $id) {
        id
        sku
        barcode
        price
        postedPrice
        options {
          name
          value
        }
        image {
          src
          alt
        }
      }
      relatedProducts(productId: $id) {
        id
        categoryId
        spu
        primaryImage {
          src
          alt
        }
        priceRange
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return {}

  return data
}

async function getBalances({ app, session, variants }) {
  const merchantId = app.state.merchant.id
  const variables = { variants, merchantId }
  const query = `
    query($merchantId: ID!, $variants: [ID!]!) {
      userInventoryBalances(merchantId: $merchantId, variants: $variants) {
        productVariantId
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return []

  return data.userInventoryBalances
}
