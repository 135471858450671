import React from 'react'
import { FormattedMessage } from 'react-intl'
import { request } from 'utilities/requestUtil'
import { BREADCRUMB_SET } from 'constants/actionType'
import { removeCheckoutItems } from 'models/payment'

export const onLoad = async ({ setState, app, session, tradeId }) => {
  const merchantId = app.state.merchant.id
  const variables = { merchantId }
  const query = `
    query($merchantId: ID!) {
      userTickets(merchantId: $merchantId) {
        id
        ticketType
        price
        amountBilled
        amountAcquired
        transDate
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  setState(data.userTickets)

  if (data.userTickets.some((item) => item.id === tradeId)) {
    removeCheckoutItems({ app })
  }
}

export const setBreadcrumb = ({ app }) => {
  const items = [
    { label: 'Home', url: '/' },
    { label: <FormattedMessage id="orderList.title" /> },
  ]

  app.dispatch({ type: BREADCRUMB_SET, items })
}
