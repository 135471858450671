import React, { useState, useEffect, useContext } from 'react'
import { Box, Flex, Text, Button } from 'rebass'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Image from 'components/Image'
import Link from 'components/Link'
import Message from 'components/Message'
import ImageCard from 'components/ImageCard'
import Page from 'components/Page'
import Container from 'components/Container'
import Breadcrumb from 'components/Breadcrumb'
import Carousel from 'nuka-carousel'
import {
  onLoad,
  setBreadcrumb,
  findNodeIdx,
  findNode,
  findChildIds,
} from 'models/category'
import { BREADCRUMB_SET } from 'constants/actionType'
import { FaInbox } from 'react-icons/fa'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState({ categories: [], products: [] })
  const { categories, categoryTree, categoryId } = app.state
  const category = getCategory(categories, categoryId)
  const categoryImages = category ? category.images : null
  const { merchant } = session.state
  const url = new URL(window.location.href)
  const key = url.searchParams.get('key')

  useEffect(() => {
    onLoad({ setState, session, app, key })
    app.dispatch({ type: BREADCRUMB_SET, items: [] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant, key])

  useEffect(() => {
    setBreadcrumb({ app, categories, categoryId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant, categoryId, categories])

  const renderCategoryTree = (items) => {
    if (!items || items.length === 0) return null
    return (
      <Box mb={3}>
        {items
          .filter((item) => item.isPublic)
          .map((item) => {
            const isRoot = item.parentCategoryId === '0'
            return (
              <Box key={item.id}>
                <Link
                  px={3}
                  pb={2}
                  color={
                    item.id === categoryId
                      ? 'accent.1'
                      : isRoot
                      ? 'grey.4'
                      : 'grey.5'
                  }
                  onClick={({ history }) =>
                    history.push(`/category?id=${item.id}`)
                  }
                >
                  {item.name}
                </Link>
                {renderCategoryTree(item.children)}
              </Box>
            )
          })}
      </Box>
    )
  }

  const renderCategoryImages = () => (
    <Box mb={3}>
      <Carousel
        autoplay={categoryImages.length > 1}
        swiping={categoryImages.length > 1}
        wrapAround
        pauseOnHover
        withoutControls={categoryImages.length <= 1}
        renderCenterLeftControls={({ previousSlide }) => (
          <Button ml={2} height="48px" variant="glass" onClick={previousSlide}>
            <MdNavigateBefore size="3em" />
          </Button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <Button mr={2} height="48px" variant="glass" onClick={nextSlide}>
            <MdNavigateNext size="3em" />
          </Button>
        )}
      >
        {categoryImages.map((item) => (
          <Image
            key={item.id}
            ratio={300 / 794}
            height={[140, 300]}
            src={item.imageUrl}
            url={item.linkUrl}
          />
        ))}
      </Carousel>
    </Box>
  )

  const node = findNode(categoryTree, categoryId)
  const products = state.products
    .filter((item) => {
      if (!node) {
        const hiddenCategoryIds = categories
          .filter((c) => !c.isPublic)
          .map((c) => c.id)
        if (!hiddenCategoryIds) return true
        return !hiddenCategoryIds.includes(item.categoryId)
      }

      const childIds = findChildIds(node)
      return childIds.includes(item.categoryId)
    })
    .map(({ id, spu, primaryImage, priceRange }) => (
      <Link
        key={id}
        p={[2, 0]}
        variant="default"
        width={1}
        href={`/product/${id}`}
      >
        <ImageCard
          ratio={1}
          height="100%"
          src={primaryImage ? primaryImage.src : null}
          sx={{
            transition:
              'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), transform 0.2s cubic-bezier(0.4, 0, 1, 1)',
            ':hover': {
              transform: 'translateY(-4px)',
              boxShadow: '0 8px 14px rgba(0, 0, 0, .125)',
            },
          }}
        >
          <Text width={1} px={3} py={3}>
            {spu}
          </Text>
          <Text px={3} pb={3} alignSelf="flex-start" color="accent.2">
            {priceRange}
          </Text>
          {/* <Text pb={3} px={3} alignSelf="flex-end" fontSize={0} color="grey.4">
            已出售 0
          </Text> */}
        </ImageCard>
      </Link>
    ))

  let activeItem = []
  let items = categoryTree ? [...categoryTree] : []
  const activeIdx = findNodeIdx(items, categoryId)

  if (activeIdx !== -1) {
    activeItem = [items[activeIdx]]
    items.splice(activeIdx, 1)
  }

  return (
    <Page title="category.title">
      <Container minHeight="calc(100vh - 212px)" bg="grey.1">
        <Breadcrumb mb={3} px={[3, 3, 3, 0]} items={app.state.breadcrumbs} />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 2fr', '1fr 4fr'],
            justifyItems: 'center',
            gridGap: 3,
          }}
        >
          <Box width={1} display={['none', 'block']}>
            <Message
              id="category.title"
              width={1}
              mb={3}
              p={2}
              bg="primary.2"
              color="grey.0"
              fontSize={2}
              textAlign="center"
            />
            {renderCategoryTree(activeItem)}
            {renderCategoryTree(items)}
          </Box>
          <Box width={1}>
            {categoryImages && renderCategoryImages()}
            {products.length === 0 ? (
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                color="grey.4"
                bg="grey.0"
                width={1}
                p={3}
              >
                <FaInbox fontSize={48} />
                <Text>No Data</Text>
              </Flex>
            ) : (
              <Box
                width={1}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: [
                    '1fr 1fr',
                    '1fr 1fr 1fr',
                    '1fr 1fr 1fr 1fr',
                  ],
                  justifyItems: 'center',
                  gridRowGap: 3,
                  gridColumnGap: [0, 2],
                }}
              >
                {products}
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Page>
  )
}

const getCategory = (categories, categoryId) => {
  if (!categories || !categoryId) return {}
  return categories.find((item) => item.id === categoryId)
}
