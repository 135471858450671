import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box, Flex, Button, Text, Link } from 'rebass'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import { initialState, fields, handlers } from 'models/checkoutProduct'
import Breadcrumb from 'components/Breadcrumb'
import Message from 'components/Message'
import Container from 'components/Container'
import Page from 'components/Page'
import TabLink from 'components/TabLink'
import CheckoutShippingModal from './CheckoutShippingModal'
import CheckoutDiscountModal from './CheckoutDiscountModal'
import Chip from 'components/Chip'
import Checkbox from 'components/Checkbox'
import { MdCreditCard, MdLocalShipping, MdWarning } from 'react-icons/md'
import Definition from 'components/Definition'
import Center from 'components/Center'

export default () => {
  const url = new URL(window.location.href)
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, app, message))
  const [shippingOpen, setShippingOpen] = useState(false)
  const [shippingIdx, setShippingIdx] = useState(-1)
  const [discountOpen, setDiscountOpen] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const cvsCartItemCount = state.cvsCartItems ? state.cvsCartItems.length : 0
  const d2dCartItemCount = state.d2dCartItems ? state.d2dCartItems.length : 0

  const handleShippingOpen = (index) => {
    setShippingOpen(true)
    setShippingIdx(index)
  }

  const handleShippingClose = () => {
    setShippingOpen(false)
    setShippingIdx(-1)
  }

  const form = fields({
    state,
    setState,
    message,
    actions: { handleShippingOpen },
  })
  const action = handlers({
    state,
    setState,
    session,
    app,
    message,
    url,
    history,
    actions: { setDisableSubmit },
  })

  useEffect(() => {
    if (action.handleLoad) {
      action.handleLoad()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderListFooter = () => {
    const checkoutCount = action.getCheckoutCount()
    const shippingConfig = action.getShippingConfig()
    const { shippingFee, shippingWaiverFee } = shippingConfig
    const shippingType = state.isCvs ? 'cvs' : 'd2d'
    return (
      <Flex mt={4} p={3} flexDirection="column" bg="grey.0">
        <Flex mb={2} alignItems="flex-start">
          <Box color="warning.0" fontSize={3} mr={2} lineHeight={1}>
            <MdWarning />
          </Box>
          <Message color="grey.4" id="checkout.message.paymentNote" />
        </Flex>
        <Flex mb={2} alignItems="flex-start">
          <Box color="warning.0" fontSize={3} mr={2} lineHeight={1}>
            <MdWarning />
          </Box>
          <Message color="grey.4" id="checkout.message.shippingNote" />
        </Flex>
        {shippingType === 'd2d' && shippingWaiverFee > 0 && shippingFee > 0 && (
          <Flex mb={3} alignItems="flex-start">
            <Box color="warning.0" fontSize={3} mr={2} lineHeight={1}>
              <MdWarning />
            </Box>
            <Message
              color="grey.4"
              id={`checkout.message.d2dShippingFee`}
              values={{ shippingWaiverFee, shippingFee }}
            />
          </Flex>
        )}
        {shippingType === 'cvs' && shippingWaiverFee > 0 && (
          <Flex mb={3} alignItems="flex-start">
            <Box color="warning.0" fontSize={3} mr={2} lineHeight={1}>
              <MdWarning />
            </Box>
            <Message
              color="grey.4"
              id={`checkout.message.cvsShippingFee`}
              values={{ shippingWaiverFee }}
            />
          </Flex>
        )}
        <Flex
          mt={2}
          flexDirection={['column', 'row']}
          justifyContent="flex-end"
        >
          {/* <Flex alignItems="center">
          {renderHeaderCheckbox({ state, setState })}
          <Message id="checkout.btn.selectAll" />
        </Flex> */}
          <Flex justifyContent="flex-end" flexWrap="wrap">
            <Flex
              mr={3}
              justifyContent="space-between"
              alignItems="center"
              width={[1, 'auto']}
            >
              <Message mr={2} id="checkout.label.productAmount" />
              <Center>
                <Text color="accent.1" fontSize={1}>
                  NT.
                </Text>
                <Text color="accent.1" fontSize={4}>
                  {action.getProductAmount()}
                </Text>
              </Center>
            </Flex>
            <Flex
              mr={3}
              justifyContent="space-between"
              alignItems="center"
              width={[1, 'auto']}
            >
              <Message mr={2} id="checkout.label.discountAmount" />
              <Center>
                <Text color="accent.1" fontSize={1}>
                  NT.
                </Text>
                <Text color="accent.1" fontSize={4}>
                  {action.getDiscountAmount()}
                </Text>
              </Center>
            </Flex>
            <Flex
              mr={3}
              justifyContent="space-between"
              alignItems="center"
              width={[1, 'auto']}
            >
              <Message mr={2} id="cart.total.price" />
              <Center>
                <Text color="accent.1" fontSize={1}>
                  NT.
                </Text>
                <Text color="accent.1" fontSize={4}>
                  {action.getInvoiceAmount()}
                </Text>
              </Center>
            </Flex>
            <Button
              width={[1, 'auto']}
              px={4}
              py={2}
              mt={[2, 0]}
              fontSize={3}
              variant="accent"
              disabled={disableSubmit || checkoutCount === 0}
              onClick={action.confirmProduct}
            >
              <Message mr={1} sx={{ lineHeight: 1 }} id="cart.btn.submit" />
              <Text>{`(${checkoutCount})`}</Text>
            </Button>
          </Flex>
        </Flex>
      </Flex>
    )
  }

  const renderDiscount = () => {
    const title = (
      <>
        <Message id="checkout.title.discount" fontSize={2} />
        <Link variant="secondary" onClick={() => setDiscountOpen(true)}>
          <Message id="checkout.btn.discount" />
        </Link>
      </>
    )
    let content = (
      <Flex flex={1} justifyContent="center">
        <Message color="grey.3" id="checkout.message.noData" />
      </Flex>
    )

    const discountName = state.isCvs ? 'cvsDiscounts' : 'd2dDiscounts'
    const discounts = state[discountName]

    if (discounts.length > 0) {
      content = discounts.map((item) => (
        <Chip
          mx={1}
          my={1}
          key={item.code}
          text={item.code}
          onClick={action.removeDiscount}
        />
      ))
    }

    return <Section title={title} content={content} />
  }

  const renderUserInfo = () => {
    const title = <Message id="checkout.title.userInfo" fontSize={2} />
    const content = (
      <Box width={1}>
        {form.name}
        {form.phone}
        <Flex>
          {form.zipcode}
          {form.city}
          {form.district}
        </Flex>
        {form.address}
      </Box>
    )
    return <Section title={title} content={content} />
  }

  const renderCvsProvider = () => {
    const merchantId = 3136507
    const { shippingProvider = {}, shippingCollect = {} } = state
    const content = (
      <form
        id="cvs-map"
        action="https://logistics.ecpay.com.tw/Express/map"
        onSubmit={action.handleCvsMap}
        style={{ width: '100%' }}
      >
        <input type="hidden" name="MerchantID" value={merchantId} />
        <input type="hidden" name="LogisticsType" value="CVS" />
        <input
          type="hidden"
          name="LogisticsSubType"
          value={shippingProvider.value + 'C2C'}
        />
        <input
          type="hidden"
          name="IsCollection"
          value={shippingCollect.value}
        />
        <input
          type="hidden"
          name="ServerReplyURL"
          value={`${process.env.REACT_APP_API_ROOT}/ecpay/cvsmap`}
        />
        <Flex mr={2} alignItems="center" width={1}>
          {form.shippingProvider}
          {/* <Box flex={1}>{form.shippingCollect}</Box> */}
        </Flex>
        <Button p={0} mt={3} type="submit" variant="plain">
          <Message id="cart.btn.search" />
        </Button>
        <Definition
          show={!!state.cvsName}
          direction="row"
          label="checkout.field.cvsName"
          value={state.cvsName}
        />
        <Definition
          show={!!state.cvsAddress}
          direction="row"
          label="checkout.field.cvsAddress"
          value={state.cvsAddress}
        />
        <Definition
          show={!!state.cvsTelephone}
          direction="row"
          label="checkout.field.cvsTelephone"
          value={state.cvsTelephone}
        />
      </form>
    )
    const title = <Message id="checkout.title.chooseCvs" fontSize={2} />
    return <Section title={title} content={content} />
  }

  const renderRecipient = (showAddress) => {
    const title = (
      <Flex alignItems="center">
        <Message mr={3} id="checkout.title.recipient" fontSize={2} />
        <Checkbox
          label="checkout.field.sameAsOrderer"
          checked={state.sameAsUser}
          onChange={() => {
            setState({
              ...state,
              sameAsUser: !state.sameAsUser,
              recipientName: state.name,
              recipientPhone: state.phone,
              recipientZipcode: state.zipcode,
              recipientCity: state.city,
              recipientDistrict: state.district,
              recipientAddress: state.address,
            })
          }}
        />
      </Flex>
    )
    const content = (
      <Box width={1}>
        {form.recipientName}
        {form.recipientPhone}
        {showAddress && (
          <>
            <Flex>
              {form.recipientZipcode}
              {form.recipientCity}
              {form.recipientDistrict}
            </Flex>
            {form.recipientAddress}
          </>
        )}
      </Box>
    )
    return <Section title={title} content={content} />
  }

  const renderReceipt = () => {
    const title = <Message id="checkout.title.receipt" fontSize={2} />
    const isBusiness = state.receiptType.value === 'RECEIPT_BUSINESS'
    const content = (
      <Flex alignItems="center" width={1}>
        {form.receiptType}
        <Box flex={1} mx={2}>
          {isBusiness && form.receiptNo}
        </Box>
        <Box flex={1}>{isBusiness && form.receiptTitle}</Box>
      </Flex>
    )
    return <Section title={title} content={content} />
  }

  const renderPaymentMethod = ({ action, id, label, icon }) => {
    const { channelProviders = [] } = app.state.merchant
    const hasChannel = channelProviders.some((item) => item === id)
    if (!hasChannel) return null

    const isActive = state.paymentMethod === id
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        bg={isActive ? 'accent.1' : 'primary.0'}
        color={isActive ? 'grey.0' : 'grey.5'}
        px={3}
        py={2}
        mr={2}
        sx={{
          borderRadius: '10px',
          boxShadow: 'small',
          cursor: 'pointer',
        }}
        onClick={() => action.setPaymentMethod(id)}
      >
        {icon && (
          <Flex mr={2} fontSize={4}>
            {icon}
          </Flex>
        )}
        <Message fontSize={3} id={label} />
      </Flex>
    )
  }

  const renderPayment = () => {
    const { isCvs, shippingCollect = {} } = state
    const isCvsCollect = isCvs && shippingCollect.value === 'Y'

    const content = (
      <Flex width={1}>
        {isCvsCollect &&
          renderPaymentMethod({
            action,
            id: 'CASH',
            label: 'cart.payment.cash',
            icon: <MdLocalShipping />,
          })}
        {!isCvsCollect &&
          renderPaymentMethod({
            action,
            id: 'ECPAY',
            label: 'cart.payment.creditCard',
            icon: <MdCreditCard />,
          })}
        {!isCvsCollect &&
          renderPaymentMethod({
            action,
            id: 'LINEPAY',
            label: 'cart.payment.linePay',
          })}
      </Flex>
    )
    const title = <Message id="checkout.title.payment" fontSize={2} />
    return <Section title={title} content={content} />
  }

  const renderSubmit = () => {
    return (
      <Flex mt={4} p={3} flexDirection="column" bg="grey.0">
        <Flex
          mt={2}
          flexDirection={['column', 'row']}
          justifyContent="flex-end"
        >
          <Flex
            mr={3}
            justifyContent="space-between"
            alignItems="center"
            width={[1, 'auto']}
          >
            <Message mr={2} id="cart.shipping.fee" />
            <Center>
              <Text color="accent.1" fontSize={1}>
                NT.
              </Text>
              <Text color="accent.1" fontSize={4}>
                {action.getShippingFee()}
              </Text>
            </Center>
          </Flex>
          <Flex
            mr={3}
            justifyContent="space-between"
            alignItems="center"
            width={[1, 'auto']}
          >
            <Message mr={2} id="checkout.label.orderAmount" />
            <Center>
              <Text color="accent.1" fontSize={1}>
                NT.
              </Text>
              <Text color="accent.1" fontSize={4}>
                {action.getBillingAmount()}
              </Text>
            </Center>
          </Flex>
          <Button
            width={[1, 'auto']}
            mt={[2, 0]}
            px={4}
            py={2}
            fontSize={3}
            variant="accent"
            disabled={disableSubmit}
            onClick={action.handleSubmit}
          >
            <Message mr={1} sx={{ lineHeight: 1 }} id="checkout.btn.submit" />
          </Button>
        </Flex>
      </Flex>
    )
  }

  const renderCvs = () => {
    const { showCvsDetail } = state
    let detail = ''
    if (showCvsDetail) {
      detail = (
        <Box>
          {renderUserInfo()}
          {renderCvsProvider()}
          {renderRecipient(false)}
          {renderReceipt()}
          {renderPayment()}
          {renderSubmit()}
        </Box>
      )
    }
    return (
      <>
        <Box width={1} mb={4} sx={{ overflowX: 'auto' }}>
          {form.cvsProduct}
        </Box>
        {renderDiscount()}
        {renderListFooter()}
        {detail}
      </>
    )
  }

  const renderD2d = () => {
    const { showD2dDetail } = state
    let detail = ''
    if (showD2dDetail) {
      detail = (
        <>
          {renderUserInfo()}
          {renderRecipient(true)}
          {renderReceipt()}
          {renderPayment()}
          {renderSubmit()}
        </>
      )
    }
    return (
      <>
        <Box width={1} mb={4} sx={{ overflowX: 'auto' }}>
          {form.d2dProduct}
        </Box>
        {renderDiscount()}
        {renderListFooter()}
        {detail}
      </>
    )
  }

  return (
    <>
      <Page title="payment.title">
        <Container minHeight="calc(100vh - 212px)" bg="grey.1">
          <Breadcrumb mb={3} px={[3, 3, 3, 0]} items={app.state.breadcrumbs} />
          <TabLink
            onClick={(id) => {
              setState({
                ...state,
                isCvs: id === 'cvs',
                showCvsDetail: false,
                showD2dDetail: false,
              })
            }}
            value={state.isCvs ? 'cvs' : 'd2d'}
            options={[
              {
                id: 'd2d',
                label: (
                  <Flex alignItems="center">
                    <Message
                      mr={1}
                      sx={{ lineHeight: 1 }}
                      id="checkout.tab.d2d"
                    />
                    <Text>{`(${d2dCartItemCount})`}</Text>
                  </Flex>
                ),
                render: renderD2d,
              },
              {
                id: 'cvs',
                label: (
                  <Flex alignItems="center">
                    <Message
                      mr={1}
                      sx={{ lineHeight: 1 }}
                      id="checkout.tab.cvs"
                    />
                    <Text>{`(${cvsCartItemCount})`}</Text>
                  </Flex>
                ),
                render: renderCvs,
              },
            ]}
          />
        </Container>
      </Page>
      <CheckoutShippingModal
        value={{ isCvs: state.isCvs }}
        open={shippingOpen}
        action={{
          handleClose: handleShippingClose,
          handleSubmit: () => action.switchShippingType(shippingIdx),
        }}
      />
      <CheckoutDiscountModal
        open={discountOpen}
        action={{
          handleClose: () => setDiscountOpen(false),
          handleSubmit: (value) => action.addDiscount(value),
        }}
      />
    </>
  )
}

function Section({ title, content }) {
  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py={2}
        px={3}
        mt={4}
        bg="grey.0"
        sx={{
          borderBottomColor: 'grey.3',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
        }}
      >
        {title}
      </Flex>
      <Flex py={3} px={3} bg="grey.0">
        {content}
      </Flex>
    </>
  )
}
