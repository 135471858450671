export const isActive = (items, id) => {
  if (!items || !id) {
    return false
  }

  for (var item of items) {
    if (item.id === id || isActive(item.items, id)) {
      return true
    }
  }

  return false
}

export const cartesian = (a, b, ...c) =>
  b ? cartesian(cartesianJoin(a, b), ...c) : a

function cartesianJoin(a, b) {
  return [].concat(...a.map(a => b.map(b => [].concat(a, b))))
}

export function listToTree(list, { idKey = 'id', parentIdKey = 'parentId' }) {
  const map = {}
  const roots = []

  for (let i = 0; i < list.length; i++) {
    map[list[i][idKey]] = i // initialize the map
    list[i].children = [] // initialize the children
  }

  for (let i = 0; i < list.length; i++) {
    const node = list[i]
    const parentId = node[parentIdKey] || '0'

    if (parentId === '0') {
      roots.push(node)
    } else {
      list[map[parentId]].children.push(node)
    }
  }

  return roots
}
