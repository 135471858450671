import React from 'react'
import { Flex, Text } from 'rebass'

export default ({ label }) => (
  <Flex flexDirection={['row', 'column']} alignItems="center" width={1}>
    <Line />
    <Text p={3} color="grey.4">
      {label}
    </Text>
    <Line />
  </Flex>
)

const Line = () => (
  <Flex
    flex={1}
    sx={{
      borderStyle: 'solid',
      borderColor: 'grey.3',
      borderWidth: ['1px 0 0 0', '0 1px 0 0']
    }}
  />
)
