import React from 'react'

export default () => (
  <div>
    <p>
      退換貨說明 <br />
      如商品欲退換貨，請先與客服聯繫。 <br />
      客服信箱:webservice@vic-apply.com.tw <br />
      客服專線:02-8913-1001分機56 <br />
      (客服上班時間為周一至周五9:00-18:00)
    </p>
    <ul>
      <li>
        辦理換貨 <br />
        換貨分為商品瑕疵及個人因素，請於收到商品的三日內來信客服信箱申請，預期恕不受理。
        <ul>
          <li>
            商品瑕疵換貨: <br />
            申請後由客服聯繫收貨時間，做同商品宅配交換處理，無需承擔運費。恕不接受品項更換。
          </li>
          <li>
            個人因素換貨: <br />
            應個人喜好因素更換品項時限同金額做更換，由消費者負擔寄回運費並提供物流單號，慎康則會吸收再次寄出換貨的運費。換貨服務一筆訂單僅限一次。
            收件人: 立益物流_慎康官網 收件電話:03-4962791
            收件地址:338桃園市楊梅區青年路2段299號
          </li>
        </ul>
      </li>
      <li>
        辦理退貨 <br />
        依照消費者保護法規定，消費者享有商品到貨七天鑑賞期（包含例假日)之權益，七日鑑賞期屬『考慮期』並非『試用期』，若商品如經拆
        封、使用、以致缺乏完整性即失去再販售價值時，恕無法退貨。
        ─七日鑑賞期計算： 以消費者簽收取件完成的當日算起到第七日止
      </li>
      <li>
        退貨期限： <br />
        請於收到商品當日算起七日內來信客服信箱申請並回覆退貨物流單號，逾期恕不受理。
        非商品瑕疵，退貨需自行寄回下列地址: 收件人: 立益物流_慎康官網
        收件電話:03-4962791 收件地址:338桃園市楊梅區青年路2段299號
      </li>
      <li>
        退款計算說明: <br />
        <ul>
          <li>原訂單未達免運門檻: 退款為退貨商品金額。</li>
          <li>
            退貨後未達免運門檻:若訂單退貨後的實際消費未達免運門檻，退款為退貨商品金額扣除運費的金額。
          </li>
          <li>
            退貨後仍達免運門檻:若訂單中退貨後的實際消費仍達免運門檻，退款為退貨商品金額。
          </li>
        </ul>
      </li>
      <li>
        退款時程: <br />
        我們將在收到退貨檢查商品狀態無異狀之後於十個工作天內辦理退款。 <br />
        信用卡一次付清，款項將刷退於原信用卡，若退刷作業期間已跨信用卡結帳日，費用將退於次月帳單。(如欲國定假日則順延)
      </li>
      <li>
        請注意以下情況無法受理退貨 <br />
        <ul>
          <li>超過七日鑑賞期</li>
          <li>滿額活動贈品未寄回(寄回贈品須為全新未拆封使用)</li>
          <li>已註明不可退換貨的優惠活動特價商品</li>
        </ul>
      </li>
      <li>
        折價券、抵用券非等同於現金，不得要求變現，抵用時一筆訂單限用一張。請留意該折價券是否有使用規則及使用效期。 折價券折抵部分不再另開立發票，開立發票金額，以該訂單「購買時實際支付的金額」來計算。
      </li>
    </ul>
  </div>
)
