import React from 'react'
import { Box } from 'rebass'

export default ({ ratio, children, ...props }) => {
  if (!children) return null
  if (!ratio) return children

  return (
    <Box
      width={1}
      pt={`${ratio * 100}%`}
      sx={{ position: 'relative' }}
      {...props}
    >
      <Box sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
        {children}
      </Box>
    </Box>
  )
}
