import React from 'react'
import { Flex } from 'rebass'
import Image from 'components/Image'

export default ({
  src,
  alt,
  ratio,
  children,
  imageProps,
  imageStyles,
  sx,
  ...props
}) => (
  <Flex
    flexDirection="column"
    justifyContent="space-between"
    alignItems="center"
    variant="card"
    width="100%"
    sx={sx}
    {...props}
  >
    <Image
      src={src}
      alt={alt || src}
      ratio={ratio}
      sx={{
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        ...imageStyles
      }}
      {...imageProps}
    />
    {children}
  </Flex>
)
