import React from 'react'
import { Box, Flex } from 'rebass'

export default ({ children, sx, ...props }) => {
  if (!children) {
    return null
  }

  return (
    <Flex justifyContent="center" alignItems="center" fontSize={0} {...props}>
      <Box
        display="inline-block"
        py={1}
        px={2}
        bg="accent.1"
        color="grey.0"
        sx={{
          borderRadius: '15px',
          textAlign: 'center'
        }}
      >
        {children}
      </Box>
    </Flex>
  )
}
