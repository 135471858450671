import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import { onLoad } from 'models/linePay'
import { Flex } from 'rebass'
import Message from 'components/Message'
import LoadingIcon from 'components/LoadingIcon'
import Container from 'components/Container'
import Page from 'components/Page'

export default () => {
  const app = useContext(AppContext)
  const session = useContext(SessionContext)
  const history = useHistory()

  const url = new URL(window.location.href)
  const transNo = url.searchParams.get('transactionId')

  useEffect(() => {
    onLoad({ history, session, app, transNo })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="payment.title">
      <Container minHeight="calc(100vh - 212px)" bg="grey.1">
        <Flex width={1} justifyContent="center" alignItems="center">
          <LoadingIcon>
            <Message id="app.message.paying" />
          </LoadingIcon>
        </Flex>
      </Container>
    </Page>
  )
}
