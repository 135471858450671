import React, { useEffect, useState, useContext } from 'react'
import { Box, Flex, Text, Button } from 'rebass'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import Link from 'components/Link'
import Breadcrumb from 'components/Breadcrumb'
import Gallery from 'components/Gallery'
import Divider from 'components/Divider'
import LoadingIcon from 'components/LoadingIcon'
import Counter from 'components/Counter'
import Message from 'components/Message'
import Select from 'components/Select'
import Page from 'components/Page'
import Container from 'components/Container'
import ImageCard from 'components/ImageCard'
import Tab from 'components/Tab'
import RefundRule from 'components/RefundInfo'
import { onLoad, setBreadcrumb, addProduct } from 'models/product'

export default ({ match }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState({
    product: { options: [] },
    productVariants: [],
    balances: [],
    relatedProducts: [],
    backorder: false,
  })
  const [options, setOptions] = useState({})
  const [variant, setVariant] = useState(null)
  const [quantity, setQuantity] = useState(0)
  const id = match.params.id
  const { product, productVariants, balances } = state
  const balanceItem = variant
    ? balances.find((item) => item.productVariantId === variant.id)
    : null
  const balance = balanceItem ? balanceItem.quantity : 0

  if ((balance > 0 || state.backorder) && quantity === 0) {
    setQuantity(1)
  }

  useEffect(() => {
    onLoad({ setState, app, session, id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setBreadcrumb({ app, product })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  useEffect(() => {
    const optionCount = Object.keys(options).length
    if (optionCount === product.options.length) {
      const matched = productVariants.find((item) => {
        if (!item.options) return false

        let count = 0
        item.options.forEach(({ name, value }) => {
          if (options[name].value === value) count++
        })
        return count === optionCount
      })
      setVariant(matched)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useEffect(() => {
    if (variant && variant.id) {
      setQuantity(getCartQuantity(app, variant.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant])

  function getCartQuantity(app, productVariantId) {
    const { cartItems } = app.state
    const cartItem = cartItems.find((item) => item.id === productVariantId)
    return cartItem ? cartItem.quantity : 0
  }

  useEffect(() => {
    if (product.options.length === 0) {
      setVariant(productVariants[0])
    }
  }, [product.options, productVariants])

  if (!product.id) {
    return (
      <Flex width={1} minHeight="100vh" justifyContent="center">
        <LoadingIcon>LOADING...</LoadingIcon>
      </Flex>
    )
  }

  const renderProductCard = ({ id, primaryImage, spu, priceRange }) => (
    <Link key={id} variant="default" width={1} href={`/product/${id}`}>
      <ImageCard
        ratio={1}
        height="100%"
        src={primaryImage ? primaryImage.src : null}
        sx={{
          transition:
            'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), transform 0.2s cubic-bezier(0.4, 0, 1, 1)',
          ':hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 14px rgba(0, 0, 0, .125)',
          },
        }}
      >
        <Text width={1} px={3} py={3}>
          {spu}
        </Text>
        <Text px={3} pb={3} alignSelf="flex-start" color="accent.2">
          {priceRange}
        </Text>
        {/* <Text pb={3} px={3} alignSelf="flex-end" fontSize={0} color="grey.4">
          已出售 0
        </Text> */}
      </ImageCard>
    </Link>
  )

  return (
    <Page title="category.title">
      <Container minHeight="100vh" bg="grey.1">
        <Breadcrumb mb={3} px={[3, 3, 3, 0]} items={app.state.breadcrumbs} />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr', '2fr 3fr'],
            justifyItems: 'center',
            gridGap: 4,
          }}
        >
          <Gallery
            width={1}
            ratio={1}
            p={[3, 0]}
            images={product.images}
            value={variant ? variant.image : null}
            thumbProps={{ height: '88px' }}
          />
          <Flex flexDirection="column" width={1} p={3} bg="grey.0">
            <Text mb={3} fontSize={3}>
              {product.spu}
            </Text>
            <Divider bg="grey.3" />
            {/* <Text my={3} fontSize={5} color="accent.1">
              {variant ? `NT.${variant.price}` : product.priceRange}
            </Text> */}
            <Flex alignItems="center" my={3}>
              <Text fontSize={5} color="accent.1">
                {variant ? `NT.${variant.price}` : product.priceRange}
              </Text>
              {variant && variant.price < variant.postedPrice && (
                <Text
                  ml={3}
                  fontSize={2}
                  color="grey.4"
                  sx={{ textDecoration: 'line-through' }}
                >
                  {`NT.${variant.postedPrice}`}
                </Text>
              )}
            </Flex>
            <Divider bg="grey.3" />
            <Box
              my={3}
              color="grey.5"
              dangerouslySetInnerHTML={{ __html: product.specHtml }}
            />
            <Divider bg="grey.3" />
            {product.options.map(({ name, value }) => (
              <Flex key={name} my={3} alignItems="center" color="grey.5">
                <Text width={1 / 4}>{name}</Text>
                <Select
                  isClearable={false}
                  isSearchable={false}
                  fieldProps={{ mb: 0, flex: 1 }}
                  placeholder={[{ id: 'placeholder.select' }, { label: name }]}
                  options={value.map((item) => ({ value: item, label: item }))}
                  value={options[name]}
                  onChange={(value) => {
                    setOptions({ ...options, [name]: value })
                  }}
                />
              </Flex>
            ))}
            <Flex mt={3} mb={4} alignItems="center" color="grey.5">
              <Message id="product.field.quantity" width={1 / 4} />
              <Counter
                value={quantity}
                max={balance === 0 && state.backorder ? 1000 : balance}
                onChange={setQuantity}
              />
              {variant && (
                <Message
                  ml={3}
                  id="product.message.balance"
                  values={{ balance }}
                />
              )}
            </Flex>
            <Flex>
              <Button
                flex={1}
                variant="accent"
                disabled={!variant || balance === 0 || quantity === 0}
                onClick={() =>
                  addProduct({ app, session, product, variant, quantity })
                }
              >
                <Message id="product.btn.addToCart" />
              </Button>
              {state.backorder && (
                <Button
                  flex={1}
                  ml={2}
                  variant="accent"
                  disabled={!variant || balance > 0}
                  onClick={() =>
                    addProduct({
                      app,
                      session,
                      product,
                      variant,
                      quantity,
                      backorder: true,
                    })
                  }
                >
                  <Message id="product.btn.backorder" />
                </Button>
              )}
            </Flex>
          </Flex>
        </Box>
        <Tab
          my={[3, 3, 4]}
          value={[
            {
              id: 'desc',
              label: <Message id="product.section.desc" />,
              render: () => (
                <Box bg="grey.0">
                  <Box dangerouslySetInnerHTML={{ __html: product.descHtml }} />
                </Box>
              ),
            },
            {
              id: 'refund',
              label: <Message id="product.section.refund" />,
              render: () => (
                <Box
                  bg="grey.1"
                  p={3}
                  sx={{
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'grey.3',
                    borderRadius: '4px',
                  }}
                >
                  <RefundRule />
                </Box>
              ),
            },
          ]}
        />
        {state.relatedProducts && state.relatedProducts.length > 0 && (
          <>
            <Flex variant="header" mt={4} mb={3}>
              <Message id="product.title.relatedList" />
            </Flex>
            <Box
              width={1}
              p={3}
              mb={[3, 3, 4]}
              sx={{
                display: 'grid',
                gridTemplateColumns: [
                  '1fr 1fr',
                  '1fr 1fr 1fr',
                  '1fr 1fr 1fr 1fr 1fr 1fr',
                ],
                justifyItems: 'center',
                gridRowGap: 3,
                gridColumnGap: 2,
              }}
            >
              {state.relatedProducts.map((item) => renderProductCard(item))}
            </Box>
          </>
        )}
      </Container>
    </Page>
  )
}
