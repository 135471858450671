import React from 'react'
import { Box, Text, Button } from 'rebass'
import { MdClose } from 'react-icons/md'

export default ({ text, onDelete, sx, ...props }) => {
  if (!text) {
    return null
  }

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      py={2}
      px={3}
      bg="primary.1"
      color="grey.5"
      sx={{
        borderRadius: '15px',
        textAlign: 'center'
      }}
      {...props}
    >
      <Text flex={1}>{text}</Text>
      <Button ml={2} type="button" variant="chip" onClick={onDelete}>
        <MdClose />
      </Button>
    </Box>
  )
}
