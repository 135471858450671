import React, { useState } from 'react'
import { Flex, Button } from 'rebass'
import Modal from 'components/Modal'
import Checkbox from 'components/Checkbox'
import Message from 'components/Message'

export default ({ value, open, action }) => {
  const [state, setState] = useState(false)
  const { isCvs = false } = value || {}

  return (
    <Modal
      title="checkout.btn.editShipping"
      open={open}
      onClose={action.handleClose}
      width={300}
      contentProps={{ bg: 'grey.0' }}
      as="form"
      onSubmit={(event) => {
        event.preventDefault()

        if (state) action.handleSubmit()
        action.handleClose()
        setState(false)
      }}
    >
      <Flex alignItems="center">
        <Checkbox
          mr={2}
          label={isCvs ? 'checkout.tab.d2d' : 'checkout.tab.cvs'}
          checked={state}
          onChange={() => {
            setState(!state)
          }}
        />
      </Flex>
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          type="button"
          variant="secondary"
          mr={2}
          onClick={action.handleClose}
        >
          <Message id="btn.close" />
        </Button>
        <Button type="submit" variant="accent">
          <Message id="btn.confirm" />
        </Button>
      </Flex>
    </Modal>
  )
}
