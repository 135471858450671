import React, { useState, useEffect } from 'react'
import { Box, Button } from 'rebass'
import Carousel from 'nuka-carousel'
import Image from 'components/Image'
import ImagePlaceholder from 'components/ImagePlaceholder'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'

export default ({ images, ratio, value, thumbProps, ...props }) => {
  if (!images || images.length === 0) {
    return <ImagePlaceholder />
  }

  const image = value && value.src ? value : images[0]
  const [activeImage, setActiveImage] = useState(image)

  useEffect(() => {
    setActiveImage(image)
  }, [image])

  return (
    <Box {...props}>
      <Box mb={3} bg="grey.0">
        <Image
          src={activeImage.src}
          variant="default"
          width={1}
          ratio={1}
          sx={{ objectFit: 'cover' }}
        />
      </Box>
      <Carousel
        slidesToScroll={3}
        slidesToShow={4}
        withoutControls={images.length <= 4}
        renderCenterLeftControls={({ previousSlide }) => (
          <Button
            width="32px"
            height="32px"
            variant="glass"
            onClick={previousSlide}
          >
            <MdNavigateBefore size="2em" />
          </Button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <Button
            width="32px"
            height="32px"
            variant="glass"
            onClick={nextSlide}
          >
            <MdNavigateNext size="2em" />
          </Button>
        )}
        renderBottomCenterControls={() => {}}
        {...thumbProps}
      >
        {images.map((image, index) => (
          <Box key={index} mx={1}>
            <Image
              variant="default"
              width={1}
              ratio={ratio}
              bg="grey.0"
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor:
                  image.src === activeImage.src ? 'accent.1' : 'grey.3',
                objectFit: 'cover'
              }}
              src={image.src}
              onClick={() => setActiveImage(image)}
            />
          </Box>
        ))}
      </Carousel>
    </Box>
  )
}
