import React from 'react'
import { Flex } from 'rebass'
import { MdPhoto } from 'react-icons/md'

export default props => (
  <Flex
    justifyContent="center"
    alignItems="center"
    width={1}
    height="100%"
    bg="grey.0"
    color="grey.3"
    fontSize={6}
    sx={{
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'grey.2',
      borderRadius: '3px'
    }}
    {...props}
  >
    <MdPhoto />
  </Flex>
)
