import React from 'react'
import { Box } from 'rebass'
import Text from 'components/Message'

export default ({ id, label, errMsg, children, ...props }) => {
  if (errMsg && typeof errMsg === 'string') {
    errMsg = { id: errMsg }
  }

  if (errMsg && errMsg.constructor === Array) {
    errMsg = { id: errMsg[0], values: errMsg[1] }
  }

  return (
    <Box mb={3} {...props}>
      {label && <Text htmlFor={id} mb={1} id={label} />}
      {children}
      {errMsg && <Text variant="error" id={errMsg.id} values={errMsg.values} />}
    </Box>
  )
}
